import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { RenderBoletasService } from 'src/app/services/render-boletas/render-boletas.service';
import { RenderConsolidadoService } from 'src/app/services/render-consolidado/render-consolidado.service';
import { UserWebsocketService } from 'src/app/services/websocket/user-websocket/user-websocket.service';
import { SharedUtilsService } from '../../../services/shared-utils/shared-utils.service';
import { Consolidado2Component } from '../../consolidado2/consolidado2.component';
import { MonitorAlertsComponent } from '../../monitor-alerts/monitor-alerts.component';

@Component({
  selector: 'app-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.less'],
})
export class SidebarContentComponent implements OnInit {
  @ViewChild('clickFundo', { static: false }) clickFundo: HTMLElement;
  ocultarElementos = 0;
  estruturas: any[];
  instituicao: string;
  tipo_estrutura: string;
  permissao: boolean;
  toggled: boolean;
  parametrosCarregados: Parametros;
  gestaoAberto: boolean;
  relatorioAberto: boolean;
  boletasAberto: boolean;
  estruturaSelecionada: string;

  constructor(
    private internalData: InternalDataService,
    private http: HttpService,
    private dialog: MatDialog,
    private userWebsocket: UserWebsocketService,
    private cookies: CookieService,
    private renderConsolidade: RenderConsolidadoService,
    private renderBoletas: RenderBoletasService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private componente2: Consolidado2Component,
    private shared: SharedUtilsService,
    public monitorAlertsComponent: MonitorAlertsComponent,
  ) {
    this.atualizaEstruturas();

    this.http.getNumeroParametros().subscribe((msg) => {
      this.parametrosCarregados.espera = msg['count_calculos_espera'];
      this.parametrosCarregados.calculando = msg['count_calculos_calculando'];
    });
    this.http.getUser().subscribe((res) => {
      if (res['permissao'] == 'Calcular') {
        this.permissao = true;
      } else {
        this.permissao = false;
      }
    });

    this.parametrosCarregados = new Parametros(0, 0);
    const sessionid = this.cookies.get('sessionid');
    this.userWebsocket.getUserWebsocket().subscribe((msg) => {
      try {
        if (msg.hasOwnProperty('count_calculos_espera')) {
          this.parametrosCarregados = {
            espera: msg['count_calculos_espera'],
            calculando: msg['count_calculos_calculando'],
          };
          if (msg['estrutura'] == true) {
            this.atualizaEstruturas();
          }
        }
      } catch {
        this.parametrosCarregados = {
          espera: this.parametrosCarregados.espera as number,
          calculando: this.parametrosCarregados.calculando as number,
        };
      }
    });

    this.internalData.getSelectedLine.subscribe((msg) => {
      this.estruturaSelecionada = msg.nome;
    });

    this.internalData.getBoletasAberto().subscribe((isBoletaAberta) => {
      this.boletasAberto = isBoletaAberta;
    });

    this.internalData.getRelatorioAberto.subscribe((isRelatorioAberto) => {
      this.relatorioAberto = isRelatorioAberto;
    });

    this.internalData.getGestaoAberto.subscribe((isGestaoAberta) => {
      this.gestaoAberto = isGestaoAberta;
    });

    this.internalData.getNovoXmlImportado.subscribe((mudanca) => {
      this.atualizaEstruturas();
    });
  }

  ngOnInit() {}

  atualizaEstruturas() {
    this.http.getEstruturas().subscribe((msg) => {
      this.estruturas = [];
      if (msg != null) {
        msg['qtd_logs'];
        msg['estruturas'].forEach((item) => {
          this.estruturas.push({
            id: item['uuid'],
            nome: item['nome_fundo'],
            possuiLog: msg['qtd_logs'].filter(function (logs) {
              return logs.parametro == item['uuid'];
            })[0]['logs'],
            isCorrigido: msg['qtd_logs'].filter(function (logs) {
              return logs.parametro == item['uuid'];
            })[0]['corrigido'],
            cadastro_xml_id: item['cadastro_xml_id'],
          });
        });
        this.instituicao = msg['instituicao'];
        this.tipo_estrutura = msg['tipo_estrutura'];
        this.internalData.setTipoInstituicao(msg['tipo_estrutura']);
      }
    });
  }

  selecionaFundo(value: any) {
    localStorage.setItem('fundo', JSON.stringify(value));
    this.marcaLinha(value);
    this.internalData.setFundoSelecionado(value);
    this.internalData.setRelatorioCarregado(false);
    this.gestaoAberto = false;
    this.relatorioAberto = false;
    this.boletasAberto = false;

    this.abreRelatorio();

    this.renderBoletas.setDadosBoleta(value['cadastro_xml_id']);

    if (this.router.url != 'home') {
      this.router.navigateByUrl('/home');
    }
  }

  marcaLinha(estrutura: any) {
    this.internalData.setSelectedLine(estrutura);
  }

  abreGestao() {
    if (this.estruturaSelecionada != undefined) {
      this.gestaoAberto = !this.gestaoAberto;
      this.internalData.setGestaoAberto(this.gestaoAberto);
    } else {
      this.monitorAlertsComponent.abrirAlert('Aviso', 'Selecione um fundo', false, 'Ok');
    }
  }

  abreRelatorio() {
    if (this.estruturaSelecionada != undefined) {
      this.relatorioAberto = !this.relatorioAberto;
      this.internalData.setRelatorioAberto(this.relatorioAberto);
    } else {
      this.monitorAlertsComponent.abrirAlert('Aviso', 'Selecione um fundo', false, 'Ok');
    }
  }

  abreBoletas() {
    if (this.estruturaSelecionada != undefined) {
      this.boletasAberto = !this.boletasAberto;
      this.internalData.setBoletasAberto(this.boletasAberto);
      window.resizeTo(100, 100);
    } else {
      this.monitorAlertsComponent.abrirAlert('Aviso', 'Selecione um fundo', false, 'Ok');
    }
  }

  gotoFinalizados() {
    this.desmarcarLinha();
    this.router.navigateByUrl('/calculos/finalizados');
  }

  gotoCalculando() {
    this.desmarcarLinha();
    this.router.navigateByUrl('/calculos/calculando');
  }

  gotoEsperando() {
    this.desmarcarLinha();
    this.router.navigateByUrl('/calculos/esperando');
  }

  gotoCancelados() {
    this.desmarcarLinha();
    this.router.navigateByUrl('/calculos/cancelados');
  }

  gotoErros() {
    this.desmarcarLinha();
    this.router.navigateByUrl('/erros');
  }

  desmarcarLinha() {
    this.internalData.setSelectedLine('');
    this.internalData.setGestaoAberto(false);
    this.internalData.setRelatorioAberto(false);
    this.internalData.setBoletasAberto(false);
  }
}

export class Parametros {
  constructor(public espera: number, public calculando: number) {}
}
