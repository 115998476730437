import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';

@Injectable({
  providedIn: 'root',
})
export class HighchartsService {
  constructor() {
    Highcharts.setOptions({
      // alterando virgula para ponto
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
      },
      colors: ['#132f43', '#c4681c', '#59a5a3', '#246172', '#911717', '#8d5655', '#ceba00', '#006600', '#36ff95'],
    });
  }

  createCalculosPorFundo(charEl: any, data: any[]) {
    const config: any = {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: 'Quantidade',
        },
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f} %',
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}',
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          colorByPoint: true,
          data: data,
        },
      ],
    };
    Highcharts.chart(charEl, config);
  }

  createFundosPorDia(charEl: any, data: any[]) {
    const config: any = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: 'Quantidade',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px;">{point.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}',
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          colorByPoint: true,
          data: data,
        },
      ],
    };
    Highcharts.chart(charEl, config);
  }

  createRentabilidadeMensal(
    charEl: any,
    nomeFundo: any[],
    data: any[],
    rentabilidade_doze_meses: any[],
    rentabilidade_doze_meses_bench: any[],
  ) {
    const config: any = {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        categories: data,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: 'Rentabilidade Mensal no Período',
        },
        labels: {
          format: '{value}%',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: nomeFundo,
          data: rentabilidade_doze_meses,
        },
        {
          name: 'IDI2009',
          data: rentabilidade_doze_meses_bench,
        },
      ],
    };
    Highcharts.chart(charEl, config);
  }

  getChartRentabilidadeMensal(
    nomeFundo: any[],
    data: any[],
    rentabilidade_doze_meses: any[],
    rentabilidade_doze_meses_bench: any[],
  ) {
    const arrayBenchs: any[] = [
      {
        name: nomeFundo,
        data: rentabilidade_doze_meses,
      },
    ];

    for (const benchs of rentabilidade_doze_meses_bench) {
      arrayBenchs.push({
        name: benchs.bench,
        data: benchs.value,
      });
    }

    return {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        categories: data,
        crosshair: true,
      },
      yAxis: {
        title: {
          text: 'Rentabilidade Mensal no Período',
        },
        labels: {
          format: '{value}%',
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: arrayBenchs,
    };
  }

  getRentabilidadeAcumulada(
    nomeFundo: string,
    data: string[],
    relatorioRentabilidadeFundo: number[],
    rentabilidadeBench: Record<string, number[]>,
  ) {
    const arrayBenchs: any[] = [
      {
        name: nomeFundo,
        data: relatorioRentabilidadeFundo,
      },
    ];
    for (const key in rentabilidadeBench) {
      if (Object.prototype.hasOwnProperty.call(rentabilidadeBench, key)) {
        arrayBenchs.push({
          name: key,
          data: rentabilidadeBench[key],
        });
      }
    }

    return {
      chart: {
        type: 'line',
        zoomType: 'x',
      },
      title: { text: '' },
      xAxis: {
        type: 'category',
        tickInterval: 20,
        title: {
          text: '',
        },
        categories: data,
      },
      yAxis: {
        title: {
          text: 'Rentabilidade (%)',
        },
        labels: {
          format: '{value}%',
        },
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
            format: '{point.y:,.2f}%',
          },
        },
      },
      tooltip: {
        shared: true,
        valueSuffix: ' %',
      },
      credits: {
        enabled: false,
      },
      series: arrayBenchs,
    };
  }

  getLiquidez(data: any[]) {
    return {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'category',
        title: {
          text: 'Prazo',
        },
      },
      yAxis: {
        title: {
          text: 'Porcentagem (%)',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:,.4f}%',
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.4f}%',
      },
      credits: {
        enabled: false,
        align: 'center',
      },
      series: [
        {
          colorByPoint: true,
          data: data,
        },
      ],
    };
  }

  getCredito(data: any[]) {
    return {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: {
          text: 'Porcentagem (%)',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            allowOverlap: true,
            format: '{point.y:,.2f}%',
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.4f}%',
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          colorByPoint: true,
          data: data,
        },
      ],
    };
  }

  getExposicao(data: any[]) {
    return {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'category',
        title: {
          text: 'Fator',
        },
      },
      yAxis: {
        title: {
          text: 'Porcentagem (%)',
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:,.2f}%',
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.4f}%',
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          colorByPoint: true,
          data: data,
        },
      ],
    };
  }

  getExplosaoSegmento(dados_posicao_porc: any[], dados_risco_porc: any[], categorias: any[]) {
    return {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: categorias,
        title: {
          text: null,
        },
      },
      yAxis: {
        title: {
          text: 'Participação (%)',
        },
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:,.2f}%',
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:,.4f}%',
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Posição (%)',
          data: dados_posicao_porc,
        },
        {
          name: 'Risco (%)',
          data: dados_risco_porc,
        },
      ],
    };
  }

  getExplosaoSetor(dados_posicao_porc: any[], dados_risco_porc: any[], categorias: any[]) {
    return {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: categorias,
        title: {
          text: null,
        },
      },
      yAxis: {
        title: {
          text: 'Posição ($)',
        },
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:,.2f} %',
          },
        },
      },
      tooltip: {
        formatter: function () {
          return (
            '<b>Relativo (%): </b>' +
            this.point.y.toFixed(2).replace('.', ',') +
            '<br><b>Valor ($): </b> ' +
            this.point.valor.toFixed(2).replace('.', ',')
          );
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Posição (%)', // esse é da legenda do gráfico
          data: dados_posicao_porc,
        },
        {
          name: 'Risco (%)',
          data: dados_risco_porc,
        },
      ],
    };
  }

  getExplosaoIndexador(dados_posicao_porc: any[], dados_risco_porc: any[], categorias: any[]) {
    return {
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: categorias,
        title: {
          text: null,
        },
      },
      yAxis: {
        title: {
          text: 'Posição ($)',
        },
      },
      legend: {
        enabled: true,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:,.2f} %',
          },
        },
      },
      tooltip: {
        formatter: function () {
          return (
            '<b>Posição (%):</b>' +
            this.point.y.toFixed(2).toString().replace('.', ',') +
            '<br><b>Valor ($): </b> ' +
            this.point.valor.toFixed(2).toString().replace('.', ',')
          );
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Posição (%)', // esse é da legenda do gráfico
          data: dados_risco_porc,
        },
        {
          name: 'Risco (%)',
          data: dados_posicao_porc,
        },
      ],
    };
  }
}
