import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NotifierService } from 'angular-notifier';
import { noop } from 'jquery';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { take } from 'rxjs/operators';
import { iFundoSelecionado } from 'src/app/models/fundo-selecionado.interface';
import { Oper } from 'src/app/models/oper';
import { FormBoletasDataService } from 'src/app/services/form-boletas-data/form-boletas-data.service';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { RenderBoletasService } from 'src/app/services/render-boletas/render-boletas.service';
import { ModalidadeOperacao, TiposOpers, tiposProdutosMITRA } from 'src/app/utils';
import { iTipoProduto } from '../../models/tiposProdutos.interface';
import { CamposOperForm } from './../../models/campos-oper-form.interface';
import { FormAcaoComponent } from './forms/form-acao/form-acao.component';
import { FormBaseComponent } from './forms/form-base/form-base.component';
import { FormBtcComponent } from './forms/form-btc/form-btc.component';
import { FormCdbComponent } from './forms/form-cdb/form-cdb.component';
import { FormCompromissadaComponent } from './forms/form-compromissada/form-compromissada.component';
import { FormCotaFundoComponent } from './forms/form-cota-fundo/form-cota-fundo.component';
import { FormFuturoComponent } from './forms/form-futuro/form-futuro.component';
import { FormOpcaoComponent } from './forms/form-opcao/form-opcao.component';
import { FormTituloPrivadoComponent } from './forms/form-titulo-privado/form-titulo-privado.component';
import { FormTituloPublicoComponent } from './forms/form-titulo-publico/form-titulo-publico.component';

const moment = _rollupMoment || _moment;

registerLocaleData(ptBr);

@Component({
  selector: 'app-oper-form',
  templateUrl: './oper-form.component.html',
  styleUrls: ['./oper-form.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
})
export class OperFormComponent implements OnInit {
  @ViewChild('formDiv', { read: ViewContainerRef, static: true })
  formDivRef: ViewContainerRef;
  private readonly notifier: NotifierService;
  public msgRetorno: string;
  public listaFundos: any[] = [];
  public camposPreenchidos = new CamposOperForm();

  public tiposOper: iTipoProduto[] = [
    {
      nome: 'Ação',
      id: tiposProdutosMITRA.Stocks,
      tipoEntrada: TiposOpers.Acao,
      tipoModalidade: ModalidadeOperacao.Normal,
      form: FormAcaoComponent,
    },
    {
      nome: 'Opção',
      id: tiposProdutosMITRA.Opcoes,
      tipoEntrada: TiposOpers.Opcao,
      tipoModalidade: ModalidadeOperacao.Normal,
      form: FormOpcaoComponent,
    },
    {
      nome: 'Título Público',
      id: tiposProdutosMITRA.RF,
      tipoEntrada: TiposOpers.TituloPublico,
      tipoModalidade: ModalidadeOperacao.Normal,
      form: FormTituloPublicoComponent,
    },
    {
      nome: 'Cota de Fundos',
      id: tiposProdutosMITRA.Fundos,
      tipoEntrada: TiposOpers.Fundo,
      tipoModalidade: ModalidadeOperacao.Normal,
      form: FormCotaFundoComponent,
    },
    {
      nome: 'Futuro',
      id: tiposProdutosMITRA.Futuros,
      tipoEntrada: TiposOpers.Futuro,
      tipoModalidade: ModalidadeOperacao.Normal,
      form: FormFuturoComponent,
    },
    {
      nome: 'Título Privado',
      id: tiposProdutosMITRA.RF,
      tipoEntrada: TiposOpers.TituloPrivado,
      tipoModalidade: ModalidadeOperacao.Normal,
      form: FormTituloPrivadoComponent,
    },
    {
      nome: 'BTC',
      id: tiposProdutosMITRA.Stocks,
      tipoEntrada: TiposOpers.Emprestimo,
      tipoModalidade: ModalidadeOperacao.Emprestimo,
      form: FormBtcComponent,
    },
    {
      nome: 'Compromissada',
      id: tiposProdutosMITRA.RF,
      tipoEntrada: TiposOpers.Compromissada,
      tipoModalidade: ModalidadeOperacao.Normal,
      form: FormCompromissadaComponent,
    },
    {
      nome: 'CDB',
      id: tiposProdutosMITRA.RF,
      tipoEntrada: TiposOpers.Cdb,
      tipoModalidade: ModalidadeOperacao.Normal,
      form: FormCdbComponent,
    },
  ];
  model: Oper;
  dictModels: { [key: string]: Oper } = {};

  public tipoOper: iTipoProduto;
  public modoOperacao: string;
  public idBoleta: any;
  public tpOper: any;
  public fundoSelecionado: any;
  public parametroSelecionado: any;
  public paramsGridBoleta: any;
  public objFundoSelecionado: iFundoSelecionado;
  constructor(
    private http: HttpService,
    private internalData: InternalDataService,
    public renderBoletas: RenderBoletasService,
    public localeService: BsLocaleService,
    public notifierService: NotifierService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public formData: FormBoletasDataService,
  ) {
    this.notifier = notifierService;
    this.localeService.use('pt-br');
    defineLocale('pt-br', ptBrLocale);

    this.internalData.getFundoSelecionado().subscribe((fundo) => {
      this.fundoSelecionado = fundo.cadastro_xml_id;
      this.objFundoSelecionado = fundo;
      this.parametroSelecionado = fundo.id;
    });

    this.internalData.getOperaBoleta.subscribe((retorno) => {
      this.modoOperacao = retorno['operacao'];
      try {
        this.idBoleta = retorno['boleta']['id'];
        this.tpOper = retorno['boleta']['Operacao'];
      } catch (e) {}
    });

    this.internalData.getParamsGridBoleta.subscribe((retorno) => {
      this.paramsGridBoleta = retorno;
    });
  }

  ngOnInit() {
    this.http.getEstruturas().subscribe((msg) => {
      if (msg != null) {
        msg['estruturas'].forEach((fundo) => {
          this.listaFundos.push(fundo);
        });
      }
      this.populaGrid();
    });
  }

  onSubmit() {
    this.modelToFormGroup();
    if (this.checkValidador()) {
      const model = {
        model: this.model,
        tipoEntrada: this.tipoOper.tipoEntrada,
        parametroId: this.parametroSelecionado,
      };

      this.http.postOper(model).subscribe((msg) => {
        if (msg['sucesso']) {
          this.msgRetorno = msg['sucesso'];
          this.notifier.notify('success', 'Operação realizada com sucesso');
          this.model.reset(this.tipoOper);
          this.formData.resetCampos();

          this.renderBoletas.setDadosBoleta(this.fundoSelecionado);

          this.internalData.getFundoSelecionado().subscribe((fundo) => {
            this.renderBoletas.setDadosBoleta(fundo['cadastro_xml_id']);
          });
        } else if (msg['erros']) {
          this.notifier.notify('error', msg['erros']);
        } else {
          this.notifier.notify('error', 'Erro interno, favor contactar o suporte');
        }
      });
    }
  }

  private checkValidador() {
    const valid = this.formData.formGroup.valid;
    if (!valid) {
      this.notifier.notify('error', 'Há campos obrigatórios não preenchidos');
    }
    return valid;
  }

  salvarEdicao() {
    this.modelToFormGroup();
    if (this.checkValidador) {
      this.model['operacao'] = this.tpOper;
      this.model['id_boleta'] = this.idBoleta;

      this.http.postEditarBoleta(this.model).subscribe((msg) => {
        if (msg['sucesso']) {
          // this.msgRetorno = msg['sucesso'];
          this.notifier.notify('success', msg['sucesso']);
          this.renderBoletas.setDadosBoleta(this.fundoSelecionado);

          this.internalData.getFundoSelecionado().subscribe((fundo) => {
            this.renderBoletas.setDadosBoleta(fundo['cadastro_xml_id']);
          });
        } else {
          // this.msgRetorno = msg['erros'];
          this.notifier.notify('error', msg['erros']);
        }
      });
    }
  }

  populaGrid() {
    if (this.modoOperacao == 'editar') {
      const cod_oper = this.idBoleta;
      const tpOper = this.tpOper;
      this.model = new Oper();
      this.formData.formGroup = new FormGroup({});
      const model = this.model;
      let control: Object;

      this.http.getBoleta(cod_oper, tpOper).subscribe((boletaRetorno) => {
        switch (tpOper) {
          case 'acoes': {
            this.tipoOper = this.tiposOper[0];
            try {
              control = model.resetAcao(boletaRetorno['operacao']);
            } catch (e) {
              alert('Ocorreu um erro ao carregar a boleta de Ações');
            }
            break;
          }

          case 'opcoes': {
            this.tipoOper = this.tiposOper[1];
            try {
              control = model.resetOpcao(boletaRetorno['operacao']);
            } catch {
              alert('Ocorreu um erro ao carregar a boleta de Opções');
            }
            break;
          }

          case 'btc': {
            this.tipoOper = this.tiposOper[6];
            try {
              control = model.resetBTC(boletaRetorno['operacao']);
            } catch {
              alert('Ocorreu um erro ao carregar a boleta de BTC');
            }
            break;
          }

          case 'titulos_publico': {
            if (boletaRetorno['operacao']['tp_titulo'] == 3) {
              this.tipoOper = this.tiposOper[5];
            } else {
              this.tipoOper = this.tiposOper[2];
            }

            try {
              control = model.resetTitulos(boletaRetorno['operacao']);
            } catch {
              alert('Ocorreu um erro ao carregar a boleta de Título Público');
            }
            break;
          }

          case 'futuros': {
            this.tipoOper = this.tiposOper[4];

            try {
              control = model.resetFuturos(boletaRetorno['operacao']);
            } catch {
              alert('Ocorreu um erro ao carregar a boleta de Futuros');
            }
            break;
          }
          case 'cota_de_fundos': {
            this.tipoOper = this.tiposOper[3];
            try {
              control = model.resetCotasFundos(boletaRetorno['operacao']);
            } catch {
              alert('Ocorreu um erro ao carregar a boleta de Cota de Fundos');
            }
            break;
          }

          case 'compromissado': {
            this.tipoOper = this.tiposOper[7];
            try {
              control = model.resetCompromissadaCdb(boletaRetorno['operacao']);
            } catch {
              alert('Ocorreu um erro ao carregar a boleta de Compromissada');
            }
            break;
          }

          case 'cdb': {
            this.tipoOper = this.tiposOper[8];
            try {
              control = model.resetCompromissadaCdb(boletaRetorno['operacao']);
            } catch {
              alert('Ocorreu um erro ao carregar a boleta de CDB');
            }
            break;
          }
        }
        this.trocaFormComponent(this.tipoOper, control);
      });
    } else {
      this.tipoOper = this.tiposOper[0];
      this.trocaFormComponent(this.tipoOper);
    }
  }

  selected(input: any, campo: string) {
    this.model[campo] = this[campo + 's'].find((elem) => elem.nome == input);
    const x = (document.getElementById(campo) as HTMLInputElement).value;
    setTimeout(function () {
      (document.getElementById(campo) as HTMLInputElement).value = x;
    }, 0);
  }

  mudouTipoEntrada(event) {
    this.tipoOper = event;
    this.camposPreenchidos.reset();
    const instance = this.trocaFormComponent(event);
    this.model.tipoProduto = event['id'];
    this.model.modalidade = event['tipoModalidade'];
    return instance;
  }

  private trocaFormComponent(event: iTipoProduto, attControl?: Object) {
    const factory = this.componentFactoryResolver.resolveComponentFactory<FormBaseComponent>(event.form);
    const viewContainerRef = this.formDivRef;
    viewContainerRef.clear();
    const formComponent = viewContainerRef.createComponent<FormBaseComponent>(factory);
    formComponent.instance.mudouTipo.pipe(take(1)).subscribe((tipo) => {
      this.mudouTipoEntrada(tipo);
    });
    formComponent.instance.rendered.pipe(take(1)).subscribe(() => {
      this.modelToFormGroup(attControl);
    });
    if (this.formData.tipoOper != null) {
      const tipoOperNome = this.formData.tipoOper.nome;
      this.dictModels[tipoOperNome] = this.model;
      this.formData.formGroups[tipoOperNome] = this.formData.formGroup;
      this.model = this.dictModels[event.nome];
      this.formData.formGroup = this.formData.formGroups[event.nome];
    }
    if (this.model == null) {
      this.formData.formGroup = new FormGroup({});
      this.model = new Oper();
    }
    const instance = formComponent.instance;
    instance.tiposOper = this.tiposOper;
    instance.edit = this.modoOperacao == 'editar';
    instance.listaFundos = this.listaFundos;
    instance.fundoSelecionado = this.objFundoSelecionado;
    instance.model = this.model;
    instance.camposPreenchidos = this.camposPreenchidos;
    instance.tipoOper = event;
    instance.ngOnInit();
    this.modelToFormGroup(attControl);
    this.formData.tipoOper = event;
    return instance;
  }
  private modelToFormGroup(attControl?: Object) {
    if (attControl == null) {
      const model = this.model;
      attControl = {
        Fundo: model.fundo,
        'Tipo do Produto': model.tipoProduto,
        'Tipo da Operação': model.classeOperacao,
      };
    }
    try {
      this.formData.formGroup.patchValue(attControl);
    } catch (e) {
      noop;
    }
    this.formData.updateCampos();
  }
}
