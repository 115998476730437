import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { saveAs } from 'file-saver/dist/FileSaver';
import { noop } from 'jquery';
import { take } from 'rxjs/operators';
/* Imports de bibliotecas internas*/
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { RenderBoletasService } from 'src/app/services/render-boletas/render-boletas.service';
import { ValidatorsService } from 'src/app/services/validators/validators.service';
import { respostaPostBoletasEmLote } from './../../../models/resposta-post-boletas-em-lote.interface';

@Component({
  selector: 'app-import-boleta',
  templateUrl: './import-boleta.component.html',
  styleUrls: ['./import-boleta.component.less'],
})
export class ImportBoletaComponent implements OnInit {
  @ViewChild('stepper', { static: true }) private stepper: MatStepper;

  public arquivoImportado: Blob;
  public exibirRetorno: boolean = false;
  public msgRetorno: string | [{}];
  public msgRetornoCustodiante: string[];
  public stepLabels: { [key: string]: string };
  public corretoras: string[];
  public registrarCorretora: boolean;
  public corretorasControl: FormControl;
  public importacaoControl: FormControl;
  public arquivoFC: FormControl;
  public passoCorretoraGroup: FormGroup;
  public clienteSelecionado: number;
  public codCorretora: string;
  public showAlert: boolean;
  public sobreposicao: boolean;
  public msgSucesso: string;
  public custodiantes: string[];
  public arquivoErros: string;
  public avisoErros: string;
  private cadastroXmlId: any;
  haErros: boolean;
  haAvisos: boolean;
  disableImportar: boolean;

  constructor(
    private http: HttpService,
    private internalData: InternalDataService,
    private renderBoletas: RenderBoletasService,
    private validador: ValidatorsService,
    private modalRef: MatDialogRef<ImportBoletaComponent>,
    private ngZone: NgZone,
  ) {
    this.filtroAutoComplete('');
    this.internalData.getFundoSelecionado().subscribe((res) => {
      this.cadastroXmlId = res['cadastro_xml_id'];
    });

    this.stepLabels = {
      importacao: 'Importação em lote',
      sobreposicao: 'Alerta de sobreposição',
      corretora: 'Seleção da corretora e carteira',
      erros: 'Integração de boletas',
      sucesso: 'Conclusão',
    };
    this.arquivoFC = new FormControl();
    this.corretorasControl = new FormControl(null, this.requireMatch());
    this.importacaoControl = new FormControl(null);

    this.passoCorretoraGroup = new FormGroup({
      corretoras: this.corretorasControl,
    });
  }

  requireMatch(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const selection: any = control.value;
      if (this.corretoras.length !== 0) {
        if (this.corretoras.indexOf(selection) !== -1) {
          return null;
        }
      }
      return { match: true };
    };
  }

  ngOnInit(arquivo?: File) {
    this.registrarCorretora = false;
    this.importacaoControl.setValue(arquivo);

    this.showAlert = false;
    this.arquivoImportado = null;
    try {
      this.stepper.reset();
    } catch (err) {
      if (err instanceof TypeError) {
        noop; // na primeira vez que executa o init, o stepper não consegue executar o reset
      } else {
        throw err;
      }
    }
  }

  validarExcel(event) {
    let arquivo: File;
    if (event.target.files && event.target.files[0]) {
      arquivo = event.target.files[0];
      arquivo = new File([arquivo], arquivo.name, { type: arquivo.type }); // deep copy
    }
    this.ngOnInit(arquivo);
    this.arquivoImportado = arquivo;
  }

  submit(corretora?: string) {
    let importa = true;
    if (corretora !== undefined && !this.checkErrors()) {
      importa = false;
    }
    if (this.arquivoImportado != null && importa) {
      this.exibirRetorno = false;
      this.postTratamento(corretora);
    } else if (!importa) {
      this.msgRetorno = 'Selecione um arquivo';
      this.exibirRetorno = true;
    }
  }
  private postTratamento(corretora: string) {
    if (corretora !== undefined && this.clienteSelecionado !== undefined) {
      this.postComCorretoraECustodiantes(corretora);
    } else if (true) {
      this.postSemCorretoraECustodiantes();
    }
  }

  private checkErrors() {
    const erros = Object.keys(this.getErrors(this.passoCorretoraGroup));
    if (erros.length) {
      this.msgRetornoCustodiante = [];
      if (erros.indexOf('match') !== -1) {
        this.msgRetornoCustodiante.push('Favor selecionar uma corretora');
      }
      if (this.clienteSelecionado === undefined) {
        this.msgRetornoCustodiante.push('Favor selecionar um codigo');
      }
      this.showAlert = true;
      return false;
    }
    this.showAlert = false;
    return true;
  }

  getErrors(group: FormGroup): ValidationErrors {
    const errors: ValidationErrors = {};
    Object.values(group.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        Object.assign(errors, this.getErrors(control));
      }
      Object.assign(errors, control.errors);
    });
    return errors;
  }

  baixarExcel(erros?: boolean) {
    let nome = 'exemplo_excel_boletas_lote.xlsx';
    const salvaFunc = (xlsx) => {
      const contentType = 'application/ms-excel';
      const blob = new Blob([xlsx], { type: contentType });
      saveAs(blob, nome);
    };
    if (erros) {
      nome = 'erros.xlsx';
      this.http.getExcelErrosBoletaLote().subscribe(salvaFunc);
    } else {
      this.http.getExcelBoletaLote().subscribe(salvaFunc);
    }
  }

  filtroAutoComplete(input: string, setValue?: boolean) {
    const idProduto = null;
    this.corretoras = [];
    return this.http.getProdutos(idProduto, input, 'corretora').subscribe((msg) => {
      msg.forEach((item) => {
        this.corretoras.push(item['NOME']);
      });
      if (setValue) {
        this.corretorasControl.setValue(input);
      }
    });
  }

  private postComCorretoraECustodiantes(corretora) {
    const formData = new FormData();
    formData.append('arquivo', this.arquivoImportado);
    formData.append('corretora', corretora);
    formData.append('cliente', this.custodiantes[this.clienteSelecionado].toString());
    this.disableImportar = true;
    this.internalData
      .getFundoSelecionado()
      .pipe(take(1))
      .subscribe((fundo) => formData.append('fundo', fundo.cadastro_xml_id.toString()));
    this.http.postImportarBoletasEmLote(formData).subscribe((res: respostaPostBoletasEmLote) => {
      this.disableImportar = false;
      this.haErros = false;
      this.haAvisos = false;
      this.trataRespostaImport(res);
      this.stepper.selected.completed = true;
      this.stepper.next();
    });
  }
  private trataRespostaImport(res: respostaPostBoletasEmLote) {
    if (res.avisos !== undefined) {
      this.haAvisos = res.avisos;
      this.avisoErros = 'Arquivo importado parcialmente, verificar a(s) linha(s) com aviso no arquivo:';
      if (res.sucesso === 0) {
        this.avisoErros = 'Arquivo não importado, verificar a(s) linha(s) com aviso no arquivo:';
        this.haErros = true;
      } else if (res.sucesso === res.total && this.haAvisos) {
        this.avisoErros = 'Arquivo importado mas há avisos. Favor verificar a(s) linha(s) no arquivo:';
      }
    }
    if (res.erros !== undefined) {
      this.haErros = res.erros;
      this.avisoErros = 'Arquivo não importado, verificar a(s) linha(s) com erros no arquivo:';
    }
    if (!this.haErros) {
      this.msgSucesso = `Foram inseridas ${res.sucesso} boletas com sucesso.`;
      const numNaoImportadas = res.total - res.sucesso;
      if (numNaoImportadas > 0) {
        // eslint-disable-next-line max-len
        this.msgSucesso = `${this.msgSucesso}<br/>${numNaoImportadas} boletas não foram inseridas, favor verificar arquivo de erros.`;
      }
      if (!this.haAvisos) {
        this.avisoErros = 'Não há erros';
        this.skipStep();
      }
      this.renderBoletas.setDadosBoleta(this.cadastroXmlId);
    }
    if (res.arquivo != null) {
      this.arquivoErros = res.arquivo;
    }
  }

  private skipStep(repeat: number = 1) {
    for (let index = 0; index < repeat; index++) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
  }

  private postSemCorretoraECustodiantes() {
    const formData = new FormData();
    formData.append('arquivo', this.arquivoImportado);
    this.disableImportar = true;
    this.internalData
      .getFundoSelecionado()
      .pipe(take(1))
      .subscribe((fundo) => formData.append('fundo', fundo.cadastro_xml_id.toString()));
    this.http.postImportarBoletasEmLote(formData).subscribe((res: respostaPostBoletasEmLote) => {
      this.disableImportar = false;
      if (res.registrar_custodiante) {
        this.sobreposicao = res.sobreposicao;
        this.stepper.selected.completed = true;
        if (!this.sobreposicao) {
          this.stepper.next();
          this.stepper.selected.completed = true;
        }
        this.stepper.next();
        this.custodiantes = res.registrar_custodiante[1] as string[];
        this.clienteSelecionado = 0;
        if (this.custodiantes.length > 1 && res.registrar_custodiante[0] != null) {
          this.clienteSelecionado = res.registrar_custodiante[0] as number;
        }
        const corretora = res.registrar_alias[0];
        if (corretora !== null) {
          this.filtroAutoComplete(corretora, true);
        }
        this.codCorretora = res.registrar_alias[1];
      } else if (res['erro_leitura']) {
        this.msgRetorno = res['erro_leitura'];
        this.exibirRetorno = true;
      } else {
        this.trataRespostaImport(res);
        this.skipStep(3);
      }
    });
  }
}
