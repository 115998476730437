import { Component, OnInit } from "@angular/core";
import { InternalDataService } from "src/app/services/internal-data/internal-data.service";

@Component({
  selector: "app-cadastro-oper",
  templateUrl: "./cadastro-oper.component.html",
  styleUrls: ["./cadastro-oper.component.less"],
})
export class CadastroOperComponent implements OnInit {
  public operacaoBoleta: string;
  public dadosOper: any;

  constructor(private internalData: InternalDataService) {
    this.internalData.getOperaBoleta.subscribe((retorno) => {
      this.operacaoBoleta = retorno["operacao"];
    });
  }

  ngOnInit() {}
}
