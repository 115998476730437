import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less', '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'],
})
export class NavbarComponent {
  public isLogin: boolean;
  public usuario: ' ';
  public inicial: string;

  constructor(
    private router: Router,
    private http: HttpService,
    private cookies: CookieService,
    private internalData: InternalDataService,
  ) {
    if (this.router.url.match('.*/login$') == null) {
      this.isLogin = false;
      this.http.getUser().subscribe((ret) => {
        this.usuario = ret['usuario'];
        this.inicial = this.usuario.charAt(0);
      });
    } else {
      this.isLogin = true;
    }
  }

  Logout() {
    this.cookies.delete('sessionid');
    this.router.navigateByUrl('/login');
  }

  gotoRedirecionamento(parametro) {
    this.internalData.setSelectedLine('');
    this.internalData.setGestaoAberto(false);
    this.internalData.setRelatorioAberto(false);
    this.internalData.setBoletasAberto(false);
    this.router.navigateByUrl(`${parametro}`);
  }

  gotoFinalizados() {
    this.router.navigateByUrl('/calculos/finalizados');
  }
}
