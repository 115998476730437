/* eslint-disable no-invalid-this */
import { Component, HostListener, Input } from '@angular/core';
import { GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-grid-limites',
  templateUrl: './grid-limites.component.html',
  styleUrls: ['./grid-limites.component.less'],
})
export class GridLimitesComponent {
  @Input() public dados: any[];
  public gridApi: GridApi;
  public gridColumnApi: any;
  public getRowNodeId: any;

  private containsFilterParams = {
    filterOptions: [
      {
        displayKey: 'contém',
        displayName: 'Contém',
        test: function (filterValue, cellValue) {
          return cellValue.includes(filterValue.toLowerCase());
        },
      },
      {
        displayKey: 'não contém',
        displayName: 'Não Contém',
        test: function (filterValue, cellValue) {
          return !cellValue.includes(filterValue.toLowerCase());
        },
      },
    ],
    suppressAndOrCondition: true,
  };

  columnDefs = [
    {
      headerName: 'Descrição',
      field: 'Descricao',
      cellClass: 'cell-string',
      lockPosition: true,
      sortable: true,
      resizable: true,
      filter: true,
      filterParams: this.containsFilterParams,
      tooltipComponent: 'descricaoTooltip',
      pinned: 'left',
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: 'Produto',
      field: 'Produto',
      cellClass: 'cell-string',
      lockPosition: true,
      sortable: true,
      resizable: true,
      // filter: 'agSetColumnFilter',
      filter: true,
      filterParams: this.containsFilterParams,
      pinned: 'left',
      maxWidth: 120,
      // suppressSizeToFit: true,
      menuTabs: ['filterMenuTab'],
    },
    {
      headerName: 'Valor Diferença',
      field: 'Valor_Diferenca',
      cellClass: 'cell-number',
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      lockPosition: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter2,
      maxWidth: 150,
    },
    {
      headerName: 'Valor Limite',
      field: 'Valor_Limite',
      cellClass: 'cell-number',
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      lockPosition: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter2,
      maxWidth: 150,
    },
    {
      headerName: 'Valor Referência',
      field: 'Valor_Referencia',
      cellClass: 'cell-number',
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      lockPosition: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter2,
      maxWidth: 150,
    },
    {
      headerName: 'Valor Utilizado',
      field: 'Valor_Utilizado',
      cellClass: 'cell-number',
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      lockPosition: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter2,
      maxWidth: 150,
    },
  ];

  private rowSelection = 'multiple';

  constructor() {}

  formatter2(params) {
    const sansDec = Number(params.value).toFixed(2).replace('.', ',');
    const valor = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return valor;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.setGridData();
  }

  setGridData() {
    this.gridApi.setRowData([]);
    this.dados.forEach((item) => {
      const novoItem = {};
      novoItem['Produto'] = item['produto'];
      novoItem['Descricao'] = item['descricao'];
      novoItem['Valor_Diferenca'] = item['valor_diferenca'];
      novoItem['Valor_Limite'] = item['valor_limite'];
      novoItem['Valor_Referencia'] = item['valor_referencia'];
      novoItem['Valor_Utilizado'] = item['valor_utilizado'];
      this.gridApi.updateRowData({ add: [novoItem] });
    });
    window.dispatchEvent(new Event('resize'));
  }
}
