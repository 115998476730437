/**
 * @Pipe
 * @description pipe to format numeric values to argentina readable currency values
 * @input number
 * @output formatted numeric value
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: any): number {
    if (value === '-' || typeof value == 'undefined' || Math.abs(value) <= 0.000001) {
      value = 0;
    }
    return this.localeString(value);
  }

  missingOneDecimalCheck(nStr) {
    nStr += '';
    const x = nStr.split(',')[1];
    if (x && x.length === 1) return true;
    return false;
  }

  missingAllDecimalsCheck(nStr) {
    nStr += '';
    const x = nStr.split(',')[1];
    if (!x) return true;
    return false;
  }

  localeString(nStr) {
    if (nStr === '') return '';
    let x;
    let x1;
    let x2;
    let rgx;
    let y1;
    let y2;
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? ',' + x[1] : '';
    rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }

    /** If value was inputed by user, it could have many decimals(up to 7)
            so we need to reformat previous x1 results */
    if (x1.indexOf(',') !== -1) {
      y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');
      y2 = x1.split(',');
      x = y2[0] + y1;
    } else {
      x = x1 + x2;
      if (this.missingOneDecimalCheck(x)) x += '0';
      if (this.missingAllDecimalsCheck(x)) x += ',00';
      if (x == '' || x == '0,00' || x == null || x == 'null,00' || x == undefined || x == '0') {
        x = '-';
      }
    }
    return x.substring(0, x.indexOf(',') + 3);
  }
}
