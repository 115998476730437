import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'angular-highcharts';
import html2pdf from 'html2pdf.js';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { SharedUtilsService } from 'src/app/services/shared-utils/shared-utils.service';
import { UserWebsocketService } from 'src/app/services/websocket/user-websocket/user-websocket.service';
import { HighchartsService } from '../../services/highcharts/highcharts.service';
import { TIPOS_PERFIL } from './../../utils';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export interface Fundo {
  id: string;
  nome: string;
  possuiLog: boolean;
  isCorrigido: boolean;
  // eslint-disable-next-line camelcase
  cadastro_xml_id: number;
}

@Component({
  selector: 'app-consolidado2',
  templateUrl: './consolidado2.component.html',
  styleUrls: ['./consolidado2.component.less'],
})
export class Consolidado2Component implements OnInit {
  constructor(
    private readonly resolver: ComponentFactoryResolver,
    private highcharts: HighchartsService,
    private httpService: HttpService,
    private internalData: InternalDataService,
    private route: ActivatedRoute,
    breakpointObserver: BreakpointObserver,
    public sharedUtils: SharedUtilsService,
    private userWebsocket: UserWebsocketService,
  ) {
    this.internalData.getSidebarToggle().subscribe((val) => {
      this.toggled = val;
    });

    breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      this.isMobile = result.matches;
    });

    this.internalData.getFundoSelecionado().subscribe((fundo: Fundo) => {
      
      this.uuid = fundo.id;
      if (typeof this.uuid != 'undefined') {
        this.criarConsolidado();
      }
    });

    this.titulosPanels = Object.keys(this.grid_cols).map((chave) => this.grid_cols[chave].titulo);
    this.panelsKeys = Object.keys(this.grid_cols);
    this.panels = this.panelsKeys.map((obj) => this.grid_cols[obj]);

    this.userWebsocket.getUserWebsocket().subscribe((msg) => {
      try {
        if (this.uuid == msg['reload']) {
          this.criarConsolidado();
        }
      } catch (e) {}
    });

    this.internalData.getBoletasAberto().subscribe(() => {});
  }

  @ViewChild('pagCabecalhoRentabilidade', { static: true, read: ViewContainerRef })
  pagCabecalhoRentabilidade: ViewContainerRef;
  @ViewChild('pagRisco', { static: true, read: ViewContainerRef }) pagRisco: ViewContainerRef;
  @ViewChild('pagEstrategiaRankLuz', { static: true, read: ViewContainerRef }) pagEstrategiaRankLuz: ViewContainerRef;
  @ViewChild('pagProdutos', { static: true, read: ViewContainerRef }) pagProdutos: ViewContainerRef;

  public showAlert$: Observable<boolean>;

  public TIPOS_PERFIL = TIPOS_PERFIL;
  public TIPOS_RELATORIO = {
    asset: 'Fundo',
    previdencia: 'Plano',
  };

  public toggled: boolean;
  public dadosConsolidado: any = {
    nome_fundo: '',
    data_posicao: '',
    cadastro_xml: {
      id: 0,
      cnpj: '',
      isin: '',
      nome: '',
      cota: '',
    },
    rentabilidade: {
      rentabilidades_12_meses: [],
      ano: '',
      ano_valor: '',
      mes: '',
      mes_valor: '',
    },
    rentabilidades_12_meses_highcharts: {
      data: [],
      rent_fundo: [],
      rent_bench: [],
    },
    rentabilidade_acumulada_12_meses_highcharts: {
      data: [],
      rent_fundo: [],
      rent_bench: [],
    },
    indicadores: [],
    ranking_luz: [],
    risco_sintetico_0: null,
    liquidez_total_highcharts: [],
    rating_credito_highcharts: [],
    fator_risco_exposicao_highcharts: [],
    variacao_cenarios: {},
    limites: {
      Enquadrado: [],
      Desenquadrado: [],
    },
    estrategia_segmento_highcharts: {
      categorias: [],
      dados_risco_porc: [],
      dados_posicao_porc: [],
    },
    estrategia_setor: [],
    estrategia_setor_highcharts: {
      categorias: [],
      dados_posicao_porc: [],
      dados_risco_porc: [],
    },
    estrategia_indexador_highcharts: {
      categorias: [],
      dados_posicao_porc: [],
      dados_risco_porc: [],
    },
    produtos: [],
    datas_relatorios: ['31/05/2019'],
    is_finalizado: false,
  };
  public uuid: string;
  public isMobile = false;
  public dataPosicao: string;
  public chartSelected = 'setor';
  public titulosPanels: string[];
  public panels: object[];
  public panelsKeys: string[];
  public filtrosUsuarioConsolidado = {};
  public estrategiaPanelVisible = true;
  public global: any;
  public pdfCarregando = false;
  // eslint-disable-next-line camelcase
  tipo_estrutura: string;
  nomesPrev: any = {};
  valoresPrev: any = {};

  // tslint:disable-next-line: member-ordering
  public highchartsChartDict: any = {
    highchartRentabilidadeMensal: new Chart({}),
    highchartRentabilidadeAcumulada: new Chart({}),
    highchartLiquidez: new Chart({}),
    highchartCredito: new Chart({}),
    highchartExposicao: new Chart({}),
    highchartExplosaoSegmento: new Chart({}),
    highchartExplosaoSetor: new Chart({}),
    highchartExplosaoIndexador: new Chart({}),
  };

  public grid_cols: any = {
    Rentabilidade: {
      titulo: 'Rentabilidade',
      class: 'col-12 ',
      style: {},
      altHeader: true,
      filter: 'rentabilidade',
      loadingSpinner: true,
      noResults: false,
    },
    ChartRentabilidadeMensal: {
      chart: 'highchartRentabilidadeMensal',
      titulo: 'Rentabilidade Mensal',
      class: 'col-12 printPdf95',
      style: { width: '906px' },
      filter: 'rentablidade_mensal',
      loadingSpinner: true,
      noResults: false,
    },
    DadosFundo: {
      titulo: 'Dados do Fundo',
      class: 'col-12 col-md-4',
      style: {},
      filter: 'dados_fundo',
      loadingSpinner: true,
      noResults: false,
    },
    ChartRentabilidadeAcumulada: {
      chart: 'highchartRentabilidadeAcumulada',
      titulo: 'Rentabilidade Acumulada',
      class: 'col-12',
      style: {},
      filter: 'rentabilidade_acumulada',
      loadingSpinner: true,
      noResults: false,
    },
    Risco: {
      titulo: 'Risco',
      class: 'col-12',
      style: {},
      altHeader: true,
      filter: 'risco',
      loadingSpinner: true,
      noResults: false,
    },
    ChartLiquidez: {
      chart: 'highchartLiquidez',
      titulo: 'Liquidez',
      class: 'col-12 col-md-6',
      style: {},
      filter: 'liquidez',
      loadingSpinner: true,
      noResults: false,
    },
    ChartCredito: {
      chart: 'highchartCredito',
      titulo: 'Crédito',
      class: 'col-12 col-md-6 printPdf95',
      style: {},
      filter: 'credito',
      loadingSpinner: true,
      noResults: false,
    },
    ChartExposicao: {
      chart: 'highchartExposicao',
      titulo: 'Alocação por Fator de Risco',
      class: 'col-12 col-md-6 printPdf95',
      style: {},
      filter: 'alocacao_fator_risco',
      loadingSpinner: true,
      noResults: false,
    },
    VariacaoCenarios: {
      titulo: 'Variação de Cenários',
      class: 'col-12',
      style: {},
      filter: 'variacao_cenarios',
      loadingSpinner: true,
      noResults: false,
    },
    Enquadramento: {
      titulo: 'Enquadramento',
      class: 'col-12',
      style: {
        // eslint-disable-next-line quote-props
        width: 'auto',
        'margin-right': '-27px',
        'margin-left': '-25px',
        'margin-top': '-10px',
      },
      filter: 'enquadramento',
      loadingSpinner: true,
      noResults: false,
    },
    ChartExplosaoSegmento: {
      chart: 'highchartExplosaoSegmento',
      titulo: 'Grafico por Segmento',
      class: 'col-12 printPdf95',
      style: {},
      loadingSpinner: true,
      noResults: false,
    },
    ChartExplosaoSetor: {
      chart: 'highchartExplosaoSetor',
      titulo: 'Grafico por Setor da Economia',
      class: 'col-12 printPdf95',
      style: {},
      loadingSpinner: true,
      noResults: false,
    },
    ChartExplosaoIndexador: {
      chart: 'highchartExplosaoIndexador',
      titulo: 'Grafico por Indexador',
      class: 'col-12 printPdf95',
      style: {},
      loadingSpinner: true,
      noResults: false,
    },
    DadosSegmento: {
      titulo: 'Dados por Segmento',
      class: 'col-12',
      style: {},
      filter: 'dados_segmento',
      loadingSpinner: true,
      noResults: false,
    },
    RankingLuz: {
      titulo: 'Ranking LUZ',
      class: 'col-12',
      style: {},
      altHeader: true,
      filter: 'ranking_luz',
      loadingSpinner: true,
      noResults: false,
    },
    Produtos: {
      titulo: 'Produtos',
      class: 'col-12',
      style: {},
      altHeader: true,
      filter: 'produtos',
      loadingSpinner: true,
      noResults: false,
    },
  };

  stopPropagation() {}

  async downloadPdfConsolidado() {
    this.pdfCarregando = true;
    const bloco1 = this.pagCabecalhoRentabilidade.element.nativeElement;
    const bloco2 = this.pagRisco.element.nativeElement;
    const bloco3 = this.pagEstrategiaRankLuz.element.nativeElement;
    const bloco4 = this.pagProdutos.element.nativeElement;
    const opt = {
      margin: [10, 5, 10, 5],
      enableLinks: false,
      pagebreak: {
        avoid: ['tr'],
        mode: ['avoid-all', 'legacy'],
      },
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        onclone: (document) => {
          this.removeElements(document);
          this.adicionaElements(document);
        },
      },
      jsPDF: { unit: 'mm', format: [450, 380], orientation: 'landscape' },
    };
    const opt2 = {
      margin: [10, 5, 10, 5],
      enableLinks: false,
      pagebreak: {
        avoid: ['tr'],
        mode: ['css', 'legacy'],
      },
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        onclone: (document) => {
          this.removeElements(document);
        },
      },
      jsPDF: { unit: 'mm', format: [450, 380], orientation: 'landscape' },
    };
    html2pdf()
      .from(bloco1)
      .set(opt)
      .toPdf()
      .get('pdf')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((pdf: any) => {
        pdf.addPage();
      })
      .from(bloco2)
      .toContainer()
      .toCanvas()
      .toPdf()
      .get('pdf')
      .then((pdf: any) => {
        pdf.addPage();
      })
      .from(bloco3)
      .toContainer()
      .toCanvas()
      .toPdf()
      .get('pdf')
      .then((pdf: any) => {
        pdf.addPage();
      })
      .from(bloco4)
      .set(opt2)
      .toContainer()
      .toCanvas()
      .toPdf()
      .get('pdf')
      .then((pdf: any) => {
        const totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i < totalPages + 1; i++) {
          pdf.setPage(i);
          pdf.setFontSize(8);
          pdf.text(`${i}/${totalPages}`, pdf.internal.pageSize.getWidth() - 10, pdf.internal.pageSize.getHeight() - 5);
          pdf.setFontSize(10);
          pdf.text(`${this.dadosConsolidado['nome_fundo']} `, 10, pdf.internal.pageSize.getHeight() - 5);
        }
        this.pdfCarregando = false;
      })
      .save(`Relatorio-${this.dadosConsolidado['nome_fundo']}-${this.dataPosicao}`);
  }
  removeElements(document: any): void {
    document.querySelectorAll('#btn-pdf-consolidado').forEach(function (canva) {
      canva.remove();
    });
    document.querySelectorAll('#btn-pdf-consolidado-filtro').forEach(function (canva) {
      canva.remove();
    });
    document.querySelectorAll('.mat-expansion-indicator').forEach(function (canva) {
      canva.remove();
    });
    document.querySelectorAll('.remove-print').forEach(function (canva) {
      canva.remove();
    });
    document.querySelectorAll('mat-expansion-panel').forEach(function (canva) {
      canva.classList.add('mat-expanded');
    });
  }
  adicionaElements(document: any): void {
    document.querySelectorAll('#cabecalho-print').forEach((canva) => {
      let dataPosicao = document.querySelector('app-min-max-datepicker > div > input');
      dataPosicao = dataPosicao ? dataPosicao.value : 0;
      canva.innerHTML = `<div style="width:100%; ">
      <h5 class="textCabecalho pdfPrint" style="font-size: 1.15rem">
      <p style="  text-align: center; "> <b> ${this.dadosConsolidado['nome_fundo']} </b> </p>
      <p style="font-size: 0.9rem; margin:0"><b>Data Posição:</b> ${dataPosicao}</p>
      </h5>
      </div>`;
    });
    document.querySelectorAll('mat-expansion-panel').forEach(function (canva) {
      canva.classList.add('mat-expanded');
    });
    document.querySelectorAll('printPdf95').forEach(function (canva) {
      canva.style.width = '90%';
    });
  }

  dadosPrevidencia() {
    this.nomesPrev = {
      fundo: 'Valor_Fundo',
      segmento: 'Valor_Segmento',
    };
    this.valoresPrev = ['9000', '1000', '2000', '3000', '4000', '5000', '6000', '7000', '8000', '9000', '1000', '8888'];
  }

  ngOnInit() {
    this.tipoUsuario();
    this.criarFiltros();
    this.dadosPrevidencia();
  }

  tipoUsuario() {
    this.httpService.getEstruturas().subscribe((msg) => {
      this.tipo_estrutura = msg['tipo_estrutura'];
      if (this.tipo_estrutura == 'Previdencia') {
        delete this.grid_cols['RankingLuz'].filter;
      }
    });
  }

  public criarConsolidado(entrada = null) {
    this.uuid = entrada == null ? this.uuid : entrada;

    if (this.uuid != undefined && this.uuid != '') {
      this.route.paramMap
        .pipe(
          switchMap(() => {
            return this.httpService.getConsolidado(this.uuid);
          }),
        )
        .subscribe((msg) => {
          this.dadosConsolidado = msg;
          if (msg['indicadores'].volatilidade != null) {
            this.dadosConsolidado.indicadores.volatilidade = msg['indicadores'].volatilidade * Math.sqrt(252);
          }
          this.atualizarSpinners();
          this.dataPosicao = msg['data_posicao'];
          this.showAlert$ = msg['show_alert'];
          this.criarGraficos();
          this.internalData.setRelatorioCarregado(true);
          this.titulosPanels = Object.keys(this.grid_cols).map((chave) => this.grid_cols[chave].titulo);
          this.panelsKeys = Object.keys(this.grid_cols);
          this.panels = this.panelsKeys.map((obj) => this.grid_cols[obj]);
          this.titulosPanels = Object.keys(this.grid_cols).map((chave) => this.grid_cols[chave].titulo);
          this.panelsKeys = Object.keys(this.grid_cols);
          this.panels = this.panelsKeys.map((obj) => this.grid_cols[obj]);
          this.grid_cols['ChartRentabilidadeMensal']['class'] =
            this.dadosConsolidado['tipo_estrutura'] == 'Fundo' ? 'col-12 col-md-8' : 'col-12';
        });
    }
  }

  atualizarSpinners() {
    this.grid_cols.Rentabilidade.loadingSpinner =
      this.dadosConsolidado.rentabilidade.ano === '' && this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.Rentabilidade.noResults =
      this.dadosConsolidado.rentabilidade.ano === '' && this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.ChartRentabilidadeMensal.loadingSpinner =
      this.dadosConsolidado.rentabilidades_12_meses_highcharts.data.length == 0 &&
      this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.ChartRentabilidadeMensal.noResults =
      this.dadosConsolidado.rentabilidades_12_meses_highcharts.data.length == 0 &&
      this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.DadosFundo.loadingSpinner =
      Object.keys(this.dadosConsolidado.indicadores).length == 0 && this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.DadosFundo.noResults =
      Object.keys(this.dadosConsolidado.indicadores).length == 0 && this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.ChartRentabilidadeAcumulada.loadingSpinner =
      this.dadosConsolidado.rentabilidade_acumulada_12_meses_highcharts.data.length == 0 &&
      this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.ChartRentabilidadeAcumulada.noResults =
      this.dadosConsolidado.rentabilidade_acumulada_12_meses_highcharts.data.length == 0 &&
      this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.Risco.loadingSpinner =
      this.dadosConsolidado.risco_sintetico_0 == null && this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.Risco.noResults =
      this.dadosConsolidado.risco_sintetico_0 == null && this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.ChartLiquidez.loadingSpinner =
      this.dadosConsolidado.liquidez_total_highcharts.length == 0 && this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.ChartLiquidez.noResults =
      this.dadosConsolidado.liquidez_total_highcharts.length == 0 && this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.ChartCredito.loadingSpinner =
      this.dadosConsolidado.rating_credito_highcharts.length == 0 && this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.ChartCredito.noResults =
      this.dadosConsolidado.rating_credito_highcharts.length == 0 && this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.ChartExposicao.loadingSpinner =
      this.dadosConsolidado.fator_risco_exposicao_highcharts.length == 0 &&
      this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.ChartExposicao.noResults =
      this.dadosConsolidado.fator_risco_exposicao_highcharts.length == 0 && this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.VariacaoCenarios.loadingSpinner =
      Object.keys(this.dadosConsolidado.variacao_cenarios).length == 0 && this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.VariacaoCenarios.noResults =
      Object.keys(this.dadosConsolidado.variacao_cenarios).length == 0 && this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.Enquadramento.loadingSpinner =
      this.dadosConsolidado.limites.Enquadrado.length == 0 && this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.Enquadramento.noResults =
      this.dadosConsolidado.limites.Enquadrado.length == 0 && this.dadosConsolidado.is_finalizado == true;
    this.grid_cols.ChartExplosaoSegmento.loadingSpinner =
      Object.keys(this.dadosConsolidado.estrategia_segmento_highcharts.categorias).length == 0 &&
      this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.ChartExplosaoSegmento.noResults =
      Object.keys(this.dadosConsolidado.estrategia_segmento_highcharts.categorias).length == 0 &&
      this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.ChartExplosaoSetor.loadingSpinner =
      Object.keys(this.dadosConsolidado.estrategia_setor_highcharts.categorias).length == 0 &&
      this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.ChartExplosaoSetor.noResults =
      Object.keys(this.dadosConsolidado.estrategia_setor_highcharts.categorias).length == 0 &&
      this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.ChartExplosaoIndexador.loadingSpinner =
      Object.keys(this.dadosConsolidado.estrategia_indexador_highcharts.categorias).length == 0 &&
      this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.ChartExplosaoIndexador.noResults =
      Object.keys(this.dadosConsolidado.estrategia_indexador_highcharts.categorias).length == 0 &&
      this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.DadosSegmento.loadingSpinner =
      this.dadosConsolidado.estrategia_setor.length == 0 && this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.DadosSegmento.noResults =
      this.dadosConsolidado.estrategia_setor.length == 0 && this.dadosConsolidado.is_finalizado == true;

    this.grid_cols.RankingLuz.loadingSpinner =
      this.dadosConsolidado.ranking_luz.length == 0 && this.dadosConsolidado.is_finalizado == false;
    this.grid_cols.RankingLuz.noResults =
      this.dadosConsolidado.ranking_luz.length == 0 && this.dadosConsolidado.is_finalizado == true;

    (this.grid_cols.Produtos.loadingSpinner =
      this.dadosConsolidado.produtos.length == 0 && this.dadosConsolidado.is_finalizado == false),
      (this.grid_cols.Produtos.noResults =
        this.dadosConsolidado.produtos.length == 0 && this.dadosConsolidado.is_finalizado == true);
  }

  criarFiltros() {
    this.httpService.getFiltroUsuarioConsolidado().subscribe((msg) => {
      this.filtrosUsuarioConsolidado = msg;
      this.estrategiaPanelVisible = msg['estrategia'];
    });
  }

  public onChartChange(event: Event) {
    const texto = event['value'] == 'indexador' ? 'Indexador' : 'Setor da Economia';
    document.getElementById('label-chart-identifier').textContent = texto;
  }

  public togglePanel(event: string) {
    const currentValue = this.filtrosUsuarioConsolidado[this.grid_cols[event]['filter']];
    const nomeFiltro = this.grid_cols[event]['filter'];
    const payload = {
      field: nomeFiltro,
      value: !currentValue,
    };
    this.httpService.postFiltroUsuarioConsolidado(payload).subscribe(() => {});
    this.filtrosUsuarioConsolidado[this.grid_cols[event]['filter']] = !currentValue;
  }

  public togglePanelEstrategia() {
    const nomeFiltro = 'estrategia';
    const currentValue = this.estrategiaPanelVisible;
    const payload = {
      field: nomeFiltro,
      value: !currentValue,
    };
    this.httpService.postFiltroUsuarioConsolidado(payload).subscribe((msg) => {});
    this.estrategiaPanelVisible = !currentValue;
  }

  public criarGraficos() {
    this.highchartsChartDict.highchartRentabilidadeMensal = new Chart(
      this.highcharts.getChartRentabilidadeMensal(
        this.dadosConsolidado['nome_fundo'],
        this.dadosConsolidado['rentabilidades_12_meses_highcharts']['data'],
        this.dadosConsolidado['rentabilidades_12_meses_highcharts']['rent_fundo'],
        this.dadosConsolidado['rentabilidades_12_meses_highcharts']['rent_bench'],
      ) as any,
    );

    this.highchartsChartDict.highchartRentabilidadeAcumulada = new Chart(
      this.highcharts.getRentabilidadeAcumulada(
        this.dadosConsolidado['nome_fundo'],
        this.dadosConsolidado['rentabilidade_acumulada_12_meses_highcharts']['data'],
        this.dadosConsolidado['rentabilidade_acumulada_12_meses_highcharts']['rent_fundo'],
        this.dadosConsolidado['rentabilidade_acumulada_12_meses_highcharts']['rent_bench'],
      ) as any,
    );

    this.highchartsChartDict.highchartLiquidez = new Chart(
      this.highcharts.getLiquidez(this.dadosConsolidado['liquidez_total_highcharts']) as any,
    );

    this.highchartsChartDict.highchartCredito = new Chart(
      this.highcharts.getCredito(this.dadosConsolidado['rating_credito_highcharts']) as any,
    );

    this.highchartsChartDict.highchartExposicao = new Chart(
      this.highcharts.getExposicao(this.dadosConsolidado['fator_risco_exposicao_highcharts']) as any,
    );

    this.highchartsChartDict.highchartExplosaoSegmento = new Chart(
      this.highcharts.getExplosaoSegmento(
        this.dadosConsolidado['estrategia_segmento_highcharts']['dados_risco_porc'],
        this.dadosConsolidado['estrategia_segmento_highcharts']['dados_posicao_porc'],
        this.dadosConsolidado['estrategia_segmento_highcharts']['categorias'],
      ) as any,
    );

    this.highchartsChartDict.highchartExplosaoSetor = new Chart(
      this.highcharts.getExplosaoSetor(
        this.dadosConsolidado['estrategia_setor_highcharts']['dados_posicao_porc'],
        this.dadosConsolidado['estrategia_setor_highcharts']['dados_risco_porc'],
        this.dadosConsolidado['estrategia_setor_highcharts']['categorias'],
      ) as any,
    );

    this.highchartsChartDict.highchartExplosaoIndexador = new Chart(
      this.highcharts.getExplosaoIndexador(
        this.dadosConsolidado['estrategia_indexador_highcharts']['dados_risco_porc'],
        this.dadosConsolidado['estrategia_indexador_highcharts']['dados_posicao_porc'],
        this.dadosConsolidado['estrategia_indexador_highcharts']['categorias'],
      ) as any,
    );
  }

  visivel(tipo: string, panel: string) {
    if (this.dadosConsolidado.tipo_estrutura == tipo) {
      return true;
    } else {
      this.grid_cols[panel]['class'] = 'col-12';
      return false;
    }
  }

  public get TipoPerfil(): typeof TIPOS_PERFIL {
    return TIPOS_PERFIL;
  }
}
