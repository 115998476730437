import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalculosComponent } from './components/calculos/calculos.component';
import { Consolidado2Component } from './components/consolidado2/consolidado2.component';
import { ConsumoComponent } from './components/consumo/consumo.component';
import { DefinicoesCalculoComponent } from './components/definicoes-calculo/definicoes-calculo.component';
import { ErrosComponent } from './components/erros/erros.component';
import { HelpComponent } from './components/help/help.component';
import { HomeComponent } from './components/home/home.component';
import { LimitesComponent } from './components/limites/limites.component';
import { LoginComponent } from './components/login/login.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';

/* prettier-ignore*/
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'help', component: HelpComponent, canActivate: [AuthGuardService] },
  { path: 'consumo', component: ConsumoComponent, canActivate: [AuthGuardService] },
  { path: 'erros', component: ErrosComponent, canActivate: [AuthGuardService] },
  { path: 'erros/:uuid', component: ErrosComponent, canActivate: [AuthGuardService] },
  { path: 'consolidado/:uuid', component: Consolidado2Component, canActivate: [AuthGuardService]},
  { path: 'limites/:tipo/:regra', component: LimitesComponent, canActivate: [AuthGuardService]},
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'calculos/:tipo', component: CalculosComponent, canActivate: [AuthGuardService]},
  { path: 'perfil', component: PerfilComponent, canActivate: [AuthGuardService]},
  { path: 'definicoes', component: DefinicoesCalculoComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
