import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';

@Component({
  selector: 'app-gestao',
  templateUrl: './gestao.component.html',
  styleUrls: ['./gestao.component.less'],
})
export class GestaoComponent implements OnInit {
  public informacoesCard: any;
  public cadastro_xml_id: number;
  public uuid: string;
  public nomeFundo: string;

  constructor(public dialog: MatDialog, private internalData: InternalDataService, private http: HttpService) {}

  ngOnInit() {
    this.internalData.getFundoSelecionado().subscribe((res) => {
      this.cadastro_xml_id = res['cadastro_xml_id'];
      this.uuid = res['id'];
      this.nomeFundo = res['nome'];
      this.atualizaCardsGestao();
    });
  }

  atualizaCardsGestao() {
    this.http.getCardsGestao(this.cadastro_xml_id, this.uuid).subscribe((res) => {
      this.informacoesCard = res;
    });
  }
}
