import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormBaseComponent } from "./../form-base/form-base.component";

@Component({
  selector: "app-form-futuro",
  templateUrl: "./form-futuro.component.html",
  styleUrls: ["../form-base/form-base.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFuturoComponent extends FormBaseComponent {}
