import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { BaseCampoComEditComponent } from "../base-campo-com-edit/base-campo-com-edit.component";

@Component({
  selector: "app-campo-valor-emissao",
  templateUrl: "./campo-valor-emissao.component.html",
  styleUrls: ["../base-campo-com-edit/base-campo-com-edit.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: BaseCampoComEditComponent,
      useExisting: CampoValorEmissaoComponent,
    },
  ],
})
export class CampoValorEmissaoComponent
  extends BaseCampoComEditComponent
  implements OnInit {
  ngOnInit() {
    this.nome = "Valor de Emissão";
  }
}
