import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, ViewChild } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-tab-limites',
  templateUrl: './tab-limites.component.html',
  styleUrls: ['./tab-limites.component.less'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          height: '*',
          width: '100%',
        }),
      ),
      state(
        'out',
        style({
          height: '19px',
          width: '100%',
        }),
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class TabLimitesComponent {
  @Input() public dados: any[];
  @Input() public tipo: string;
  @Input() public temProduto: boolean;
  @ViewChild('panel', { static: true })
  panel: MatExpansionModule;
  @ViewChild('regrasTab', { static: false })
  regrasTab: MatTabsModule;

  public menuOpen = 'in';

  constructor() {}

  setStatusToggle(status: boolean) {
    this.menuOpen = status ? 'in' : 'out';
  }
  statusToggle(event: Event) {
    event.stopPropagation();
    this.menuOpen = this.menuOpen === 'out' ? 'in' : 'out';
  }
}
