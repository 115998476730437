import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonitorAlertsComponent } from './monitor-alerts.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MonitorAlertsComponent],
  exports: [MonitorAlertsComponent],
  providers: [MonitorAlertsComponent]
})
export class MonitorAlertsModule { }
