export interface iCamposOperForm {
  fundo: boolean;
  produto: boolean;
  contrato: boolean;
  quantidade: boolean;
  taxa: boolean;
  pu: boolean;
  premio: boolean;
  volatilidade: boolean;
  financeiro: boolean;
  corretora: boolean;
  cnpj: boolean;
  emissor: boolean;
  valorEmissao: boolean;
  dataEmissao: boolean;
  vencimento: boolean;
  contraparte: boolean;
  classeOperacao: boolean;
}
export class CamposOperForm implements iCamposOperForm {
  public fundo = true;
  public produto = true;
  public contrato = true;
  public quantidade = true;
  public taxa = true;
  public pu = true;
  public premio = true;
  public volatilidade = true;
  public financeiro = true;
  public corretora = true;
  public cnpj = true;
  public emissor = true;
  public valorEmissao = true;
  public dataEmissao = true;
  public vencimento = true;
  public contraparte = true;
  public classeOperacao = true;
  constructor() {
    this.reset();
  }
  public reset() {
    this.fundo = true;
    this.produto = true;
    this.contrato = true;
    this.quantidade = true;
    this.taxa = true;
    this.pu = true;
    this.premio = true;
    this.volatilidade = true;
    this.financeiro = true;
    this.corretora = true;
    this.cnpj = true;
    this.emissor = true;
    this.valorEmissao = true;
    this.dataEmissao = true;
    this.vencimento = true;
    this.contraparte = true;
    this.classeOperacao = true;
  }
}
