import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { map, switchMap } from 'rxjs/operators';
import { iFundoSelecionado } from 'src/app/models/fundo-selecionado.interface';
import { Consolidado2Component } from '../components/consolidado2/consolidado2.component';
import { LimitesComponent } from '../components/limites/limites.component';
import { HttpService } from '../services/http/http.service';
import { InternalDataService } from '../services/internal-data/internal-data.service';

@Component({
  selector: 'app-min-max-datepicker',
  templateUrl: './min-max-datepicker.component.html',
  styleUrls: ['./min-max-datepicker.component.less'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
})
export class MinMaxDatepickerComponent {
  public dataPosicao = '';
  public enabledDates: Date[];
  public uuid: string;
  private newUUID: any;
  private url: String;
  public myForm: any;
  public dataSelecionada: any;
  public minDate: Date;
  public maxDate: Date;
  public fundo: iFundoSelecionado;

  constructor(
    private compConsolidado: Consolidado2Component,
    private compLimites: LimitesComponent,
    private httpService: HttpService,
    private route: ActivatedRoute,
    public localeService: BsLocaleService,
    private internalData: InternalDataService,
  ) {
    this.internalData.getFundoSelecionado().subscribe((res) => {
      
      this.fundo = res;
      this.uuid = res['id'];
      this.uuid = this.uuid == undefined ? localStorage.getItem('uuid') : this.uuid;
      localStorage.setItem('fundo', JSON.stringify(res));
      this.myForm = new FormGroup({
        dateFull: new FormControl(),
      });
      this.getDatasRelatorios();
    });
    this.url = route.url.pipe(map((segments) => segments.join('')))['destination']._value['0'].path;
    this.localeService.use('pt-br');
    defineLocale('pt-br', ptBrLocale);
  }

  ngOnInit(): void {}

  startDatepicker(valor: Date) {
    this.dataSelecionada = valor;
    const data = formatDate(valor, 'dd/MM/yyyy', 'pt-BR');
    this.getHash(data);
  }

  setMinMaxDates(datas) {
    const datasRelatorios: string[] = datas['datas_relatorios'];
    const dataPos: string = datas['data_posicao'];
    this.enabledDates = datasRelatorios.map((data) => new Date(data + 'T00:00:00-0300'));
    if (datasRelatorios.length != 0) {
      const list = [];
      list.push(datasRelatorios[0].split('-')[0], datasRelatorios[0].split('-')[1], datasRelatorios[0].split('-')[2]);
      list.push(
        datasRelatorios.slice(-1)[0].split('-')[0],
        datasRelatorios.slice(-1)[0].split('-')[1],
        datasRelatorios.slice(-1)[0].split('-')[2],
      );
      this.minDate = new Date(list[0], list[1] - 1, list[2]);
      this.maxDate = new Date(list[3], list[4] - 1, list[5]);
    } else {
      this.minDate = new Date();
      this.maxDate = new Date();
    }
    this.dataSelecionada = new Date(dataPos + 'T00:00:00-0300');
    
  }

  getHash(valor: string) {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          return this.httpService.getHash(this.uuid, valor);
        }),
      )
      .subscribe((msg) => {
        this.newUUID = msg['id'];
        this.fundo.id = this.newUUID;
        this.internalData.setFundoSelecionado(this.fundo);
        if (this.url === 'limites') {
          this.compLimites.criaLimites(this.newUUID);
        } else {
          this.compConsolidado.criarConsolidado(this.newUUID);
        }
      });
  }

  getDatasRelatorios() {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          return this.httpService.getDatasRelatorio(this.uuid);
        }),
      )
      .subscribe((msg) => {
        this.setMinMaxDates(msg);
      });
  }
}
