import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { RenderBoletasService } from 'src/app/services/render-boletas/render-boletas.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.less'],
})
export class ContentComponent implements OnInit {
  toggled: boolean;
  gestaoAberto: boolean;
  boletasAberto: boolean;
  relatorioAberto: boolean;
  relatorioCarregado: Observable<boolean>;
  public nomeFundo: string;

  constructor(private internalData: InternalDataService, private renderBoletas: RenderBoletasService) {
    this.internalData.getSidebarToggle().subscribe((val) => (this.toggled = val));
    this.internalData.getGestaoAberto.subscribe((val) => (this.gestaoAberto = val));
    this.internalData.getBoletasAberto().subscribe((val) => (this.boletasAberto = val));
    this.internalData.getRelatorioAberto.subscribe((val) => (this.relatorioAberto = val));
    this.relatorioCarregado = this.internalData.getRelatorioCarregado;
    this.internalData.getFundoSelecionado().subscribe((fundo) => {
      this.nomeFundo = fundo.nome;
    });
  }

  ngOnInit() {}
}
