import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuardService } from '../auth-guard/auth-guard.service';
import { HttpService } from '../http/http.service';

export interface CookieInterfaceParams {
  name: string;
  value: string;
  expires?: number | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'Lax' | 'None' | 'Strict';
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpService,
    private cookies: CookieService,
    private guard: AuthGuardService,
    private router: Router,
  ) {}

  private token: string = '';

  getToken() {
    return this.token;
  }

  setToken(value) {
    this.token = value;
  }

  public login(user: string, pass: string) {
    this.http
      .postAuth({
        username: user,
        password: pass,
      })
      .subscribe((ret) => {
        this.cookies.set('sessionid', ret['token'], undefined, '/', undefined, true, 'Strict');
        this.cookies.set('sessionid', ret['token'], undefined, '/', undefined, undefined, 'Strict');
        this.guard.setIsAuth(true);
        this.token = ret['token'];
        this.router.navigateByUrl(`/home`);
      });
  }
}
