import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NumberFormatPipe } from './number.pipe';

@NgModule({
  imports: [CommonModule, RouterModule],
  exports: [CommonModule, NumberFormatPipe],
  declarations: [NumberFormatPipe],
})
export class PipeModule {}
