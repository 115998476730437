import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class RenderConsolidadoService {
  private dadosConsolidado = new BehaviorSubject<object>({});
  // eslint-disable-next-line no-invalid-this
  getDadosConsolidado = this.dadosConsolidado.asObservable();

  setDadosConsolidado(uuid: string) {
    if (uuid != undefined) {
      this.httpService.getConsolidado(uuid).subscribe((msg) => {
        this.dadosConsolidado.next(msg);
      });
    }
  }

  constructor(private httpService: HttpService) {}
}
