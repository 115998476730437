import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from 'angular-highcharts';
import { CompileDirective } from './compile.directive';

@NgModule({
  imports: [RouterModule, CommonModule, NgbModule, ChartModule],
  declarations: [CompileDirective],
  exports: [CompileDirective],
})
export class CompileDirectiveModule {}
