import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { BaseCampoComEditComponent } from "../base-campo-com-edit/base-campo-com-edit.component";

@Component({
  selector: "app-campo-premio",
  templateUrl: "./campo-premio.component.html",
  styleUrls: ["../base-campo-com-edit/base-campo-com-edit.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: BaseCampoComEditComponent, useExisting: CampoPremioComponent },
  ],
})
export class CampoPremioComponent
  extends BaseCampoComEditComponent
  implements OnInit {
  ngOnInit() {
    this.nome = "Premio";
  }
}
