import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less']
})
export class LoaderComponent implements OnInit {

  @Input()
  public condition: Observable<boolean>;
  public isCondition: boolean;

  constructor() { 
  }

  ngOnInit() {
    this.condition.subscribe(val => this.isCondition = val);
  }

}
