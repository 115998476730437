import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnaliticoWebsocketService {
  analiticoWebsocket: WebSocketSubject<any>;

  constructor() {}

  startAnaliticoWebsocket(userToken: string) {
    this.analiticoWebsocket = webSocket({
      url: `${environment.websocket}/ws/canais/analitico/${userToken}/`,
      closeObserver: {
        next: () => {},
      },
      openObserver: {
        next: () => {},
      },
    });
    return this.getAnaliticoWebsocket();
  }

  getAnaliticoWebsocket() {
    return this.analiticoWebsocket.asObservable();
  }

  sendAnaliticoWebsocketMessage(message) {
    this.analiticoWebsocket.next({ message: message });
  }

  closeAnaliticoWebsocket() {
    this.analiticoWebsocket.complete();
  }
}
