import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { AgGridModule } from 'ag-grid-angular';
import { ChartModule } from 'angular-highcharts';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CookieService } from 'ngx-cookie-service';
import { NgxMarkjsModule } from 'ngx-markjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BoletasComponent } from './components/boletas/boletas.component';
import { CadastroOperComponent } from './components/cadastro-oper/cadastro-oper.component';
import { CalculosComponent } from './components/calculos/calculos.component';
import { CardComponent } from './components/card/card.component';
import { Consolidado2Component } from './components/consolidado2/consolidado2.component';
import { Consolidado2Module } from './components/consolidado2/consolidado2.module';
import { ConsumoComponent } from './components/consumo/consumo.component';
import { ContentComponent } from './components/content/content.component';
import { DefinicoesCalculoComponent } from './components/definicoes-calculo/definicoes-calculo.component';
import { DetalhamentoLimitesComponent } from './components/detalhamento-limites/detalhamento-limites.component';
import { GridLimitesComponent } from './components/grid-limites/grid-limites.component';
import { ErrosComponent } from './components/erros/erros.component';
import { GestaoComponent } from './components/gestao/gestao.component';
import { GridComponent } from './components/grid/grid.component';
import { GridboletasComponent } from './components/gridboletas/gridboletas.component';
import { HelpComponent } from './components/help/help.component';
import { Help2Component } from './components/help2/help2.component';
import { HomeComponent } from './components/home/home.component';
import { ImportBoletaComponent } from './components/import-boleta/import-boleta/import-boleta.component';
import { LimitesComponent } from './components/limites/limites.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingPageComponent } from './components/loading-page/loading-page.component';
import { ForgotPasswordComponent, LoginComponent } from './components/login/login.component';
import { MonitorAlertsModule } from './components/monitor-alerts/monitor-alerts.module';
import { NavbarModule } from './components/navbar/navbar.module';
import { BaseCampoComEditComponent } from './components/oper-form/campos/base-campo-com-edit/base-campo-com-edit.component';
import { CampoContraparteComponent } from './components/oper-form/campos/campo-contraparte/campo-contraparte.component';
import { CampoContratoComponent } from './components/oper-form/campos/campo-contrato/campo-contrato.component';
import { CampoCorretoraComponent } from './components/oper-form/campos/campo-corretora/campo-corretora.component';
import { CampoDataEmissaoComponent } from './components/oper-form/campos/campo-data-emissao/campo-data-emissao.component';
import { CampoEmissorComponent } from './components/oper-form/campos/campo-emissor/campo-emissor.component';
import { CampoFinanceiroComponent } from './components/oper-form/campos/campo-financeiro/campo-financeiro.component';
import { CampoFundoComponent } from './components/oper-form/campos/campo-fundo/campo-fundo.component';
import { CampoPorcIndexComponent } from './components/oper-form/campos/campo-porc-index/campo-porc-index.component';
import { CampoPremioComponent } from './components/oper-form/campos/campo-premio/campo-premio.component';
import { CampoProdutoComponent } from './components/oper-form/campos/campo-produto/campo-produto.component';
import { CampoPuComponent } from './components/oper-form/campos/campo-pu/campo-pu.component';
import { CampoQuantidadeComponent } from './components/oper-form/campos/campo-quantidade/campo-quantidade.component';
import { CampoTaxaOperComponent } from './components/oper-form/campos/campo-taxa-oper/campo-taxa-oper.component';
import { CampoTaxaSpreadComponent } from './components/oper-form/campos/campo-taxa-spread/campo-taxa-spread.component';
import { CampoTipoOperComponent } from './components/oper-form/campos/campo-tipo-oper/campo-tipo-oper.component';
import { CampoTipoProdutoComponent } from './components/oper-form/campos/campo-tipo-produto/campo-tipo-produto.component';
import { CampoValorEmissaoComponent } from './components/oper-form/campos/campo-valor-emissao/campo-valor-emissao.component';
import { CampoVencimentoComponent } from './components/oper-form/campos/campo-vencimento/campo-vencimento.component';
import { CampoVolatilidadeComponent } from './components/oper-form/campos/campo-volatilidade/campo-volatilidade.component';
import { FormAcaoComponent } from './components/oper-form/forms/form-acao/form-acao.component';
import { FormBaseComponent } from './components/oper-form/forms/form-base/form-base.component';
import { FormBtcComponent } from './components/oper-form/forms/form-btc/form-btc.component';
import { FormCdbComponent } from './components/oper-form/forms/form-cdb/form-cdb.component';
import { FormCompromissadaComponent } from './components/oper-form/forms/form-compromissada/form-compromissada.component';
import { FormCotaFundoComponent } from './components/oper-form/forms/form-cota-fundo/form-cota-fundo.component';
import { FormFuturoComponent } from './components/oper-form/forms/form-futuro/form-futuro.component';
import { FormOpcaoComponent } from './components/oper-form/forms/form-opcao/form-opcao.component';
import { FormTituloPrivadoComponent } from './components/oper-form/forms/form-titulo-privado/form-titulo-privado.component';
import { FormTituloPublicoComponent } from './components/oper-form/forms/form-titulo-publico/form-titulo-publico.component';
import { OperFormComponent } from './components/oper-form/oper-form.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { PopupConfirmacaoComponent } from './components/popup-confirmacao/popup-confirmacao.component';
import { SidebarContentComponent } from './components/sidebar/sidebar-content/sidebar-content.component';
import { SidebarDockComponent } from './components/sidebar/SidebarDock/SidebarDock.component';
import { TabLimitesComponent } from './components/tab-limites/tab-limites.component';
import { UploadXmlComponent } from './components/upload-xml/upload-xml.component';
import { CompileDirectiveModule } from './directives/compile-directive.module';
import { MinMaxDatepickerModule } from './min-max-datepicker/min-max-datepicker.module';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { AuthService } from './services/auth/auth.service';
import { AuthInterceptorService } from './services/auth_interceptor/auth-interceptor.service';
import { FormBoletasDataService } from './services/form-boletas-data/form-boletas-data.service';
import { HighchartsService } from './services/highcharts/highcharts.service';
import { HttpService } from './services/http/http.service';
import { InternalDataService } from './services/internal-data/internal-data.service';
import { UserInfoService } from './services/user-info/user-info.service';
import { ValidatorsService } from './services/validators/validators.service';
import { WebsocketService } from './services/websocket/websocket.service';

const ANGULAR_NOTIFIER_CONFIG: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    GridComponent,
    GridboletasComponent,
    OperFormComponent,
    CadastroOperComponent,
    CardComponent,
    ContentComponent,
    UploadXmlComponent,
    LoginComponent,
    HomeComponent,
    HelpComponent,
    Help2Component,
    ConsumoComponent,
    LoadingPageComponent,
    LimitesComponent,
    CalculosComponent,
    ErrosComponent,
    GestaoComponent,
    BoletasComponent,
    LoaderComponent,
    PerfilComponent,
    ForgotPasswordComponent,
    DefinicoesCalculoComponent,
    SidebarContentComponent,
    SidebarDockComponent,
    ImportBoletaComponent,
    PopupConfirmacaoComponent,
    BaseCampoComEditComponent,
    CampoContraparteComponent,
    CampoContratoComponent,
    CampoCorretoraComponent,
    CampoDataEmissaoComponent,
    CampoEmissorComponent,
    CampoFinanceiroComponent,
    CampoFundoComponent,
    CampoPorcIndexComponent,
    CampoPremioComponent,
    CampoProdutoComponent,
    CampoPuComponent,
    CampoQuantidadeComponent,
    CampoTaxaOperComponent,
    CampoTaxaSpreadComponent,
    CampoTipoOperComponent,
    CampoTipoProdutoComponent,
    CampoValorEmissaoComponent,
    CampoVencimentoComponent,
    CampoVolatilidadeComponent,
    FormCompromissadaComponent,
    FormAcaoComponent,
    FormOpcaoComponent,
    FormTituloPublicoComponent,
    FormCotaFundoComponent,
    FormFuturoComponent,
    FormTituloPrivadoComponent,
    FormBtcComponent,
    FormCompromissadaComponent,
    FormBaseComponent,
    FormCdbComponent,
    TabLimitesComponent,
    GridLimitesComponent,
    DetalhamentoLimitesComponent,
  ],
  imports: [
    AlertModule.forRoot(),
    NavbarModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AgGridModule.withComponents([]),
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    DragDropModule,
    TooltipModule,
    MatSelectModule,
    NgbModule,
    ChartModule,
    MatCheckboxModule,
    MatTabsModule,
    MatCardModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    NgxMarkjsModule,
    BsDatepickerModule.forRoot(),
    NgxMarkjsModule,
    ScrollingModule,
    CompileDirectiveModule,
    Consolidado2Module,
    MinMaxDatepickerModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    PDFExportModule,
    MatSidenavModule,
    CommonModule,
    NotifierModule.withConfig(ANGULAR_NOTIFIER_CONFIG),
    MonitorAlertsModule,
    MatDividerModule,
    MatIconModule,
    MatRadioModule,
    MatListModule,
  ],
  providers: [
    HttpService,
    WebsocketService,
    InternalDataService,
    UserInfoService,
    HighchartsService,
    Consolidado2Component,
    LimitesComponent,
    LoginComponent,
    AuthInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    AuthGuardService,
    CookieService,
    AuthService,
    GridboletasComponent,
    SidebarContentComponent,
    FormBoletasDataService,
    ValidatorsService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UploadXmlComponent,
    PopupConfirmacaoComponent,
    ImportBoletaComponent,
    CadastroOperComponent,
    FormAcaoComponent,
  ],
})
export class AppModule {}
