import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi } from 'ag-grid-community';
import { NotifierService } from 'angular-notifier';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { RenderBoletasService } from 'src/app/services/render-boletas/render-boletas.service';
import { nomeClasseOperacao } from 'src/app/utils';
import { HttpService } from '../../services/http/http.service';
import { WebsocketService } from '../../services/websocket/websocket.service';
import { CadastroOperComponent } from '../cadastro-oper/cadastro-oper.component';
import { ImportBoletaComponent } from '../import-boleta/import-boleta/import-boleta.component';
import { MonitorAlertsComponent } from '../monitor-alerts/monitor-alerts.component';
import { PopupConfirmacaoComponent } from '../popup-confirmacao/popup-confirmacao.component';
@Component({
  selector: 'app-gridboletas',
  templateUrl: './gridboletas.component.html',
  styleUrls: ['./gridboletas.component.less'],
})
export class GridboletasComponent implements OnInit {
  @ViewChild('agGrid', { static: false }) agGrid: AgGridAngular;

  private readonly notifier: NotifierService;
  private gridApi: GridApi;
  public getRowNodeId;
  public getRowStyle;
  private nomeInstituicao: string;
  private parametroIdFundo: string;
  private fundoSelecionado: any;
  public variavelParaOcultar = false;

  // public modules: Module[] = AllCommunityModules;
  rowData: any;

  columnDefs = [
    {
      lockPosition: true,
      minWidth: 50,
      maxWidth: 50,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      headerName: 'ID',
      field: 'id',
      cellClass: 'cell-number',
      lockPosition: true,
      resizable: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Tipo Operação',
      field: 'tipo_oper',
      cellClass: 'cell-number',
      lockPosition: false,
      resizable: true,
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Produto',
      field: 'Produto',
      cellClass: 'cell-number',
      lockPosition: false,
      resizable: true,
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Corretora',
      field: 'Corretora',
      cellClass: 'cell-number',
      lockPosition: false,
      resizable: true,
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Quantidade',
      field: 'Quantidade',
      cellClass: 'cell-number',
      lockPosition: false,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter2,
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Preço',
      field: 'Preco',
      cellClass: 'cell-number',
      lockPosition: false,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter2,
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Operação',
      field: 'Operacao',
      cellClass: 'cell-number',
      lockPosition: false,
      resizable: true,
      sortable: true,
      filter: true,
      hide: true,
    },
    {
      headerName: 'Processado',
      field: 'Processado',
      cellClass: 'cell-number',
      lockPosition: false,
      resizable: true,
      sortable: true,
      filter: true,
      cellStyle: { textAlign: 'center' },
    },
  ];

  socket: Subject<MessageEvent>;

  constructor(
    private websocket: WebsocketService,
    private http: HttpService,
    private cookies: CookieService,
    private internalData: InternalDataService,
    private renderBoletas: RenderBoletasService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    public notifierService: NotifierService,
    public monitorAlertsComponent: MonitorAlertsComponent,
  ) {
    this.notifier = notifierService;

    this.getRowNodeId = (data) => {
      return data.id;
    };

    this.internalData.getFundoSelecionado().subscribe((fundo) => {
      this.fundoSelecionado = fundo['cadastro_xml_id'];
      this.parametroIdFundo = fundo['id'];
      this.renderBoletas.setDadosBoleta(fundo['cadastro_xml_id']);
    });
    this.http.getUserInfo().subscribe((ret) => {
      this.nomeInstituicao = ret['instituicao_nome'];
    });
  }

  private criaItens(boletas: { [tipo: string]: {}[] }) {
    let listaFinal: {}[] = [];
    Object.entries(boletas).forEach((parChaveArray) => {
      const tipo = parChaveArray[0];
      const lista = parChaveArray[1];
      const listaTipo = lista.map((boleta) => {
        const tipoOper = nomeClasseOperacao.get(boleta['cod_classe_operacao']);
        const novoItem = {};

        novoItem['id'] = boleta['cod_oper'];
        novoItem['tipo_oper'] = tipoOper;
        novoItem['Fundo'] = boleta['cod_fundo'];
        if (boleta['nome_produto']) {
          novoItem['Produto'] = boleta['nome_produto'];
        } else {
          novoItem['Produto'] = boleta['nome_contrato'];
        }
        novoItem['Corretora'] = boleta['nome_corretora'];
        novoItem['Quantidade'] = boleta['quantidade'];
        novoItem['Preco'] = boleta['pu'];
        novoItem['Operacao'] = tipo;
        novoItem['Processado'] = boleta['check'] ? 'Processado' : '';
        return novoItem;
      });
      listaFinal = listaFinal.concat(listaTipo);
    });

    this.gridApi.setRowData(listaFinal);
  }

  /**
   * Escuta a mudança de tamanho de tela
   * @param event
   */
  @HostListener('window:resize', [])
  onResize() {
    this.gridApi.sizeColumnsToFit();
  }

  openDialogOper() {
    this.internalData.setOperaBoleta({ operacao: 'cadastrar' });
    const dialogRef = this.dialog.open(CadastroOperComponent);
    dialogRef.afterClosed().subscribe(() => true);
  }

  openDialogImportar() {
    const dialogRef = this.dialog.open(ImportBoletaComponent);
    dialogRef.afterClosed().subscribe(() => true);
  }

  removerBoletas() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();

    this.abrirPopupConfirmacao('Deseja apagar esta boleta?', 'Esta operação não pode ser desfeita').then(
      (respostaPopup) => {
        if (respostaPopup) {
          if (selectedNodes.length > 0) {
            const boletaData = selectedNodes.map((boleta) => boleta.data);

            this.http.postRemoverBoletas(boletaData).subscribe(() => {
              this.renderBoletas.setDadosBoleta(this.fundoSelecionado);
            });
          } else {
            this.monitorAlertsComponent.abrirAlert(
              'Aviso',
              'Para remover boleta é necessário selecionar ao menos uma',
              false,
              'ok',
            );
          }
        }
      },
    );
  }

  editarBoleta() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();

    if (selectedNodes.length == 1) {
      this.internalData.setOperaBoleta({
        operacao: 'editar',
        boleta: selectedNodes[0].data,
      });

      const dialogConfig = new MatDialogConfig();

      const dialogRef = this.dialog.open(CadastroOperComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => true);

      this.renderBoletas.setDadosBoleta(this.fundoSelecionado);
    } else {
      this.monitorAlertsComponent.abrirAlert('Aviso', 'Selecione uma boleta para editar!', false, 'Ok');
    }
  }

  reprocessarConsolidado() {
    const selectedNodes = this.agGrid.api.getSelectedNodes();
    const listaBoletas = [];

    if (selectedNodes.length > 0) {
      this.abrirPopupConfirmacao('Deseja reprocessar o relatório?', 'Isso levará alguns instantes').then(
        (respostaPopup) => {
          if (respostaPopup) {
            selectedNodes.forEach((node) => {
              listaBoletas.push(node.data);
            });

            const conteudoPost = {
              nomeInstituicao: this.nomeInstituicao,
              parametroIdFundo: this.parametroIdFundo,
              listaBoletas: listaBoletas,
              showAlert: true,
            };

            this.notifier.notify('success', 'Seu relatório está sendo reprocessado!');
            this.http.postReprocessaConsolidado(conteudoPost).subscribe((_res) => {});
          }
        },
      );
    } else {
      this.monitorAlertsComponent.abrirAlert(
        'Aviso',
        'Selecione pelo menos uma boleta para reprocessar o relatório.',
        false,
        'Ok',
      );
    }
  }

  /**
   * O retorno do fechamento do popup é um boolean true/false dependendo do botão que é clicado
   */

  abrirPopupConfirmacao(titulo: string, mensagem: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    dialogConfig.maxWidth = '500px';
    dialogConfig.data = { title: titulo, message: mensagem };

    const dialogRef = this.dialog.open(PopupConfirmacaoComponent, dialogConfig);

    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe(
        (result) => {
          resolve(result);
        },
        () => {
          resolve(false);
        },
      );
    });
  }

  onGridReady(params) {
    this.internalData.setParamsGridBoleta(params);
    this.gridApi = params.api;
    this.renderBoletas.getDadosBoleta.subscribe((boletas: { [tipo: string]: {}[] }) => this.criaItens(boletas));
    this.gridApi.sizeColumnsToFit();
    window.dispatchEvent(new Event('resize'));
  }

  formatter2(params) {
    return Number(params.value).toFixed(2).replace('.', ',');
  }

  formatter6(params) {
    return Number(params.value).toFixed(6).replace('.', ',');
  }

  ngOnInit() {}

  public addOrUpdate(id: string, field: string, value: number) {
    // Update
    const rowNode = this.gridApi.getRowNode(id);
    if (rowNode != undefined) {
      rowNode.setDataValue(field, Number(value));
      return;
    }

    // Cria um row novo
    const novoItem = {};
    novoItem['id'] = id;
    novoItem[field] = Number(value);
    this.gridApi.updateRowData({ add: [novoItem] });
    window.dispatchEvent(new Event('resize'));
  }
}
