import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NavbarModule } from '../components/navbar/navbar.module';
import { MinMaxDatepickerComponent } from './min-max-datepicker.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    NavbarModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
  ],
  declarations: [MinMaxDatepickerComponent],
  exports: [MinMaxDatepickerComponent],
})
export class MinMaxDatepickerModule {}
