import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { SidebarContentComponent } from '../sidebar/sidebar-content/sidebar-content.component';

@Component({
  selector: 'app-calculos',
  templateUrl: './calculos.component.html',
  styleUrls: ['./calculos.component.less'],
})
export class CalculosComponent implements OnInit {
  public toggled: boolean;
  public retorno: any;
  public filtrado: [];
  public tipoCalculo: TipoCalculo;
  public textoFiltro: string;
  public tipoCalculoVisual: string;

  get tipos() {
    return TipoCalculo;
  }

  constructor(
    private internal: InternalDataService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private sideBar: SidebarContentComponent,
  ) {
    this.internal.getSidebarToggle().subscribe((msg) => (this.toggled = msg));
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.tipoCalculo = TipoCalculo[params.get('tipo')];
          this.tipoCalculoVisual = TipoCalculoVisual[this.tipoCalculo];
          return this.http.getCalculos(params.get('tipo'));
        }),
      )
      .subscribe((msg) => {
        this.retorno = msg;
        this.filtrado = this.retorno['calculos'];
      });
  }

  deletar(calculo) {
    this.http.postCancelaCalculo(calculo.uuid).subscribe(() => {
      const index = this.retorno['calculos'].indexOf(calculo);
      this.retorno['calculos'].splice(index, 1);
    });
  }

  filtrar() {
    this.filtrado = this.retorno['calculos'].filter((element, index, array) => {
      return (
        element.nome_fundo.toLowerCase().includes(this.textoFiltro.toLowerCase()) ||
        element.data_posicao.toLowerCase().includes(this.textoFiltro.toLowerCase())
      );
    });
  }

  gotoRedirecionamentoFinalizado(parametro) {
    this.router.navigateByUrl(`/home`);
    let uuid = parametro['uuid'];

    this.http.getEstruturaByUuid(uuid).subscribe((res) => {
      this.sideBar.selecionaFundo(res['retorno']);
    });
  }
}

export enum TipoCalculo {
  'finalizados' = 0,
  'calculando' = 1,
  'esperando' = 2,
  'cancelados' = 3,
}

export enum TipoCalculoVisual {
  'Finalizados' = 0,
  'Calculando' = 1,
  'Esperando Cálculo' = 2,
  'Cancelados' = 3,
}
