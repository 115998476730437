import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { BaseCampoComEditComponent } from "../base-campo-com-edit/base-campo-com-edit.component";

@Component({
  selector: "app-campo-volatilidade",
  templateUrl: "./campo-volatilidade.component.html",
  styleUrls: ["../base-campo-com-edit/base-campo-com-edit.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: BaseCampoComEditComponent,
      useExisting: CampoVolatilidadeComponent,
    },
  ],
})
export class CampoVolatilidadeComponent
  extends BaseCampoComEditComponent
  implements OnInit {
  ngOnInit() {
    this.nome = "Volatilidade:";
  }
}
