import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-loading-page",
  templateUrl: "./loading-page.component.html",
  styleUrls: ["./loading-page.component.less"],
})
export class LoadingPageComponent implements OnInit {
  router: any;
  toggled: boolean;

  constructor() {}

  ngOnInit() {}
}
