import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ChartModule } from 'angular-highcharts';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CompileDirectiveModule } from 'src/app/directives/compile-directive.module';
import { MinMaxDatepickerModule } from 'src/app/min-max-datepicker/min-max-datepicker.module';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { NavbarModule } from '../navbar/navbar.module';
import { Consolidado2Component } from './consolidado2.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    NavbarModule,
    MatExpansionModule,
    CompileDirectiveModule,
    MatCheckboxModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MinMaxDatepickerModule,
    PipeModule,
    PDFExportModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    ChartModule,
    AlertModule.forRoot(),
  ],
  declarations: [Consolidado2Component],
  exports: [Consolidado2Component, PipeModule],
})
export class Consolidado2Module {}
