import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseCampoComEditComponent } from '../base-campo-com-edit/base-campo-com-edit.component';

@Component({
  selector: 'app-campo-produto',
  templateUrl: './campo-produto.component.html',
  styleUrls: ['../base-campo-com-edit/base-campo-com-edit.component.less'],
  providers: [{ provide: BaseCampoComEditComponent, useExisting: CampoProdutoComponent }],
})
export class CampoProdutoComponent extends BaseCampoComEditComponent implements OnInit {
  ngOnInit() {
    this.nome = 'Produto';
    this.prefixo = 'produto';
    this.formControl = new FormControl(null, this.requireMatch());
  }
}
