import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupConfirmacaoComponent } from '../popup-confirmacao/popup-confirmacao.component';

@Component({
  selector: 'app-monitor-alerts',
  templateUrl: './monitor-alerts.component.html',
  styleUrls: ['./monitor-alerts.component.less'],
})
export class MonitorAlertsComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit() {}
  abrirAlert(
    title: string,
    message: string,
    showCancel: boolean = true,
    okText: string = 'Sim',
    cancelText: string = 'Não',
  ) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';
    dialogConfig.maxWidth = '500px';
    dialogConfig.data = {
      title: title,
      message: message,
      showCancel: showCancel,
      okText: okText,
      cancelText: cancelText,
    };
    const dialogRef = this.dialog.open(PopupConfirmacaoComponent, dialogConfig);
  }
}
