import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginComponent } from '../../components/login/login.component';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  public conteudoErro = 1;

  constructor(private router: Router, private auth: AuthService, public LOGIN: LoginComponent) {
    // this.conteudoErro = this.erros.error;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.match('.*/auth$') == null) {
      // ignora se for url de autenticacao
      request = request.clone({
        setHeaders: {
          Authorization: `Token ${this.auth.getToken()}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401 || error.status == 403) {
          this.router.navigateByUrl('/login');
        } else if (error.status == 400) {
          this.LOGIN.alertaSenha();
        }
        return throwError(error);
      }),
    );
  }
}
