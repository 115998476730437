import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseCampoComEditComponent } from '../base-campo-com-edit/base-campo-com-edit.component';

@Component({
  selector: 'app-campo-contraparte',
  templateUrl: './campo-contraparte.component.html',
  styleUrls: ['../base-campo-com-edit/base-campo-com-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  providers: [
    {
      provide: BaseCampoComEditComponent,
      useExisting: CampoContraparteComponent,
    },
  ],
})
export class CampoContraparteComponent extends BaseCampoComEditComponent implements OnInit {
  ngOnInit() {
    this.nome = 'Contraparte';
    this.prefixo = 'contraparte';
    this.formControl = new FormControl(null, this.requireMatchOrNull());
  }
}
