import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { SidebarContentComponent } from '../sidebar/sidebar-content/sidebar-content.component';

@Component({
  selector: 'app-erros',
  templateUrl: './erros.component.html',
  styleUrls: ['./erros.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    // { provide:BsLocaleService}
  ],
})
export class ErrosComponent implements OnInit {
  public uuid = '';

  minDate: Date;
  maxDate: Date;
  public retorno: any[];
  public filtrado: any[];
  public corrigido: boolean;
  public fundoFiltro: string = '';
  public distinctFundos: string[];
  public dataFiltro: string;

  dataMenor: any;
  menorData: string;
  dataMaior: any;
  maiorData: string;
  disabledDates = [];
  millisecondPerDay = 86400 * 1000;
  enabledDates = [];
  todasAsDatas = [];
  @ViewChild('valorerros', { static: false }) valorErros: ElementRef;
  public i: number;

  set Corrigido(value: any) {
    value = value == 'true';
    this.corrigido = value;
    this.filtrar();
  }

  get Corrigido() {
    return this.corrigido;
  }

  toggled: boolean;
  data_posicao: [];

  constructor(
    private http: HttpService,
    public localeService: BsLocaleService,
    private internalData: InternalDataService,
    private router: Router,
    private route: ActivatedRoute,
    private sideBar: SidebarContentComponent,
  ) {
    this.localeService.use('pt-br');
    defineLocale('pt-br', ptBrLocale);
    this.corrigido = false;
    this.internalData.getSidebarToggle().subscribe((val) => {
      this.toggled = val;
    });
  }

  ngOnInit() {
    this.datasPicker();
    this.http.getErros().subscribe((msg) => {
      this.retorno = msg;
      this.filtrado = msg;
      this.corrigido = false;
      this.distinctFundos = [...new Set(msg.map((fundo) => fundo['nome_fundo']))]; // pega distinct dos nomes dos fundos
      // (https://stackoverflow.com/questions/38206915/filter-out-array-to-have-only-unique-values)
      this.dataFiltro = '';
      this.filtrar();
      for (let x = 0; x <= this.filtrado['0']['data_posicao_date_picker'].length; x++) {
        this.todasAsDatas.push(this.filtrado[x]['data_posicao_date_picker']);
      }
      let MenorValor;
      let MaiorValor: any;
      MenorValor = this.todasAsDatas.sort();
      MaiorValor = MenorValor.slice(-1);
      this.minDate = new Date(
        MenorValor[0].slice(0, 4) + ',' + MenorValor[0].slice(5, 7) + ',' + MenorValor[0].slice(8, 10),
      );
      this.maxDate = new Date(
        MaiorValor[0].slice(0, 4) + ',' + +MaiorValor[0].slice(5, 7) + ',' + MaiorValor[0].slice(8, 10),
      ); // e maior range do date picker
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('uuid') != null) {
        this.uuid = params.get('uuid');
      } else {
        this.uuid = '';
      }
    });
  }

  datas(dataCompleta) {
    if (dataCompleta == null) {
      // reincio aplicacao se vazio
      this.ngOnInit();
    }
    const tamanhocaracter = +dataCompleta.getMonth() + 1;
    if (tamanhocaracter.toString().length < 2) {
      let tamanhocaracter2;
      tamanhocaracter2 = '0' + tamanhocaracter; // append no mes
      this.dataFiltro = +dataCompleta.getDate() + '/' + tamanhocaracter2 + '/' + dataCompleta.getFullYear();
    } else {
      this.dataFiltro =
        +dataCompleta.getDate() + '/' + (+dataCompleta.getMonth() + 1) + '/' + dataCompleta.getFullYear();
    }
    this.fundoFiltro = '';
    this.filtrar();
    this.uuid = '';
  }

  GetDisabledDates(excludeDates: Array<Date>) {
    const now = new Date();
    let startDate: Date = new Date(now.setFullYear(now.getFullYear() - 2));
    const endDate: Date = new Date(now.setFullYear(now.getFullYear() + 2));
    this.disabledDates = [];
    do {
      let found = false;
      for (let i = 0; i < excludeDates.length; i++) {
        const excludeDate: Date = excludeDates[i];
        if (this.IsSameDay(excludeDate, startDate)) {
          found = true;
        }
      }
      if (!found) {
        this.disabledDates.push(startDate);
      }
      startDate = new Date(startDate.getTime() + 86400000);
    } while (startDate <= endDate);
  }

  datasPicker() {
    this.http.getErros().subscribe((msg) => {
      this.filtrado = msg;
      const datasBackend = [];
      this.filtrado.forEach((item) => {
        datasBackend.push(item.data_posicao_date_picker);
      });

      for (let x = 0; x <= datasBackend.length; x++) {
        this.enabledDates.push(new Date(datasBackend[x])); // incremento datas
      }
      this.GetDisabledDates(this.enabledDates); // chamo metodo
    });
  }

  onProductChange() {
    this.uuid = '';
    this.dataFiltro = '';
    this.filtrar();
  }

  IsSameDay(date1: Date, date2: Date) {
    if (
      date1.getFullYear() == date2.getFullYear() &&
      date1.getMonth() == date2.getMonth() &&
      date1.getDate() + 1 == date2.getDate()
    ) {
      // altero data para +1, esta por array
      return true;
    } else {
      return false;
    }
  }

  filtrar() {
    this.filtrado = JSON.parse(JSON.stringify(this.retorno));
    this.filtrarCorrigidos();
    this.filtrarFundos();
    this.filtrarDatas();
  }

  filtrarFundos() {
    if (this.fundoFiltro != '') {
      this.filtrado = this.filtrado.filter((fundo) => fundo.nome_fundo == this.fundoFiltro);
    }
  }

  filtrarCorrigidos() {
    this.filtrado = this.filtrado.map((fundo) => {
      if (fundo.logs.luz) {
        fundo.logs.luz = fundo.logs.luz.filter((log) => this.toBool(log['corrigido']) == this.corrigido);
        if (fundo.logs.luz.length == 0) {
          delete fundo.logs.luz;
        }
      }
      if (fundo.logs.usuario) {
        fundo.logs.usuario = fundo.logs.usuario.filter((log) => this.toBool(log['corrigido']) == this.corrigido);
        if (fundo.logs.usuario.length == 0) {
          delete fundo.logs.usuario;
        }
      }

      return fundo;
    });

    this.filtrado = this.filtrado.filter((fundo) => fundo.logs.usuario || fundo.logs.luz);
  }

  filtrarDatas() {
    if (this.dataFiltro != '') {
      this.filtrado = this.filtrado.filter((fundo) => fundo.data_posicao == this.dataFiltro);
    }
  }

  toBool(value) {
    return value == 'True';
  }

  apagaErro(log) {
    this.http.getApagaErro(log.id).subscribe(() => {
      this.http.getErros().subscribe((msg) => {
        this.retorno = msg;
        this.filtrado = msg;
        this.filtrar();
      });
    });
  }

  enviaEmail(fundo) {
    if (!fundo.enviado) {
      this.http.getEnviaEmail(fundo.uuid).subscribe(() => {
        fundo.enviado = true;
      });
    }
  }

  gotoRedirecionamento(parametro) {
    const uuid = parametro['uuid'];

    this.http.getEstruturaByUuid(uuid).subscribe((res) => {
      this.router.navigateByUrl(`/home`);
      this.sideBar.selecionaFundo(res['retorno']);
    });
  }
}
