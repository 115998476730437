import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  private usuario: string;
  private nome: string;
  private email: string;
  private instituicao: string;
  private descricao: string;

  constructor() {}

  setclassdadosCadastrais(usuario: string, nome: string, email: string, instituicao: string, descricao: string) {
    this.usuario = usuario;
    this.nome = nome;
    this.email = email;
    this.instituicao = instituicao;
    this.descricao = descricao;
  }

  getClassDadosCadastrais() {
    return {
      usuario: this.usuario,
      nome: this.nome,
      email: this.email,
      instituicao: this.instituicao,
      descricao: this.descricao,
    };
  }
}
