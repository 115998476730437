import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-popup-confirmacao',
  templateUrl: './popup-confirmacao.component.html',
  styleUrls: ['./popup-confirmacao.component.less']
})

export class PopupConfirmacaoComponent implements OnInit {

  public title = 'Sem título';
  public message = 'Lorem ipsum';
  public showCancel = true;
  public okText = 'Sim';
  public cancelText = 'Não';

  constructor(
    public dialogRef: MatDialogRef<PopupConfirmacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Boolean) {
      if (data.hasOwnProperty('title')) {
        this.title = data['title'];
      }
      if (data.hasOwnProperty('message')) {
        this.message = data['message'];
      }
      if (data.hasOwnProperty('showCancel')) {
        this.showCancel = data['showCancel'];
      }
      if (data.hasOwnProperty('okText')) {
        this.okText = data['okText'];
      }
      if (data.hasOwnProperty('cancelText')) {
        this.cancelText = data['cancelText'];
      }
    }

    ngOnInit() {

    }

}
