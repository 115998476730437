export enum tiposProdutosMITRA {
  Composicoes = -865,
  ContaBancaria = -1434,
  Cosif = -1380,
  Fundos = -785,
  Futuros = -664,
  Indexadores = -647,
  Lancamentos = -764,
  Moedas = -603,
  Opcoes = -698,
  RF = -6201,
  Stocks = -446,
  SwapRF = -7355,
  VerticesDefinidos = -1313,
  OperacoesEstruturadas = -3741,
}

export enum classeOperacao {
  Compra = -7849,
  Venda = -7850,
  Doado = -7851,
  Tomado = -7852,
  Aplicação = -7853,
  Resgate = -7854,
  ResgateTotal = -7855,
  ExercícioOpcao = -7856,
  Aporte = -7965,
  Retirada = -7966,
  Antecipação = -8779,
  Renovação = -8846,
}
export let nomeClasseOperacao = new Map<number, string>([
  [-7849, "Compra"],
  [-7850, "Venda"],
  [-7851, "Doado"],
  [-7852, "Tomado"],
  [-7853, "Aplicação"],
  [-7854, "Resgate"],
  [-7855, "Resgate Total"],
  [-7856, "Exercício Opção"],
  [-7965, "Aporte"],
  [-7966, "Retirada"],
  [-8779, "Antecipação"],
  [-8846, "Renovação"],
]);

export enum ModalidadeOperacao {
  Normal = -4067,
  Termo = -4069,
  Emprestimo = -4068,
  Pronto = -5681,
}

export enum TIPOS_PERFIL {
  asset = "Asset",
  previdencia = "Previdencia",
}

export enum TiposOpers {
  Acao,
  Opcao,
  TituloPublico,
  TituloPrivado,
  Fundo,
  Futuro,
  Emprestimo,
  Compromissada,
  Cdb,
}
