import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { UserWebsocketService } from 'src/app/services/websocket/user-websocket/user-websocket.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { AnaliticoWebsocketService } from 'src/app/services/websocket/analitico-websocket/analitico-websocket.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {  

  constructor(
    private http: HttpService,
    private userWebsocket: UserWebsocketService,
    private analiticoWebsocket: AnaliticoWebsocketService,
    private internal: InternalDataService
    ) {
    this.http.getVerificarRedis().subscribe(response => {
    });
    this.internal.getSessionToken.subscribe(token => {
      this.userWebsocket.startUserWebsocket(token);
      this.analiticoWebsocket.startAnaliticoWebsocket(token);
    })
  }

  ngOnInit() {
    if (localStorage.getItem('fundo')){
      var value = JSON.parse(localStorage.getItem('fundo'))
      
      this.internal.setSelectedLine(value);
      this.internal.setFundoSelecionado(value);
      this.internal.setRelatorioCarregado(false);
      this.internal.setRelatorioAberto(true);
    }
  }

}
