import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GridLimitesComponent } from '../grid-limites/grid-limites.component';
import { TabLimitesComponent } from '../tab-limites/tab-limites.component';

@Component({
  selector: 'app-detalhamento-limites',
  templateUrl: './detalhamento-limites.component.html',
  styleUrls: ['./detalhamento-limites.component.less'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          height: '*',
          width: '100%',
        }),
      ),
      state(
        'out',
        style({
          height: '19px',
          width: '100%',
        }),
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class DetalhamentoLimitesComponent extends TabLimitesComponent implements OnInit {
  @ViewChild('panelGrid', { static: true })
  panelGrid: GridLimitesComponent;
  public chaves: string[] = [];

  ngOnInit() {
    if (this.dados) {
      for (const dado of Object.keys(this.dados)) {
        this.chaves.push(dado);
      }
    }
  }

  setStatusToggle(status: boolean) {
    super.setStatusToggle(status);
  }
  statusToggle(event: Event) {
    super.statusToggle(event);
  }
}
