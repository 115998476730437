import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { BaseCampoComEditComponent } from "../base-campo-com-edit/base-campo-com-edit.component";

@Component({
  selector: "app-campo-taxa-spread",
  templateUrl: "./campo-taxa-spread.component.html",
  styleUrls: ["../base-campo-com-edit/base-campo-com-edit.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: BaseCampoComEditComponent,
      useExisting: CampoTaxaSpreadComponent,
    },
  ],
})
export class CampoTaxaSpreadComponent
  extends BaseCampoComEditComponent
  implements OnInit {
  ngOnInit() {
    this.nome = "Taxa ou Spread";
  }
}
