import { classeOperacao, ModalidadeOperacao, TiposOpers, tiposProdutosMITRA } from '../utils';
import { NomeId } from './NomeId.interface';
import { iTipoProduto } from './tiposProdutos.interface';

export class Oper {
  public data_entrada: any;
  public produto: NomeId;
  public contrato: NomeId;
  public corretora: NomeId;
  public fundo: any;
  public contraparte: NomeId;
  public quantidade: number;
  public pu: number;
  public premio: number;
  public inicio: any;
  public taxa: number;
  public taxaSpread: number;
  public porcIndex: number;
  public tipoProduto: tiposProdutosMITRA;
  public emissao: any;
  public vencimento: any;
  public volatilidade: number;
  public classeOperacao: classeOperacao;
  public modalidade: ModalidadeOperacao;
  public valor_emissao: number;
  public emissor: NomeId;
  public check: boolean;
  constructor() {
    this.reset();
  }
  public reset(tipoOper: iTipoProduto = undefined) {
    this.data_entrada = Date.now();
    this.produto = {
      id: 0,
      nome: '',
    };
    this.contrato = {
      id: 0,
      nome: '',
    };
    this.corretora = {
      id: 0,
      nome: '',
    };
    this.contraparte = {
      id: 0,
      nome: '',
    };
    this.quantidade = 1;
    this.pu = null;
    this.premio = null;
    this.inicio = null;
    this.taxa = null;
    if (tipoOper === undefined) {
      this.tipoProduto = tiposProdutosMITRA.Stocks;
      this.modalidade = ModalidadeOperacao.Normal;
      this.classeOperacao = classeOperacao.Compra;
    } else {
      if (tipoOper.tipoEntrada == TiposOpers.Fundo) {
        this.classeOperacao = classeOperacao.Aplicação;
      } else if (tipoOper.tipoEntrada == TiposOpers.Emprestimo) {
        this.classeOperacao = classeOperacao.Doado;
      }
      this.tipoProduto = tipoOper.id;
      this.modalidade = tipoOper.tipoModalidade;
    }
    this.emissao = null;
    this.vencimento = null;
    this.volatilidade = null;
    this.valor_emissao = null;
    this.emissor = {
      id: 0,
      nome: '',
    };
    this.check = false;
  }
  private getPU(dados: object) {
    let pu: number;
    if (dados['pu'] != null) {
      if (typeof dados['pu'] === 'string') {
        pu = parseFloat(dados['pu'].replace(',', '.'));
      } else {
        pu = dados['pu'];
      }
    } else {
      pu = null;
    }
    return pu;
  }
  public resetAcao(dados: object) {
    this.reset();
    const pu = this.getPU(dados);
    this.data_entrada = dados['data_entrada'];
    this.quantidade = dados['data_entrada'];
    this.produto = {
      id: dados['cod_produto'],
      nome: dados['nome_produto'],
    };
    this.corretora = {
      id: dados['cod_corretora'],
      nome: dados['nome_corretora'],
    };
    this.fundo = dados['cod_fundo'];
    this.quantidade = dados['quantidade'];
    this.pu = pu;
    this.premio = pu;
    this.tipoProduto = tiposProdutosMITRA.Stocks;
    this.classeOperacao = dados['cod_classe_operacao'];
    this.check = false;
    return { Produto: this.produto.nome, Corretora: this.corretora.nome };
  }

  public resetOpcao(dados: object) {
    this.reset();
    const pu = this.getPU(dados);
    this.data_entrada = dados['data_entrada'];
    this.produto = {
      id: dados['cod_produto'],
      nome: dados['nome_produto'],
    };
    this.corretora = {
      id: dados['cod_corretora'],
      nome: dados['nome_corretora'],
    };
    this.fundo = dados['cod_fundo'];
    this.quantidade = dados['quantidade'];
    this.pu = pu;
    this.premio = pu;
    this.inicio = dados['inicio'];
    this.tipoProduto = tiposProdutosMITRA.Stocks;
    this.volatilidade = dados['vol'];
    this.classeOperacao = dados['cod_classe_operacao'];
    return { Produto: this.produto.nome, Corretora: this.corretora.nome };
  }
  public resetBTC(dados: object) {
    this.reset();
    const pu = this.getPU(dados);
    this.data_entrada = dados['data_entrada'];
    this.produto = {
      id: dados['cod_produto'],
      nome: dados['nome_produto'],
    };
    this.corretora = {
      id: dados['cod_corretora'],
      nome: dados['nome_corretora'],
    };

    this.fundo = dados['cod_fundo'];
    this.contraparte = {
      id: dados['cod_contraparte'],
      nome: dados['nome_contraparte'],
    };
    this.quantidade = dados['quantidade'];
    this.pu = pu;
    this.premio = pu;
    this.inicio = dados['inicio'];
    this.taxa = dados['taxa'];
    this.tipoProduto = tiposProdutosMITRA.Stocks;
    this.inicio = dados['inicio'];
    this.emissao = dados['inicio'];
    this.vencimento = dados['vencimento'];
    this.volatilidade = dados['vol'];
    this.classeOperacao = dados['cod_classe_operacao'];
    return { Produto: this.produto.nome, Corretora: this.corretora.nome, Contraparte: this.contraparte.nome };
  }

  resetTitulos(dados: object) {
    this.reset();
    const pu = this.getPU(dados);
    this.data_entrada = dados['data_entrada'];
    this.contrato = {
      id: dados['cod_contrato'],
      nome: dados['nome_contrato'],
    };
    this.corretora = {
      id: dados['cod_corretora'],
      nome: dados['nome_corretora'],
    };

    this.fundo = dados['cod_fundo'];
    this.contraparte = {
      id: dados['cod_contraparte'],
      nome: dados['nome_contraparte'],
    };
    this.quantidade = dados['quantidade'];
    this.pu = pu;
    this.taxa = dados['taxa'];
    this.tipoProduto = tiposProdutosMITRA.RF;
    this.classeOperacao = dados['cod_classe_operacao'];
    return { Contrato: this.contrato.nome, Corretora: this.corretora.nome, Contraparte: this.contraparte.nome };
  }
  resetFuturos(dados: object) {
    this.reset();
    const pu = this.getPU(dados);
    this.data_entrada = dados['data_entrada'];
    this.produto = {
      id: dados['cod_produto'],
      nome: dados['nome_produto'],
    };
    this.corretora = {
      id: dados['cod_corretora'],
      nome: dados['nome_corretora'],
    };

    this.fundo = dados['cod_fundo'];
    this.quantidade = dados['quantidade'];

    this.pu = pu;
    this.taxa = dados['taxa'];
    this.tipoProduto = tiposProdutosMITRA.Futuros;
    this.classeOperacao = dados['cod_classe_operacao'];
    return { Produto: this.produto.nome, Corretora: this.corretora.nome };
  }
  resetCotasFundos(dados: object) {
    this.reset();
    const pu = this.getPU(dados);
    this.data_entrada = dados['data_entrada'];
    this.produto = {
      id: dados['cod_produto'],
      nome: dados['nome_produto'],
    };
    this.corretora = {
      id: dados['cod_corretora'],
      nome: dados['nome_corretora'],
    };
    this.pu = pu;
    this.fundo = dados['cod_fundo'];
    this.quantidade = dados['quantidade'];

    this.tipoProduto = tiposProdutosMITRA.Fundos;
    this.classeOperacao = dados['cod_classe_operacao'];
    return { Produto: this.produto.nome, Corretora: this.corretora.nome };
  }
  resetCompromissadaCdb(dados: object) {
    this.reset();
    const pu = this.getPU(dados);
    let valorEmissao;

    if (dados['valor_emissao'] != null) {
      valorEmissao = dados['valor_emissao'].replace(',', '.');
    } else {
      valorEmissao = null;
    }
    this.data_entrada = dados['data_entrada'];
    this.produto = {
      id: dados['cod_produto'],
      nome: dados['nome_produto'],
    };
    this.corretora = {
      id: dados['cod_corretora'],
      nome: dados['nome_corretora'],
    };

    this.fundo = dados['cod_fundo'];
    this.contraparte = {
      id: dados['cod_contraparte'],
      nome: dados['nome_contraparte'],
    };
    this.pu = pu;
    this.quantidade = dados['quantidade'];
    this.taxa = dados['taxa'];
    this.tipoProduto = tiposProdutosMITRA.RF;
    this.emissao = dados['data_emissao'];
    this.vencimento = dados['data_vencimento'];
    this.classeOperacao = dados['cod_classe_operacao'];
    this.porcIndex = dados['porc_index'];
    this.taxaSpread = dados['taxa_spread'];
    this.valor_emissao = valorEmissao;
    this.emissor = {
      id: dados['cod_emissor'],
      nome: dados['nome_emissor'],
    };
    return { Produto: this.produto.nome, Corretora: this.corretora.nome, Contraparte: this.contraparte.nome };
  }
}
