import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class RenderBoletasService {
  private DadosBoleta = new BehaviorSubject<object>({});
  getDadosBoleta = this.DadosBoleta.asObservable();

  setDadosBoleta(cadastro_xml_id: number) {
    if (cadastro_xml_id != undefined) {
      this.httpService.getOperacoes(cadastro_xml_id).subscribe((msg) => {
        this.DadosBoleta.next(msg);
      });
    }
  }

  constructor(private httpService: HttpService) {}
}
