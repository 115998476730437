import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseCampoComEditComponent } from '../base-campo-com-edit/base-campo-com-edit.component';

@Component({
  selector: 'app-campo-financeiro',
  templateUrl: './campo-financeiro.component.html',
  styleUrls: ['../base-campo-com-edit/base-campo-com-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: BaseCampoComEditComponent,
      useExisting: CampoFinanceiroComponent,
    },
  ],
})
export class CampoFinanceiroComponent extends BaseCampoComEditComponent implements OnInit {
  ngOnInit() {
    this.formControl = new FormControl();
    this.nome = 'Financeiro:';
  }
}
