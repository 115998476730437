import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { tiposProdutosMITRA } from 'src/app/utils';
import { BaseCampoComEditComponent } from '../base-campo-com-edit/base-campo-com-edit.component';

@Component({
  selector: 'app-campo-taxa-oper',
  templateUrl: './campo-taxa-oper.component.html',
  styleUrls: ['../base-campo-com-edit/base-campo-com-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BaseCampoComEditComponent, useExisting: CampoTaxaOperComponent }],
})
export class CampoTaxaOperComponent extends BaseCampoComEditComponent implements OnInit {
  ngOnInit() {
    this.nome = 'Taxa da Operação';
  }
  onChangeTaxa(event) {
    if (this.formData.tipoOper.id == tiposProdutosMITRA.RF && event.target.value != '' && this.model.contrato.id != 0) {
      const data = new Intl.DateTimeFormat('PT-br', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).format();
      const taxa = this.model.taxa;
      const contrato = this.model.contrato.nome;

      this.http.getPU(data, taxa, contrato).subscribe((res) => {
        if ('PU Financeiro' in res) {
          this.formData.puDisable = true;
          this.model.pu = res['PU Financeiro'];
          this.formData.updateCampos();
        }
      });
    } else {
      if (this.formData.puDisable) {
        this.model.pu = null;
        this.formData.puDisable = false;
      }
      this.formData.updateCampos();
    }
  }
}
