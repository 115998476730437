import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsDatepickerViewMode, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { HighchartsService } from '../../services/highcharts/highcharts.service';

@Component({
  selector: 'app-consumo',
  templateUrl: './consumo.component.html',
  styleUrls: ['./consumo.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
})
export class ConsumoComponent implements OnInit {
  panelOpenState = true;
  panelOpenState2 = true;
  panelOpenState3 = true;

  @ViewChild('campoData', { static: false }) campoData: ElementRef;

  minDate: Date;
  maxDate: Date;
  data: string;
  name = 'Angular';
  modelDate = '';
  myGroup: any;
  bsValue = new Date();

  minMode: BsDatepickerViewMode = 'month';
  bsConfig: Partial<BsDatepickerConfig>;
  selected: any;
  total: string;
  modeloInicial: string = 'TimeWEB';
  valorData: string;
  public geral: any;
  public current_dates: any[];

  permissao: boolean;
  ngAfterViewChecked() {
    const mesesNomesCompletos = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    for (const num of numbers) {
      if (this.geral == ptBrLocale.months[num]) {
        this.campoData.nativeElement.value = mesesNomesCompletos[num] + ' ' + this.bsValue.getFullYear();
      } else {
      }
    }
  }

  ngAfterViewInit() {
    const mesesNomesCompletos = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    for (const num of numbers) {
      if (this.campoData.nativeElement.value == ptBrLocale.months[num]) {
        this.campoData.nativeElement.value = mesesNomesCompletos[num];
      } else {
      }
    }
  }

  datas(dataCompleta) {
    // DATEPICKER
    this.geral = ptBrLocale.months[dataCompleta.getMonth()];
    if (this.selected == undefined) {
      // enviando somente a data
      this.enviarDataeInstituicao('?data=' + (dataCompleta.getMonth() + 1) + '-' + dataCompleta.getFullYear());
    } else {
      // enviando data e instituicao
      this.enviarDataeInstituicao(
        '?instituicao=' + this.selected + '&data=' + (dataCompleta.getMonth() + 1) + '-' + dataCompleta.getFullYear(),
      );
    }
  }

  onOptionsSelected(event): void {
    // COMBOBOX
    const value = event.target.value;
    this.selected = value;
    this.total = this.selected;
    this.enviarDataeInstituicaoComboBox(
      '?instituicao=' + this.selected + '&data=' + (this.bsValue.getMonth() + 1) + '-' + this.bsValue.getFullYear(),
    );
  }

  toggled: boolean;
  @ViewChild('fundosPorDia', { static: true }) public fundosPorDia: ElementRef;
  @ViewChild('calculosPorFundo', { static: true })
  public calculosPorFundo: ElementRef;
  router: any;
  dias_utilizados = 0;
  calculos_cobrados = 0;
  instituicao: string;
  cobranca: any = {};
  instituicoes: string;
  todasAsDatas = [];
  todasAsDatas2 = [];

  constructor(
    private highcharts: HighchartsService,
    private internalData: InternalDataService,
    private http: HttpService,
    public localeService: BsLocaleService,
  ) {
    ptBrLocale.months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    this.localeService.use('pt-br');
    defineLocale('pt-br', ptBrLocale);
    this.internalData.getSidebarToggle().subscribe((val) => {
      this.toggled = val;
    });

    this.http.getUser().subscribe((res) => {
      if (res['permissao'] == 'Luz') {
        this.permissao = true;
      } else {
        this.permissao = false;
      }
    });
  }

  ngOnInit(): void {
    this.bsConfig = Object.assign(
      {},
      {
        dateInputFormat: 'MMMM',
        selectDay: false,
        showWeekNumbers: false,
        minMode: this.minMode,
      },
    );
    this.myGroup = new FormGroup({
      dateFull: new FormControl(),
    });

    this.valorData = '?data=' + (this.bsValue.getMonth() + 1) + '-' + this.bsValue.getFullYear();
    this.http.getConsumoTotal(this.valorData).subscribe((msg) => {
      this.instituicoes = msg['instituicoes'];
    });
  }

  enviarDataeInstituicao(NomeDaInstitucaoeData: string) {
    this.http.getConsumoTotal(NomeDaInstitucaoeData).subscribe((msg) => {
      const calculosPorFundo = [];
      const fundosPorDia = [];
      this.cobranca;
      this.minDate;
      this.maxDate;

      const datasPosicoes = [];
      msg['datas_relatorios'].forEach((item) => {
        datasPosicoes.push(item.Datas);
      });
      this.current_dates = datasPosicoes;
      let MenorValor;
      let MaiorValor: any;
      MenorValor = datasPosicoes.sort();
      MaiorValor = MenorValor.slice(-1);

      this.minDate = new Date(MenorValor[0]);
      this.maxDate = new Date(MaiorValor); // e maior range do date picker

      if (Object.keys(msg['estruturas_mes']).length != 0) {
        msg['estruturas_mes'].forEach((item) => {
          calculosPorFundo.push({
            name: item.nome_fundo,
            y: item.count_estrutura,
          });
        });
      }

      if (Object.keys(msg['dias']).length != 0) {
        msg['dias'].forEach((item) => {
          fundosPorDia.push({
            name: item.dia,
            y: item.valor,
            color: '#132f43',
          });
        });
        fundosPorDia.push({
          name: 'Total de Fundos',
          y: msg['total_dias'],
          color: '#c85628',
        });
      }

      this.instituicao = msg['instituicao_destino_nome'];
      this.calculos_cobrados = msg['calculos_cobrados'];
      this.dias_utilizados = msg['dias_utilizados'];
      this.highcharts.createCalculosPorFundo(this.calculosPorFundo.nativeElement, calculosPorFundo);
      this.highcharts.createFundosPorDia(this.fundosPorDia.nativeElement, fundosPorDia);
    });
  }

  enviarDataeInstituicaoComboBox(NomeDaInstitucaoeData: string) {
    this.http.getConsumoTotal(NomeDaInstitucaoeData).subscribe((msg) => {
      const calculosPorFundo = [];
      const fundosPorDia = [];
      this.cobranca;
      this.minDate;
      this.maxDate;

      const datasPosicoes2 = [];
      msg['datas_relatorios'].forEach((item) => {
        datasPosicoes2.push(item.Datas);
      });
      this.current_dates = datasPosicoes2;

      let MenorValorComboBox;
      let MaiorValorComboBox: any;
      MenorValorComboBox = datasPosicoes2.sort();
      MaiorValorComboBox = MenorValorComboBox.slice(-1);

      this.minDate = new Date(MenorValorComboBox[0]);
      this.maxDate = new Date(MaiorValorComboBox); // e maior range do date picker

      // fitlragem datapicker travar na data atual se dados não vierem.
      if (datasPosicoes2.length == 0) {
        this.minDate = new Date();
        this.maxDate = new Date();
      }

      if (Object.keys(msg['estruturas_mes']).length != 0) {
        msg['estruturas_mes'].forEach((item) => {
          calculosPorFundo.push({
            name: item.nome_fundo,
            y: item.count_estrutura,
          });
        });
      }

      if (Object.keys(msg['dias']).length != 0) {
        msg['dias'].forEach((item) => {
          fundosPorDia.push({
            name: item.dia,
            y: item.valor,
            color: '#132f43',
          });
        });
        fundosPorDia.push({
          name: 'Total de Fundos',
          y: msg['total_dias'],
          color: '#c85628',
        });
      }

      this.instituicao = msg['instituicao_destino_nome'];
      this.calculos_cobrados = msg['calculos_cobrados'];
      this.dias_utilizados = msg['dias_utilizados'];
      this.highcharts.createCalculosPorFundo(this.calculosPorFundo.nativeElement, calculosPorFundo);
      this.highcharts.createFundosPorDia(this.fundosPorDia.nativeElement, fundosPorDia);
    });
  }
}
