import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iTipoProduto } from '../../../../models/tiposProdutos.interface';
import { BaseCampoComEditComponent } from '../base-campo-com-edit/base-campo-com-edit.component';

@Component({
  selector: 'app-campo-tipo-produto',
  templateUrl: './campo-tipo-produto.component.html',
  styleUrls: ['../base-campo-com-edit/base-campo-com-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: BaseCampoComEditComponent,
      useExisting: CampoTipoProdutoComponent,
    },
  ],
})
export class CampoTipoProdutoComponent extends BaseCampoComEditComponent implements OnInit {
  @Input()
  public edit: boolean;
  @Input()
  public tiposOper: iTipoProduto[];
  @Output() mudouTipo = new EventEmitter<iTipoProduto>();

  ngOnInit() {
    this.nome = 'Tipo do Produto';
  }
  mudouTipoEntrada($event) {
    this.mudouTipo.emit($event);
  }
}
