import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { iFundoSelecionado } from 'src/app/models/fundo-selecionado.interface';

@Injectable({
  providedIn: 'root',
})
export class InternalDataService {
  private sidebarToggle = new BehaviorSubject(true);

  getSidebarToggle() {
    return this.sidebarToggle.asObservable();
  }
  /* private srcSidebarToggle = new BehaviorSubject(true);
  // eslint-disable-next-line no-invalid-this
  private _sidebarToggle: Observable<boolean> = this.srcSidebarToggle.asObservable();

  get sidebarToggle() {
    return this._sidebarToggle;
  }

  set sidebarToggle(value: boolean) {
    this.srcSidebarToggle.next(value);
  }*/

  private gestaoAberto = new BehaviorSubject(false);
  getGestaoAberto = this.gestaoAberto.asObservable();

  private relatorioAberto = new BehaviorSubject(false);
  getRelatorioAberto = this.relatorioAberto.asObservable();

  private boletasAberto = new BehaviorSubject(false);

  getBoletasAberto() {
    return this.boletasAberto.asObservable();
  }

  private fundoSelecionado = new BehaviorSubject<iFundoSelecionado>({
    id: '',
    nome: '',
    possuiLog: false,
    cadastro_xml_id: -1,
  });

  getFundoSelecionado() {
    return this.fundoSelecionado.asObservable();
  }

  private relatorioCarregado = new BehaviorSubject<boolean>(false);
  getRelatorioCarregado = this.relatorioCarregado.asObservable();

  private selectedLine = new BehaviorSubject<any>('');
  getSelectedLine = this.selectedLine.asObservable();

  private operaBoleta = new BehaviorSubject<object>({});
  getOperaBoleta = this.operaBoleta.asObservable();

  private paramsGridBoleta = new BehaviorSubject<object>({});
  getParamsGridBoleta = this.paramsGridBoleta.asObservable();

  private tipoInstituicao = new BehaviorSubject<string>('');
  getTipoInstituicao = this.tipoInstituicao.asObservable();

  private novoXmlImportado = new BehaviorSubject(false);
  getNovoXmlImportado = this.novoXmlImportado.asObservable();

  private sessionToken = new BehaviorSubject<string>('');
  getSessionToken = this.sessionToken.asObservable();

  private dataCalculo = new BehaviorSubject<Date>(new Date());

  getDataCalculo() {
    return this.dataCalculo.asObservable();
  }

  constructor() {}

  setDataCalculo(value: Date) {
    this.dataCalculo.next(value);
  }

  setSidebarToggle(value: boolean) {
    this.sidebarToggle.next(value);
  }

  setGestaoAberto(value: boolean) {
    this.gestaoAberto.next(value);
  }

  setRelatorioAberto(value: boolean) {
    this.relatorioAberto.next(value);
  }

  setBoletasAberto(value: boolean) {
    this.boletasAberto.next(value);
  }

  setFundoSelecionado(value: iFundoSelecionado) {
    this.fundoSelecionado.next(value);
  }

  setSelectedLine(value: any) {
    this.selectedLine.next(value);
  }

  setRelatorioCarregado(value: boolean) {
    this.relatorioCarregado.next(value);
  }

  setOperaBoleta(value: {}) {
    this.operaBoleta.next(value);
  }

  setParamsGridBoleta(value: {}) {
    this.paramsGridBoleta.next(value);
  }

  setTipoInstituicao(value: string) {
    this.tipoInstituicao.next(value);
  }

  setNovoXmlImportado(value: boolean) {
    this.novoXmlImportado.next(value);
  }

  setSessionToken(value: string) {
    this.sessionToken.next(value);
  }
}
