import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Oper } from "src/app/models/oper";
import { tiposProdutosMITRA } from "src/app/utils";
import { BaseCampoComEditComponent } from "../base-campo-com-edit/base-campo-com-edit.component";

@Component({
  selector: "app-campo-pu",
  templateUrl: "./campo-pu.component.html",
  styleUrls: ["../base-campo-com-edit/base-campo-com-edit.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: BaseCampoComEditComponent, useExisting: CampoPuComponent },
  ],
})
export class CampoPuComponent
  extends BaseCampoComEditComponent
  implements OnInit {
  ngOnInit() {
    this.nome = "PU";
  }
  onChangePU(event: { target: { value: string } }) {
    let model = this.model as Oper;
    if (
      this.formData.tipoOper.id == tiposProdutosMITRA.RF &&
      event.target.value != "" &&
      this.model.contrato.id != 0
    ) {
      let data = new Intl.DateTimeFormat("PT-br", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format();
      let pu = model.pu;
      let contrato = model.contrato.nome;

      this.http.getTaxa(data, pu, contrato).subscribe((res) => {
        if ("Taxa" in res) {
          this.formData.taxaDisable = true;
          model.taxa = res["Taxa"];
          this.formData.updateCampos();
        }
      });
    } else {
      if (this.formData.taxaDisable) {
        model.taxa = null;
        this.formData.taxaDisable = false;
      }
      this.formData.updateCampos();
    }
  }
}
