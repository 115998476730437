import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { iFundoSelecionado } from 'src/app/models/fundo-selecionado.interface';
import { BaseCampoComEditComponent } from '../base-campo-com-edit/base-campo-com-edit.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-campo-fundo',
  templateUrl: './campo-fundo.component.html',
  styleUrls: ['../base-campo-com-edit/base-campo-com-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BaseCampoComEditComponent, useExisting: CampoFundoComponent }],
})
export class CampoFundoComponent extends BaseCampoComEditComponent implements OnInit {
  public campoPreenchido: any; 
  public fundoDefault: any;
  @Input()
  listaFundos: iFundoSelecionado[];
  @Input()
  fundoSelecionado: iFundoSelecionado;

  ngOnInit() {
    this.campoPreenchido =  { fundo: false }
    this.formControl = new FormControl();
    this.nome = 'Fundo';
    this.model.fundo = this.fundoSelecionado.cadastro_xml_id;
    this.fundoDefault = this.listaFundos.find(
      (fundo) => fundo['cadastro_xml_id'] === this.fundoSelecionado['cadastro_xml_id'],
    );
  }
}
