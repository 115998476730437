import { Component, OnInit } from '@angular/core';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.less'],
})
export class CardComponent implements OnInit {
  nomeFundo: string;

  constructor(private internal: InternalDataService) {
    this.internal.getFundoSelecionado().subscribe((msg) => (this.nomeFundo = msg.nome));
  }

  ngOnInit() {}
}
