import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { classeOperacao, TiposOpers } from "src/app/utils";
import { BaseCampoComEditComponent } from "../base-campo-com-edit/base-campo-com-edit.component";
import { iTipoOper } from "./../../../../models/tipoOper.interface";

@Component({
  selector: "app-campo-tipo-oper",
  templateUrl: "./campo-tipo-oper.component.html",
  styleUrls: ["../base-campo-com-edit/base-campo-com-edit.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: BaseCampoComEditComponent, useExisting: CampoTipoOperComponent },
  ],
})
export class CampoTipoOperComponent
  extends BaseCampoComEditComponent
  implements OnInit {
  public classesOper: iTipoOper[]
  public nome_default;
  ngOnInit() {
    this.classesOper = [
      {
        nome: "Compra",
        id: classeOperacao.Compra,
      },
      {
        nome: "Venda",
        id: classeOperacao.Venda,
      },
    ];
    this.nome = "Tipo da Operação";
    this.getTipos();
    this.model.classeOperacao = this.classesOper[0].id;
  }
  private getTipos() {
    if (this.tipoOper.tipoEntrada === TiposOpers.Fundo) {
      this.classesOper = [
        {
          nome: "Aplicação",
          id: classeOperacao.Aplicação,
        },
        {
          nome: "Resgate Total",
          id: classeOperacao.ResgateTotal,
        },
        {
          nome: "Resgate Parcial",
          id: classeOperacao.Resgate,
        },
      ];
    } else if (this.tipoOper.tipoEntrada == TiposOpers.Emprestimo) {
      this.model.inicio = Date.now();

      this.model.classeOperacao = classeOperacao.Doado;
      this.classesOper = [
        {
          nome: "Doado",
          id: classeOperacao.Doado,
        },
        {
          nome: "Tomado",
          id: classeOperacao.Tomado,
        },
      ];
    } else {
      this.classesOper = [
        {
          nome: "Compra",
          id: classeOperacao.Compra,
        },
        {
          nome: "Venda",
          id: classeOperacao.Venda,
        },
      ];
    }
  }
}
