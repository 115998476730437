import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as ExcelProper from 'exceljs';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as fs from 'file-saver';
import { switchMap } from 'rxjs/operators';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { SharedUtilsService } from 'src/app/services/shared-utils/shared-utils.service';
import { UserWebsocketService } from 'src/app/services/websocket/user-websocket/user-websocket.service';
import { HttpService } from '../../services/http/http.service';
import { DetalhamentoLimitesComponent } from '../detalhamento-limites/detalhamento-limites.component';
import { TabLimitesComponent } from '../tab-limites/tab-limites.component';

export interface IObjetoRegra {
  tipo: string;
  temProduto: boolean;
}

export interface ILimites {
  descricao: string;
  produto: string;
  valor_referencia: number;
  valor_limite: number;
  valor_utilizado: number;
  valor_diferenca: number;
}

export interface IRegras {
  4994: ILimites[];
  555: ILimites[];
  175: ILimites[];
}

export interface IDetalhamentoLimite {
  desenquadrado: IRegras;
  enquadrado: IRegras;
}

export interface IEstrutura {
  nome_fundo: string;
  data_posicao: string;
  patrimonio_liquido: string;
  enviado: boolean;
}

export interface IConsultaDetalhamentoLimites {
  estrutura_carregada: IEstrutura;
  detalhamento_limite: IDetalhamentoLimite;
}

@Component({
  selector: 'app-limites',
  templateUrl: './limites.component.html',
  styleUrls: ['./limites.component.less'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          height: '*',
          width: '100%',
        }),
      ),
      state(
        'out',
        style({
          height: '19px',
          width: '100%',
        }),
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class LimitesComponent implements OnInit {
  public enquadradoMenuOpen: string = 'in';
  public desenquadradoMenuOpen: string = 'in';
  @ViewChild('panelDesenquadrado', { static: true })
  panelDesenquadrado: TabLimitesComponent;
  @ViewChild('panelEnquadrado', { static: true })
  panelEnquadrado: TabLimitesComponent;
  @ViewChild('panelDetalhamentoEnquadrado', { static: true })
  panelDetalhamentoEnquadrado: DetalhamentoLimitesComponent;
  @ViewChild('panelDetalhamentoDesenquadrado', { static: true })
  panelDetalhamentoDesenquadrado: DetalhamentoLimitesComponent;

  public uuid: string;
  public tipo: string;
  public regra: string;
  public detalhamentoConcluido = false;
  public paginaDetalhamento = false;
  public detalhamentoLimite: IConsultaDetalhamentoLimites[];

  enquadrado: any[];
  desenquadrado: any[] = [];
  contadorDesenquadrado: any;
  contadorEnquadrado: any;
  estrutura: any;
  toggled: boolean;

  public objetoDesenquadrado: IObjetoRegra = {
    tipo: 'Desenquadrado',
    temProduto: false,
  };

  public objetoEnquadrado: IObjetoRegra = {
    tipo: 'Enquadrado',
    temProduto: false,
  };

  public objetoDetalhamento: IObjetoRegra = {
    tipo: 'Detalhamento',
    temProduto: true,
  };

  constructor(
    private userWebsocket: UserWebsocketService,
    private internalData: InternalDataService,
    private http: HttpService,
    public route: ActivatedRoute,
    public sharedUtils: SharedUtilsService,
    public router: Router,
  ) {
    this.internalData.getSidebarToggle().subscribe((val) => {
      this.toggled = val;
    });

    this.userWebsocket.getUserWebsocket().subscribe((msg) => {
      try {
        if (msg['detalhamento_concluido']) this.detalhamentoConcluido = true;
        if (this.detalhamentoConcluido) {
          this.http.consultaDetalhamentoLimite(this.uuid).subscribe((res: IConsultaDetalhamentoLimites[]) => {
            this.detalhamentoLimite = res['detalhamento_limite'];
          });
        }
      } catch (error) {
        console.error('error no webscket:', error);
      }
    });
  }

  ngOnInit() {
    this.internalData.getFundoSelecionado().subscribe((fundo) => {
      this.uuid = fundo.cadastro_xml_id == -1 ? localStorage.getItem('uuid') : fundo.id;
      localStorage.setItem('uuid', this.uuid);
      this.criaLimites(this.uuid);
      this.http.verificaConclusaoDetalhamento(this.uuid).subscribe((res) => {
        this.detalhamentoConcluido = res['concluido'];

        if (this.detalhamentoConcluido) {
          this.http.consultaDetalhamentoLimite(this.uuid).subscribe((res: IConsultaDetalhamentoLimites[]) => {
            this.detalhamentoLimite = res['detalhamento_limite'];
          });
        }
      });
    });
  }

  toggleDetalhamento(event: Event) {
    this.paginaDetalhamento = !this.paginaDetalhamento;
  }

  conversao(valor, chave) {
    if (chave.includes('valor')) {
      return parseFloat(valor);
    } else {
      return valor;
    }
  }

  preencheWorkbook(status, regra, workbook, header) {
    const nomeSheet = regra + ' ' + status;
    const worksheet = workbook.addWorksheet(nomeSheet);
    worksheet.addRow(header);
    for (const descricao of this.detalhamentoLimite[status][regra]) {
      const chavesDetalhamento = Object.keys(descricao);
      const temp = [];
      for (const chave of chavesDetalhamento) {
        temp.push(this.conversao(descricao[chave], chave));
      }
      worksheet.addRow(temp);
    }
  }

  downloadExcel() {
    const workbook: ExcelProper.Workbook = new Excel.Workbook();

    const header = [
      'Descrição',
      'Produto',
      'Valor Referência',
      'Valor Limite',
      'Valor Utilizado',
      'Valor Diferença',
      'Status',
    ];

    this.preencheWorkbook('desenquadrado', '555', workbook, header);
    this.preencheWorkbook('desenquadrado', '175', workbook, header);
    this.preencheWorkbook('desenquadrado', '4994', workbook, header);

    this.preencheWorkbook('enquadrado', '555', workbook, header);
    this.preencheWorkbook('enquadrado', '175', workbook, header);
    this.preencheWorkbook('enquadrado', '4994', workbook, header);

    const fname = 'Detalhamento limites';
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const dataAtual = new Date();

      fs.saveAs(blob, `${fname}-${dataAtual.getDate()}-${dataAtual.getMonth() + 1}-${dataAtual.getFullYear()}.xlsx`);
    });
  }

  goToHome() {
    this.router.navigate(['']);
  }

  criaLimites(entrada = null) {
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.tipo = params.get('tipo');
          this.regra = params.get('regra');
          return this.http.getLimitesDetalhes(entrada ? entrada : this.uuid);
        }),
      )
      .subscribe((res) => {
        this.enquadrado = res['limites_detalhes']['enquadrado'];
        this.desenquadrado = res['limites_detalhes']['desenquadrado'];
        this.estrutura = res['estrutura_carregada']['nome_fundo'];
      });
  }
}
