import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tiposProdutosMITRA } from 'src/app/utils';
import { environment } from 'src/environments/environment';
import { InternalDataService } from '../internal-data/internal-data.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient, private internal: InternalDataService) {}

  postOper(oper: any) {
    return this.http.post(`${environment.django}/front/cadastro_operacao`, oper);
  }

  getVerificarRedis() {
    return this.http.get<Object[]>(`${environment.django}/front/verificar_produtos_redis`);
  }

  getProdutos(tipo: tiposProdutosMITRA | number | null, filter: string = '', prefixo: string) {
    let getString: string;
    if (tipo === null) {
      getString = `${environment.django}/front/produtos_rest?busca=${filter}&prefixo=${prefixo}`;
    } else {
      getString = `${environment.django}/front/produtos_rest?busca=${tipo}:${filter}&prefixo=${prefixo}`;
    }
    return this.http.get<Object[]>(getString);
  }

  getFundos(filter: string = '') {
    return this.http.get<Object[]>(`${environment.django}/front/produtos_rest?busca=${filter}&prefixo=fundo`);
  }

  getLimitesDetalhes(parametro_id: string = '') {
    return this.http.get<Object[]>(`${environment.django}/calculos/limites_detalhes_rest/${parametro_id}`);
  }

  consultaDetalhamentoLimite(uuid: string) {
    return this.http.get<Object[]>(`${environment.django}/calculos/consulta_detalhamento_limite?calculo_id=${uuid}`);
  }

  verificaConclusaoDetalhamento(uuid: string) {
    return this.http.get<Object[]>(`${environment.django}/calculos/detalhamento_limites?uuid=${uuid}`);
  }

  getEstruturas() {
    return this.http.get<Object[]>(`${environment.django}/calculos/ultimas_estruturas_carregadas_rest`);
  }

  getAnalitico(cadastro_xml_id: number) {
    return this.http.get<Object[]>(`${environment.django}/front/analitico?cod_fundo=${cadastro_xml_id}`);
  }

  getOperacoes(cadastro_xml_id: number) {
    return this.http.get<Object[]>(`${environment.django}/front/operacoes?busca=${cadastro_xml_id}`);
  }

  getBoleta(codOper: number, operacao: string) {
    return this.http.get<Object>(`${environment.django}/front/operacao_rest?cod_oper=${codOper}&operacao=${operacao}`);
  }

  getNumeroParametros() {
    return this.http.get<Object>(`${environment.django}/calculos/numero_parametros_rest`);
  }

  postAuth(payload: any) {
    return this.http.post<string>(`${environment.django}/auth`, payload);
  }

  verificaCalculoDataXML(payload: any) {
    return this.http.post(`${environment.django}/calculos/verifica_data_xml`, payload);
  }

  verificaXml(payload: any) {
    return this.http.post(`${environment.django}/calculos/verifica_xml`, payload);
  }

  validaXml(payload:any){
    return this.http.post(`${environment.django}/calculos/valida_xml`, payload);
  }

  importaXml(payload: any) {
    return this.http.post(`${environment.django}/calculos/importa_xml`, payload);
  }

  postRentabilidade(payload: any) {
    return this.http.post(`${environment.django}/relatorios/salva_rentabilidade`, payload, { observe: 'response' });
  }

  postDiferencas(payload: any) {
    return this.http.post(`${environment.django}/calculos/diferencas_rest`, payload);
  }

  postRecalculo(payload: any) {
    return this.http.post(`${environment.django}/calculos/inicia_worker`, payload);
  }

  getConsolidado(uuid: string) {
    return this.http.get<Object>(`${environment.django}/calculos/${uuid}/consolidado_rest`);
  }

  getHash(uuid: string, data: string) {
    return this.http.get<Object>(`${environment.django}/calculos/${uuid}/parametro_data?data=${data}`);
  }

  getUser() {
    return this.http.get<Object>(`${environment.django}/ver_perfil_rest`);
  }

  getUserInfo() {
    return this.http.get<Object>(`${environment.django}/info_usuario_rest`);
  }

  getConsumoTotal(dados: string) {
    return this.http.get<Object>(`${environment.django}/clientes/relatorio_consumo_rest/${dados}`); // CONSUMO
  }

  postIniciaConsumidor() {
    return this.http.post(`${environment.django}/front/inicia_consumidor`, {});
  }

  getIsAuth() {
    return this.http.get<Object>(`${environment.django}/front/is_auth`);
  }

  getCalculos(tipo: string) {
    return this.http.get<Object>(`${environment.django}/calculos/calculos_tipos_rest?status=${tipo}`);
  }

  getErros() {
    return this.http.get<Object[]>(`${environment.django}/calculos/erro_rest`);
  }

  getErrosCalculo(calculo_id) {
    return this.http.get<Object[]>(`${environment.django}/calculos/erro_rest?parametro_id=${calculo_id}`);
  }

  postCancelaCalculo(uuid: string) {
    return this.http.post(`${environment.django}/calculos/cancelar_rest/${uuid}`, {});
  }

  getEnviaEmail(uuid: string) {
    return this.http.get(`${environment.django}/calculos/email_rest?parametro_id=${uuid}`);
  }

  getApagaErro(id: string) {
    return this.http.get(`${environment.django}/calculos/email_rest?id=${id}`);
  }

  postAlteraSenha(payload) {
    return this.http.post(`${environment.django}/mudar_senha_rest`, payload);
  }

  postEsqueciSenha(usuario: string, email: string) {
    return this.http.post(`${environment.django}/forgot_password_rest?`, {
      usuario,
      email,
    });
  }

  getDatasRelatorio(uuid: string) {
    return this.http.get<Object[]>(`${environment.django}/calculos/${uuid}/datas_relatorios_rest`);
  }

  getFiltroUsuarioConsolidado() {
    return this.http.get(`${environment.django}/clientes/consolidado_filtro_rest`);
  }

  postFiltroUsuarioConsolidado(payload: any) {
    return this.http.post(`${environment.django}/clientes/consolidado_filtro_rest/`, payload);
  }

  postRemoverBoletas(boletas: any) {
    return this.http.post(`${environment.django}/front/remover_boletas`, boletas);
  }

  postEditarBoleta(oper: any) {
    return this.http.post(`${environment.django}/front/editar_boletas`, oper);
  }

  getCardsGestao(cadastro_xml_id: number, uuid: string) {
    return this.http.get(
      `${environment.django}/front/cards_gestao_rest?cadastro_xml_id=${cadastro_xml_id}&uuid=${uuid}`,
    );
  }

  postReprocessaConsolidado(boletas: any) {
    return this.http.post(`${environment.django}/front/importa_boletas`, boletas);
  }

  getEstruturaByUuid(uuid: string) {
    return this.http.get<Object[]>(`${environment.django}/calculos/get_estrutura_by_uuid_rest?uuid=${uuid}`);
  }

  getTaxa(data, pu, contrato) {
    return this.http.get(`${environment.django}/front/consulta_taxa_pu_rf?data=${data}&pu=${pu}&contrato=${contrato}`);
  }

  getPU(data, taxa, contrato) {
    return this.http.get(
      `${environment.django}/front/consulta_taxa_pu_rf?data=${data}&taxa=${taxa}&contrato=${contrato}`,
    );
  }

  postImportarBoletasEmLote(arquivo_xls) {
    return this.http.post(`${environment.django}/front/importar_boletas_em_lote`, arquivo_xls);
  }

  getExcelBoletaLote() {
    return this.http.get(`${environment.django}/front/baixar_excel_boletas_lote`, { responseType: 'arraybuffer' });
  }
  getExcelErrosBoletaLote() {
    return this.http.get(`${environment.django}/front/baixar_excel_erros_boletas_lote`, {
      responseType: 'arraybuffer',
    });
  }
}
