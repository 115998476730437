import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseCampoComEditComponent } from 'src/app/components/oper-form/campos/base-campo-com-edit/base-campo-com-edit.component';
import { iTipoOper } from './../../models/tipoOper.interface';

@Injectable({
  providedIn: 'root',
})
export class FormBoletasDataService {
  private _taxaDisable: boolean = false;
  private _puDisable: boolean = false;
  private _tipoOper: iTipoOper;
  private _campos: BaseCampoComEditComponent[];
  private _formGroup: FormGroup;
  public formGroups: { [key: string]: FormGroup };

  public get formGroup(): FormGroup {
    return this._formGroup;
  }

  public set formGroup(formGroup: FormGroup) {
    this._formGroup = formGroup;
  }
  public get campos(): BaseCampoComEditComponent[] {
    return this._campos;
  }

  public set campos(campos: BaseCampoComEditComponent[]) {
    this._campos = campos;
  }

  public get tipoOper(): iTipoOper {
    return this._tipoOper;
  }

  public set tipoOper(tipoOper: iTipoOper) {
    this._tipoOper = tipoOper;
  }

  public get puDisable(): boolean {
    return this._puDisable;
  }

  public set puDisable(puDisable: boolean) {
    this._puDisable = puDisable;
  }

  public get taxaDisable(): boolean {
    return this._taxaDisable;
  }

  public set taxaDisable(taxaDisable: boolean) {
    this._taxaDisable = taxaDisable;
  }

  constructor() {
    this.formGroups = {};
  }
  reset() {
    this._taxaDisable = false;
    this._puDisable = false;
    this._campos = [];
  }

  public updateCampos() {
    this._campos.forEach((campo) => {
      campo.update();
    });
  }
  public resetCampos() {
    this._campos.forEach((campo) => {
      campo.modelNomeId = {
        id: 0,
        nome: '',
      };
      campo.update();
    });
  }
}
