import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { iCamposOperForm } from 'src/app/models/campos-oper-form.interface';
import { Oper } from 'src/app/models/oper';
import { FormBoletasDataService } from 'src/app/services/form-boletas-data/form-boletas-data.service';
import { iTipoProduto } from '../../../../models/tiposProdutos.interface';
import { iFundoSelecionado } from './../../../../models/fundo-selecionado.interface';
import { BaseCampoComEditComponent } from './../../campos/base-campo-com-edit/base-campo-com-edit.component';

@Component({
  selector: 'app-form-base',
  template: '',
  styleUrls: ['./form-base.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormBaseComponent {
  @Input()
  public tiposOper: iTipoProduto[];
  @Input()
  public edit: boolean;
  @Input()
  public listaFundos: string[];
  @Input()
  public model: Oper;
  @Input()
  fundoSelecionado: iFundoSelecionado;
  @Input()
  public camposPreenchidos: iCamposOperForm;
  @Input()
  public tipoOper: iTipoProduto;
  @Output() mudouTipo = new EventEmitter<iTipoProduto>();
  constructor(private formData: FormBoletasDataService, protected chageDetector: ChangeDetectorRef) {}
  @ViewChildren(BaseCampoComEditComponent)
  campos: QueryList<BaseCampoComEditComponent>;
  @Output() rendered = new EventEmitter<iTipoProduto>();

  ngOnInit() {
    this.formData.reset();
  }
  ngAfterViewInit() {
    this.formData.campos = this.campos.toArray();
    if (Object.keys(this.formData.formGroup.controls).length === 0) {
      this.campos.forEach((element, index) => {
        this.formData.formGroup.addControl(element.nome, element.formControl);
      });
    }
    this.formData.updateCampos();
    this.rendered.emit();
  }
  mudouTipoEntrada($event) {
    this.mudouTipo.emit($event);
  }
}
