import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { iCamposOperForm } from 'src/app/models/campos-oper-form.interface';
import { Oper } from 'src/app/models/oper';
import { iTipoProduto } from 'src/app/models/tiposProdutos.interface';
import { FormBoletasDataService } from 'src/app/services/form-boletas-data/form-boletas-data.service';
import { HttpService } from 'src/app/services/http/http.service';
import { TiposOpers } from 'src/app/utils';
import { NomeId } from './../../../../models/NomeId.interface';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-base-campo-com-edit',
  templateUrl: './base-campo-com-edit.component.html',
  styleUrls: ['./base-campo-com-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseCampoComEditComponent implements OnDestroy, OnInit ,MatFormFieldControl<any> {
  private _modoOperacao: string;
  private _value: any;
  private _placeholder: string;
  private _disabled: boolean = false;
  protected prefixo: string;
  static nextId = 0;
  public focused: boolean;
  public nome: string;
  campo: FormGroup;
  formControl: FormControl;

  @HostBinding()
  id = `app-campo-contraparte-${BaseCampoComEditComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  stateChanges = new Subject<void>();
  public get modoOperacao() {
    return this._modoOperacao;
  }
  
  @Input()
  public model: Oper;
  @Input()
  public modelNomeId: NomeId;
  @Output()
  public modelNomeIdChange = new EventEmitter<NomeId>();
  @Input()
  public camposPreenchidos: iCamposOperForm;
  @Input()
  public tipoOper: iTipoProduto;

  @Input()
  public set modoOperacao(modo: string) {
    this._modoOperacao = modo;
    this.stateChanges.next();
  }

  public get value(): any {
    return this._value;
  }

  @Input()
  public set value(value: any) {
    this._value = value;
    this.stateChanges.next();
  }

  get required() {
    return this._required;
  }
  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  public get placeholder() {
    return this._placeholder;
  }
  public set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }
  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.campo.disable() : this.campo.enable();
    this.stateChanges.next();
  }
  @Input('aria-describedby') userAriaDescribedBy: string;
  public get empty(): boolean {
    return this.value != null;
  }

  public get errorState(): boolean {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }
  controlType?: string = 'editavel';
  autofilled?: boolean;
  public filtro: NomeId[] = [];

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private fb: FormBuilder,
    protected http: HttpService,
    public formData: FormBoletasDataService,
    protected ngZone: NgZone,
    protected changeDetector: ChangeDetectorRef,
  ) {
    this.formControl = new FormControl(null, Validators.required);
    try {
      this.filtroAutoComplete('');
    } catch {
      // vai dar erro
    }
  }
  ngOnInit(): void {
    this.campo = this.fb.group({ nome: '' });
    this.fm.monitor(this.elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }
  setDescribedByIds(ids: string[]) {
    const controlElement = this.elRef.nativeElement.querySelector('.app-base-campo-com-edit-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }
  public update() {
    this.filtroAutoComplete(this.formControl.value);
    this.formControl.markAsTouched();
    this.changeDetector.detectChanges();
  }
  selected(selecionado) {
    const objSelecionado = this.filtro.filter((obj) => obj.nome === selecionado);
    if (objSelecionado.length === 1) {
      this.modelNomeId = objSelecionado[0];
      this.modelNomeIdChange.emit(this.modelNomeId);
      this.formControl.setValue(objSelecionado[0].nome);
    }
  }
  filtroAutoComplete(input: string) {
    let idProduto = null;
    if (this.tipoOper !== undefined) {
      if (this.tipoOper.tipoEntrada == TiposOpers.TituloPublico) {
        input = 'PUBLICO:' + input;
      } else if (this.tipoOper.tipoEntrada == TiposOpers.TituloPrivado) {
        input = 'PRIVADO:' + input;
      } else {
        idProduto = this.tipoOper.id;
      }
    }
    this.http.getProdutos(idProduto, input, this.prefixo).subscribe((msg) => {
      this.filtro = [];
      msg.forEach((item) => {
        if (this.tipoOper !== undefined && this.tipoOper.tipoEntrada === TiposOpers.Cdb) {
          if (
            item['NOME'].includes('CDB') &&
            (item['NOME'].includes('IPCA') || item['NOME'].includes('CDI') || item['NOME'].includes('Pre'))
          ) {
            this.filtro.push({
              id: parseInt(item['COD']),
              nome: item['NOME'],
            });
          }
        } else {
          this.filtro.push({
            id: parseInt(item['COD']),
            nome: item['NOME'],
          });
        }
      });
      this.formControl.updateValueAndValidity();
      this.changeDetector.detectChanges();
    });
  }

  requireMatch(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const selection: any = control.value;
      if (this.filtro.filter((opt) => opt.nome === selection).length !== 0) {
        return null;
      }
      return { match: true };
    };
  }
  requireMatchOrNull(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const selection: any = control.value;
      const nullArr: NomeId[] = [
        { nome: null, id: null },
        { nome: '', id: null },
        { nome: undefined, id: null },
      ];
      const arr: NomeId[] = this.filtro.concat(nullArr);
      if (arr.filter((opt) => opt.nome === selection).length !== 0) {
        return null;
      }
      return { match: true };
    };
  }
  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.complete();
  }
}
