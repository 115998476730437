import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/auth/auth.service';
import { HttpService } from './services/http/http.service';
import { InternalDataService } from './services/internal-data/internal-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  public title: string;

  constructor(
    private internal: InternalDataService,
    private http: HttpService,
    private auth: AuthService,
    private cookies: CookieService,
  ) {
    this.title = 'front';
    if (this.auth.getToken() == '') {
      this.auth.setToken(this.cookies.get('sessionid'));
      this.internal.setSessionToken(this.cookies.get('sessionid'));
    }
  }

  ngOnInit() {}
}
