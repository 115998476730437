import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseCampoComEditComponent } from '../base-campo-com-edit/base-campo-com-edit.component';

@Component({
  selector: 'app-campo-corretora',
  templateUrl: './campo-corretora.component.html',
  styleUrls: ['../base-campo-com-edit/base-campo-com-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: BaseCampoComEditComponent,
      useExisting: CampoCorretoraComponent,
    },
  ],
})
export class CampoCorretoraComponent extends BaseCampoComEditComponent implements OnInit {
  ngOnInit() {
    this.nome = 'Corretora';
    this.prefixo = 'corretora';
    this.formControl = new FormControl(null, this.requireMatchOrNull());
  }
}
