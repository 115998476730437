import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { UserInfoService } from '../../services/user-info/user-info.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.less'],
})
export class PerfilComponent implements OnInit {
  public usuario: string;
  public nome: string;
  public email: string;
  public instituicao: string;
  public descricao: string;
  public formReturnMsg: any = {
    erros: [],
    success: '',
  };

  public toggled: boolean;

  changePasswordSubmit(event: Event, form: NgForm) {
    this.httpService.postAlteraSenha(form.value).subscribe((msg) => {
      this.formReturnMsg = msg;
    });
  }

  constructor(
    private internalData: InternalDataService,
    private httpService: HttpService,
    private userInfo: UserInfoService,
  ) {
    this.internalData.getSidebarToggle().subscribe((val) => {
      this.toggled = val;
    });
    this.httpService.getUserInfo().subscribe((ret) => {
      this.usuario = ret['username'];
      this.nome = ret['nome_completo'];
      this.email = ret['email'];
      this.instituicao = ret['instituicao_nome'];
      this.descricao = ret['instituicao_descricao'];
    });
  }

  ngOnInit() {}
}
