import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InternalDataService } from '../../services/internal-data/internal-data.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root',
})
export class SharedUtilsService {
  uuid: any;

  /*
    Entra com o evento, pega os irmãos baseado no event.target, que é o elemento e atribui display=none
    caso esteja visível
    @param event
    @param iterations: número de irmãos que vão ser escondidos/exibidos
   */
  collapseNextSiblings(event, iterations, attr) {
    let currentElement = event.target.parentNode.parentNode.nextElementSibling;
    [...Array(iterations).keys()].forEach((index) => {
      const style = window.getComputedStyle(currentElement);
      currentElement.style.display = style.getPropertyValue('display') == attr ? 'none' : attr;
      currentElement = currentElement.nextElementSibling;
    });
  }

  RedirecionamentoEnquadrado() {
    this.router.navigateByUrl(`/limites/enquadrado/4994`);
  }

  RedirecionamentoDesenquadrado() {
    this.router.navigateByUrl(`/limites/desenquadrado/4994`);
  }

  hasProp(o, name) {
    return o.hasOwnProperty(name);
  }

  constructor(private router: Router, private internalData: InternalDataService, private http: HttpService) {}
}
