import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserWebsocketService {
  userWebsocket: WebSocketSubject<any>;

  constructor() {}

  startUserWebsocket(userToken: string) {
    this.userWebsocket = webSocket({
      url: `${environment.websocket}/ws/canais/calculos_processamento/${userToken}/`,
      closeObserver: {
        next: () => {},
      },
      openObserver: {
        next: () => {},
      },
    });
    return this.getUserWebsocket();
  }

  getUserWebsocket() {
    return this.userWebsocket.asObservable();
  }

  sendUserWebsocketMessage(message) {
    this.userWebsocket.next({ message: message });
  }

  closeUserWebsocket() {
    this.userWebsocket.complete();
  }
}
