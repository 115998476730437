import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';

@Component({
  selector: 'app-definicoes-calculo',
  templateUrl: './definicoes-calculo.component.html',
  styleUrls: ['./definicoes-calculo.component.less'],
})
export class DefinicoesCalculoComponent implements OnInit {
  public toggled: boolean;
  public userData: any = {
    usuario: '',
    nome: '',
    email: '',
    instituicao: '',
    descricao: '',
  };

  constructor(private internalData: InternalDataService, private httpService: HttpService) {
    this.internalData.getSidebarToggle().subscribe((val) => {
      this.toggled = val;
    });
    this.httpService.getUserInfo().subscribe((ret) => {
      this.userData.usuario = ret['username'];
      this.userData.nome = ret['nome_completo'];
      this.userData.email = ret['email'];
      this.userData.instituicao = ret['instituicao_nome'];
      this.userData.descricao = ret['instituicao_descricao'];
    });
  }

  ngOnInit() {}
}
