import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { ButtonRelativeToValue } from 'highcharts';
import { Observable, of } from 'rxjs';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.less'],
})
export class HelpComponent implements OnInit {
  @ViewChild('search', { static: false }) searchElemRef: ElementRef;
  @ViewChild('clearButton', { static: false })
  clearButton: ButtonRelativeToValue;
  @ViewChild('nextButton', { static: false }) nextButton: ButtonRelativeToValue;
  @ViewChild('prevButton', { static: false }) prevButton: ButtonRelativeToValue;
  @ViewChild('accordion', { static: false }) accordion: MatAccordion;
  @ViewChild('panel', { static: false }) panel: MatExpansionPanel;
  myStyle: SafeHtml;
  searchText$: Observable<string>;
  searchConfig = { separateWordSearch: false };
  procuraTexto: string;
  step: number = -2;
  toggled: boolean;
  abertos: boolean[] = [false];
  posicaoAberto: any[] = [];
  next: number = 0;

  public buscaPalavra() {
    this.searchText$ = of(this.procuraTexto);
    this.abre();
  }

  public abre() {
    this.abertos = this.lista.map((item) =>
      (item.nome + item.conteudo).toLowerCase().includes(this.procuraTexto.toLowerCase()),
    );
  }

  public limparBusca() {
    this.procuraTexto = null;
    this.searchText$ = of(this.procuraTexto);
    this.abertos = [false];
    this.next = 0;
    this.scrollTop();
  }

  public scrollTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }

  public scrollNext() {
    const panelAberto = document.querySelectorAll('.mat-expanded.mat-expansion-panel');

    const posicoes: any[] = [];
    panelAberto.forEach((item) => {
      posicoes.push(item['offsetTop']);
    });

    this.posicaoAberto = posicoes;
    this.next++;
    if (this.next == 0) {
      window.scroll({
        top: this.posicaoAberto[0] - 250,
        behavior: 'smooth',
      });
    } else {
      window.scroll({
        top: this.posicaoAberto[this.next] - 250,
        behavior: 'smooth',
      });
    }
  }

  public scrollPrevious() {
    this.next--;
    window.scroll({
      top: this.posicaoAberto[this.next] - 250,
      behavior: 'smooth',
    });
  }

  constructor(private internalData: InternalDataService, private _sanitizer: DomSanitizer) {
    this.next = 0;

    this.internalData.getSidebarToggle().subscribe((val) => {
      this.toggled = val;
    });
  }

  ngOnInit() {
    const botaoTop = document.querySelectorAll('.fixed-action-btn a');
    const inputEnter = document.getElementsByName('busca')[0];

    this.myStyle = this._sanitizer.bypassSecurityTrustHtml(
      `<style>#conteudoStyle .alignTexto {text-align: center; font-size: small;} #conteudoStyle .child { text-align: justify;} #conteudoStyle .alignImg {display: flex; justify-content: center;};  </style> `,
    );
    botaoTop.forEach((item) => {
      item.addEventListener('click', this.scrollTop);
    });

    inputEnter.addEventListener('keyup', (e) => {
      const key = e.which || e.keyCode;
      if (key == 13) {
        this.buscaPalavra();
      }
    });
  }

  ngAfterViewInit() {}

  public lista = [
    {
      nome: 'Definições de Cálculo',
      conteudo: `
      <p class="child"><br><b>Cálculo de Volatilidade:</b> A Volatilidade anualizada últimos 12 meses corresponde ao desvio padrão dos retornos do fundo, considerando uma amostra de 252.<br> 
      <br><b>Índice de Sharpe:</b> O índice de Sharpe é a razão entre a diferença entre a média dos retornos do fundo e a curva Risk Free e a volatilidade, considerando uma amostra de 252, e a curva diária do Risk Free, conforme a Equação 1:<br> 
      <div class="alignTexto"><img style='margin-left: 120px' src="assets/images/help/formula.png" class="" alt="Responsive image"></div><br> </div>
      <br><b>Variação de Cenário:</b> A variação de cenário projeta diferentes situações incomuns de mercado para que o gestor possa identificar possíveis perdas em cada uma das situações. Os choques são dados nas curvas de mercado e grupos de produtos. Por isso, o Mitra Monitor traz as seguintes variações de cenário:<br>
      <br><b>Cenário Stress - Otimista e Pessimista:</b> Considera o cenário de stress da BMF, mas com o stress de 1% para mais ou para menos, em cada um dos cenários.<br>
      <br><b>Stress CVM - Juros, Câmbio e Bolsa:</b> Considera o cenário de Stress da CVM para os produtos susceptíveis aos stress a cada um dos cenários apresentados. De forma semelhante o choque é de -1%.<br> 
      <br><b>Pior Cenário BM&F:</b>  A projeção do pior cenário BM&F faz as seguintes considerações: C_IGP Alto 4, DESAGIOLFT Alto 4, IBVSP Baixo 4, IDI Baixo 4, Pré Alto 4 e esses são pacotes de informações vindos da BM&F e indicam o cenário de stress para todos os ativos da carteira.<br> 
      <p class="child"> <br><b>Risco <br> </b>O risco apresentado é o VaR, que é um modelo que mede, sob condições normais de mercado, a máxima perda esperada de um portfólio, com certo grau de confiança, para um dado horizonte de tempo. Dessa forma o Mitra Monitor, adota o grau de confiança de 99% (Fator de confiança f 2,33), com distribuição normal,  período de 1 dia, amostra de 252 e volatilidade EWMA, que considera as informações mais recentes com maior peso, através do Fator de Decaimento.  </p>
      <p class="child"> <br>O <b>VaR/Posição</b> representa o que impacto de uma possível perda representa em  relação à posição do fundo. </p>
      <p class="child"><br>A <b>ETL</b> (Expectated Tail Loss) é uma medida de risco além do que estimada pelo VaR, pois considera eventos menos prováveis de acontecer, considerando a cauda (tail) da distribuição normal. Por esse motivo o ETL, é maior que o VaR. <br> </p>
      <p class="child"> <br><b>Liquidez<br></b> A Liquidez apresentada pelo Mitra Monitor considera a liquidez da carteira nos prazos de 1, 21 e 252 dias, dessa forma considera as posições em relação ao PL, em cada um desses períodos. Dessa forma, os prazos considerados variam de acordo com o tipo de ativo, por exemplo, ativos negociados em bolsa como ações e fundos imobiliários possuem prazo 1, bem como para ativos públicos, pela alta liquidez. Para os títulos privados, como debêntures, a liquidez é ponderada pelo volume de negociação desses títulos, para que o dado apresentado seja mais próximo do realizado no mercado. Para os demais títulos é considerado o prazo até o vencimento. </p>
     
   </p>
      `,
    },
    {
      nome: 'Login',
      conteudo: `
        <div class="alignImg"><img src="assets/images/help/tela-de-login.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto" > Figura 1.Tela de Login </p>
        <p class="child"><br> <br>A tela de Login  (Figura 1) apresenta ícones na barra superior que direcionam o usuário para informações adicionais da empresa, como o Quem Somos, e também para outros produtos que possam interessar como a Solução de Previdência, Mitra, POP e POP Trade. 
            No formulário de Login, o cliente informa o seu usuário e senha, para logar no Mitra Monitor clicando no botão Acessar. Caso o usuário tenha perdido a senha, esta pode ser recuperada pelo “Esqueci minha senha”.
      `,
    },
    {
      nome: 'Menu Usuário',
      conteudo: `
        <p class="child">Para acessar o Menu Usuário, basta clicar no canto superior direito indicado na Figura 2 para acessar as opções de Consumo, Perfil, Histórico, Ajuda, Definições, Sair, como demonstrado na Figura 3. </p>      
        <br><br><div class="alignImg"><img src="assets/images/help/acesso-ao-menu-usuario.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto"> Figura 2. Acesso ao Menu Usuário </p>
        <br><br><div class="alignImg"><img src="assets/images/help/menu-usuario.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto" > Figura 3. Menu Usuário </p>
      `,
    },
    {
      nome: 'Consumo',
      conteudo: `
        <p class="child">As informações de Consumo são apresentadas pelo usuário na Figura 4, sendo possível visualizar o histórico por mês de uso. Em relação a cobrança, ela é feita a cada fundo e data posição de cálculo, como demonstrado nos Dados de Utilização. Também é demonstrado a proporção de cálculos realizados por fundo, caso o usuário tenha mais de um fundo. <br>
        </p>
        <br><div class="alignImg"><img src="assets/images/help/consumo.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto">Figura 4. Consumo </p>
      `,
    },
    {
      nome: 'Perfil',
      conteudo: `
        <p class="child">Na aba de Perfil, indicado na Figura 5, o usuário pode visualizar o nome do login, o nome exibido no canto superior direito do relatório, o e-mail vinculado ao seu usuário, a instituição a qual ele pertence, bem como a descrição. No bloco abaixo, o usuário também pode alterar a sua senha, indicando a antiga e alterando para a nova senha desejada.  
        Também é possível  ocultar e reexibir as informações ao selecionar a seta verde no canto superior direito de cada bloco. <br>
        </p>
        <br><div class="alignImg"><img src="assets/images/help/perfil-do-usuario.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto">Figura 5. Perfil do Usuário </p>
      `,
    },
    {
      nome: 'Histórico',
      conteudo: `
        <p class="child">A aba de Histórico (Figura 6) possui as informações de todos os processamentos realizados no Mitra Monitor, contendo o Nome do Fundo, a Data Posição e a Data Criação. No filtro, é possível selecionar o nome do fundo desejado ou a data posição em que o processamento foi efetuado.
        </p>
        <br><br><div class="alignImg"><img src="assets/images/help/historico-de-processamento.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto"> Figura 6. Histórico de Processamento</p>
         `,
    },
    {
      nome: 'Definições',
      conteudo: `
        <p class="child" > As definições apresentam as parametrizações usadas no cálculo do relatório do Mitra Monitor. Sendo assim, são apresentados os parâmetros para o cálculo do  Risco como a Distribuição Normal, Volatilidade EWMA, Moeda Real, Amostra de 252, Período de 1 dia, e o Intervalo de Confiança de 99%. Assim o usuário pode saber quais parâmetros estão sendo usados no cálculo do seu relatório.
        </p>
      `,
    },
    {
      nome: 'Sair',
      conteudo: `
        <p class="child" >O botão de Sair faz o Logout do Mitra Monitor, e volta à tela de Login.</p>
      `,
    },
    {
      nome: 'Tela Inicial',
      conteudo: `
        <p class="child">Ao logar pela primeira vez no Mitra Monitor o usuário irá visualizar a seguinte tela (Figura 7), apenas com o nome da Instituição na sidebar à esquerda. Logo abaixo estão as informações do Processamento que informam o status do cálculo em cada processo.
        </p>
        <br><br><div class="alignImg"><img src="assets/images/help/tela-inicial-do-mitra-monitor.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto">Figura 7. Tela Inicial do Mitra Monitor</p>
         `,
    },
    {
      nome: 'Processamento',
      conteudo: `
        <p class="child">Quando o XML é importado, o status do cálculo pode ser observado pelo Processamento. 
        Inicialmente, o XML fica no status Esperando cálculo (Figura 8) durante a importação do arquivo. Quando o cálculo se inicia, o status se atualiza para Calculando (Figura 9) e por fim, quando o relatório está pronto, o status é o Finalizado (Figura 10). Ao clicar na opção Cancelar no status Esperando Cálculo ou Calculando, o usuário bloqueia o cálculo e pode verificar quais processos foram interrompidos ao selecionar a opção Cancelados (Figura 11).         
        </p><br>
        <br><br><div class="alignImg"><img src="assets/images/help/processamento-esperando-calculo.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto">Figura 8. Processamento, Esperando Cálculo</p>
        <br><br><div class="alignImg"><img src="assets/images/help/processamento-calculando.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto">Figura 9. Processamento, Calculando </p>
        <br><br><div class="alignImg"><img src="assets/images/help/processamento-calculando.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto">Figura 10. Processamento, Finalizados</p>
        <br><br><div class="alignImg"><img src="assets/images/help/processamento-cancelados.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto">Figura 11. Processamento, Cancelados</p>
        <br><br><p class="child">A opção de Erro serve para informar possíveis problemas como ativo não encontrado, e o responsável pela correção, como ilustrado na Figura 12. Ao enviar o e-mail, a equipe de suporte é acionada para a resolução do problema. </p>
        <br><br><div class="alignImg"><img src="assets/images/help/processamento-erro.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto">Figura 12.Processamento, Erro </p>
      `,
    },
    {
      nome: 'Barra de Comando',
      conteudo: `
        <p class="child">À esquerda está a barra de comando, sendo o primeiro ícone uma seta que serve para ocultar ou exibir a sidebar, demonstrado na Figura 13.</p>
        <br><br><div class="alignImg"><img src="assets/images/help/icone-abrir-menu-lateral.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto">Figura 13. Ícone Abrir Menu Lateral</p>
         `,
    },

    {
      nome: 'Importador de XML',
      conteudo: `
        <p class="child"><br>Já o segundo ícone é o botão de Importar XML (Figura 14) e ao clicar abre-se o modal de importação do XML, como demonstrado  na Figura 15.<br><br></p>
        <div class="alignImg"><img src="assets/images/help/figura14.png" class="img-fluid" alt="Responsive image" ></div> 
        <p class="alignTexto"> Figura 14. Ícone para Importar XML </p>
        <br><div class="alignImg"><img src="assets/images/help/figura15.png" class="img-fluid" alt="Responsive image" ></div> 
        <p class="alignTexto">Figura 15. Modal de Importação, Passo 1 Importação XML.<br> </p>
        <p class="child"><br>O segundo passo do modal é a Validação XML (Figura 16) que faz uma leitura completa do arquivo e retorna uma planilha disponível para download com todas as informações das inconsistências encontradas que podem, ou não, resultar em erros de cálculo, dependendo de sua gravidade.<br><br></p>
        <div class="alignImg"><img src="assets/images/help/figura16.png" class="img-fluid" alt="Responsive image" ></div> 
        <p class="alignTexto"> Figura 16. Passo 2 do Modal de Importação, Validação XML </p>
        <p class="child"><br>O terceiro passo do modal, é o Alerta de sobreposição (Figura 17) que indica ao usuário que para uma determinada data, o fundo já foi calculado e a nova importação do XML acarretará na sobreposição das informações já calculadas. Dessa forma, o usuário pode cancelar ou prosseguir com a importação.<br> </p>
        <br><div class="alignImg"><img src="assets/images/help/figura17.png" class="img-fluid" alt="Responsive image" ></div> 
        <p class="alignTexto">Figura 17. Passo 3 do Modal de Importação, Alerta de sobreposição.<br> </p>
        <p class="child"><br>No quarto passo do modal (Figura 18), é apresentada a possibilidade do usuário verificar a explosão da composição do seu fundo  ao permitir a importação dos XMLs dos fundos adquiridos pelo gestor. Caso o usuário queira verificar apenas o relatório do seu fundo, deve selecionar a opção Não, e o quarto Passo 4 XMLs ausentes é omitido (Figura 19). Em caso afirmativo, ocorre a verificação dos XMLs ausentes para proceder a explosão, como indicado na Figura 19. Além disso, o usuário pode voltar ao início para importar os XMLs ausentes, caso deseje, ou voltar ao Passo 3 para mudar a opção de não explosão do fundo.
        </p>
        <br><br><div class="alignImg"><img src="assets/images/help/figura18.png" class="img-fluid" alt="Responsive image" ></div> 
        <p class="alignTexto">Figura 18. Passo 4 do Modal de Importação, Visualizar ativos da composição.<br> </p>
        <br><div class="alignImg"><img src="assets/images/help/figura19.png" class="img-fluid" alt="Responsive image" ></div> 
        <p class="alignTexto">Figura 19. Passo 5 do Modal de Importação, XMLs ausentes.<br></p>
        <p class="child"><br>Ao prosseguir no modal, o usuário avança para o Passo 6 (Figura 20), que possibilita a escolha do Benchmark.<br></p>
        <br><div class="alignImg"><img src="assets/images/help/figura20-2.png" class="img-fluid" alt="Responsive image"></div>
        <p class="alignTexto">Figura 20. Seleção de Benchmarks.</p>
      `,
    },
    {
      nome: 'Relatório',
      conteudo: `
        <p class="child"><br>O terceiro ícone na barra de comando é o Relatório (Figura 21) que exibe as informações calculadas na tela. Caso não haja nenhum fundo selecionado, aparece um pop-up, com um aviso para o usuário selecionar um fundo (Figura 21).
        <br><br></p>
        <div class="alignImg"><img src="assets/images/help/icone-do-relatorio.png" class="img-fluid" alt="Responsive image" ></div> 
        <p class="alignTexto"> Figura 21. Ícone do Relatório. </p>
        <br><br><div class="alignImg"><img src="assets/images/help/aviso-para-selecao-do-fundo.png" class="img-fluid" alt="Responsive image" ></div> 
        <p class="alignTexto">Figura 22. Aviso para seleção do fundo.</p>
      `,
    },
    {
      nome: 'Gestão',
      conteudo: `
      <p class="child"><br>A Gestão (Figura 23) traz informações do resultado e o acompanhamento das boletas inseridas, auxiliando o gestor na tomada de decisão. Caso não tenha nenhum fundo selecionado aparece o mesmo aviso  da Figura 22.
      <br><br></p>
      <div class="alignImg"><img src="assets/images/help/icone-de-gestao.png" class="img-fluid" alt="Responsive image" ></div> 
      <p class="alignTexto">Figura 23. Ícone de Gestão. </p>
      <br><br><div class="alignImg"><img src="assets/images/help/relatorio-de-gestao.png" class="img-fluid" alt="Responsive image" ></div> 
      <p class="alignTexto">Figura 24. Relatório de Gestão </p>
    `,
    },
    {
      nome: 'Boletas',
      conteudo: `
      <p class="child"><br>O último ícone na barra de comando (Figura 25) é o das boletas, e ao clicar neste botão o usuário abre gride de boletas (Figura 25) onde é possível simular a inserção de novas operações  individualmente ao clicar no botão Nova, para os produtos: Ação, Opção Títulos Públicos, Títulos Privados, Cotas de Fundos, Futuro, BTC, Compromissada e CDB (Figura 27). Lembrando que cada produto tem a indicação de campos obrigatórios, e o não preenchimento impede a criação da boleta.<br><br></p>
      <div class="alignImg"><img src="assets/images/help/icone-de-boletas.png" class="img-fluid" alt="Responsive image" ></div> 
      <p class="alignTexto">Figura 25. Ícone de Boletas </p>
      <br><br><div class="alignImg"><img src="assets/images/help/gride-de-boletas.png" class="img-fluid" alt="Responsive image" ></div> 
      <p class="alignTexto">Figura 26. Gride de Boletas<br> </p>
      <br><div class="alignImg"><img src="assets/images/help/registro-de-novas-operacoes.png" class="img-fluid" alt="Responsive image" ></div> 
      <p class="alignTexto">Figura 27. Registro de Novas Operações<br> </p>
      <br><p class="child">Também é permitido ao usuário realizar a  importação em lote, ao selecionar essa opção no gride e então se abre o modal de importação de boletas. Os arquivos habilitados para a integração são os arquivos PESC e o excel, e este está disponível no link no primeiro passo do modal. Os Passos 2 e 3 são exclusivos do Arquivo PESC, onde é verificado se o arquivo já foi importado anteriormente. No Passo 3 é informado ao usuário informações vindas do Arquivo PESC.<br> </p> 
      <br><div class="alignImg"><img src="assets/images/help/importacao-de-boletas-em-lote.png" class="img-fluid" alt="Responsive image" ></div> 
      <p class="alignTexto">Figura 28. Importação de boletas em lote<br></p>
      <p class="child"><br>Ao proceder a integração em lote, caso ocorra um erro no preenchimento dos campos, é gerado um arquivo que indica ao usuário as incongruências das informações fornecidas (Figura 28). De forma semelhante, também é gerado um arquivo de erros para o Arquivo PESC. Dessa forma, o usuário pode voltar ao início e reinserir um novo arquivo com as correções, ou avançar e inserir apenas as boletas sem erro, informadas no Passo 5 (Figura 30) que indica a proporção das boletas inseridas com sucesso. Ao usuário também é permitido Editar e Apagar as boletas inseridas. 
      <br></p>
      <br><div class="alignImg"><img src="assets/images/help/integracao-de-boletas-e-download-do-arquivo-de-erros.png" class="img-fluid" alt="Responsive image"></div>
      <p class="alignTexto">Figura 29. Integração de boletas e download do arquivo de erros<br></p> 
      <br><div class="alignImg"><img src="assets/images/help/conclusao-da-importacao.png" class="img-fluid" alt="Responsive image"></div>
      <p class="alignTexto">Figura 30. Conclusão da importação<br></p> 
      <p class="child"><br>Por fim, ao selecionar a opção Reprocessar Relatório, é exibido um aviso exibido na Figura 31, e um novo relatório é calculado considerando a inserção das boletas. <br></p> 
      <br><div class="alignImg"><img src="assets/images/help/aviso-para-reprocessamento-do-relatorio.png" class="img-fluid" alt="Responsive image"></div>   
      <p class="alignTexto">Figura 31. Aviso para reprocessamento do relatório.</p>
      `,
    },
  ];
}
