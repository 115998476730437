import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseCampoComEditComponent } from '../base-campo-com-edit/base-campo-com-edit.component';

@Component({
  selector: 'app-campo-emissor',
  templateUrl: './campo-emissor.component.html',
  styleUrls: ['../base-campo-com-edit/base-campo-com-edit.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BaseCampoComEditComponent, useExisting: CampoEmissorComponent }],
})
export class CampoEmissorComponent extends BaseCampoComEditComponent implements OnInit {
  ngOnInit() {
    this.nome = 'Emissor';
    this.prefixo = 'contraparte';
    this.formControl = new FormControl(null, this.requireMatchOrNull());
  }
}
