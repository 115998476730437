import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { RenderBoletasService } from 'src/app/services/render-boletas/render-boletas.service';
import { MonitorAlertsComponent } from '../../monitor-alerts/monitor-alerts.component';
import { UploadXmlComponent } from '../../upload-xml/upload-xml.component';

@Component({
  selector: 'app-sidebar-dock',
  templateUrl: './SidebarDock.component.html',
  styleUrls: ['./SidebarDock.component.less'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('150ms ease-out')),
      transition('default => rotated', animate('150ms ease-in')),
    ]),
  ],
})
export class SidebarDockComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  public tipo_estrutura = 'Asset';
  state: string = 'default';
  ocultarElementos = 0;
  estruturas: any[];
  instituicao: string;
  permissao: boolean;
  toggled: boolean;
  gestaoAberto: boolean;
  relatorioAberto: boolean;
  boletasAberto: boolean;
  estruturaSelecionada: string;
  tipoInstituicao: string;

  constructor(
    private internalData: InternalDataService,
    private dialog: MatDialog,
    private renderBoletas: RenderBoletasService,
    public monitorAlertsComponent: MonitorAlertsComponent,
  ) {
    this.internalData.getSelectedLine.subscribe((msg) => {
      this.estruturaSelecionada = msg.nome;
    });

    this.internalData.getBoletasAberto().subscribe((isBoletaAberta) => {
      this.boletasAberto = isBoletaAberta;
    });

    this.internalData.getRelatorioAberto.subscribe((isRelatorioAberto) => {
      this.relatorioAberto = isRelatorioAberto;
    });

    this.internalData.getGestaoAberto.subscribe((isGestaoAberta) => {
      this.gestaoAberto = isGestaoAberta;
    });

    this.internalData.getTipoInstituicao.subscribe((res) => {
      this.tipo_estrutura = res;
    });
  }

  ngOnInit() {}

  toggleSidenav() {
    this.state = this.state === 'default' ? 'rotated' : 'default';
    this.sidenav.toggle();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize')); // para redimensionar os graficos
    }, 50);
  }

  openDialog() {
    const dialogRef = this.dialog.open(UploadXmlComponent);
    dialogRef.afterClosed().subscribe(() => true);
  }

  abreGestao() {
    if (this.estruturaSelecionada != undefined) {
      this.gestaoAberto = !this.gestaoAberto;
      this.internalData.setGestaoAberto(this.gestaoAberto);
    } else {
      this.monitorAlertsComponent.abrirAlert('Aviso', 'Selecione um fundo', false, 'Ok');
    }
  }

  abreRelatorio() {
    if (this.estruturaSelecionada != undefined) {
      this.relatorioAberto = !this.relatorioAberto;
      this.internalData.setRelatorioAberto(this.relatorioAberto);
    } else {
      this.monitorAlertsComponent.abrirAlert('Aviso', 'Selecione um fundo', false, 'Ok');
    }
  }

  abreBoletas() {
    if (this.estruturaSelecionada != undefined) {
      this.boletasAberto = !this.boletasAberto;
      this.internalData.setBoletasAberto(this.boletasAberto);
      window.resizeTo(100, 100);
    } else {
      this.monitorAlertsComponent.abrirAlert('Aviso', 'Selecione um fundo', false, 'Ok');
    }
  }
}
