import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpService } from '../http/http.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  private isAuth: boolean;

  constructor(private http: HttpService) {}

  setIsAuth(value) {
    this.isAuth = value;
  }

  canActivate(http: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isAuth) {
      return true;
    } else {
      return this.http.getIsAuth().pipe(
        map(() => {
          this.isAuth = true;
          return true;
        }),
        catchError(() => {
          this.isAuth = false;
          return of(false);
        }),
      );
    }
  }
}
