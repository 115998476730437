import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  constructor() {}
  requireMatch(Arr: any[]): ValidatorFn {
    // Valida se o valor está no Arr
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const selection: any = control.value;
      if (Arr.indexOf(selection) !== -1) {
        return null;
      }
      return { match: true };
    };
  }
  onlyOne(validator: ValidatorFn): ValidatorFn {
    // Valida se apenas um dos inputs passam na validação do "validador"
    return (group: FormGroup | FormArray): ValidationErrors | null => {
      if (group && group.controls) {
        const formsValidos = Object.keys(group.controls).filter((k) => {
          return !validator(group.controls[k]);
        });
        return formsValidos.length == 1 ? null : { onlyOne: true };
      }
      return null;
    };
  }
}
