import { Component, HostListener, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { AnaliticoWebsocketService } from 'src/app/services/websocket/analitico-websocket/analitico-websocket.service';
import { UserWebsocketService } from 'src/app/services/websocket/user-websocket/user-websocket.service';
import { HttpService } from '../../services/http/http.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.less'],
})
export class GridComponent implements OnInit {
  private gridApi;
  public gridColumnApi;
  public getRowNodeId;

  columnDefs = [
    {
      headerName: 'ID',
      field: 'id',
      cellClass: 'cell-string',
      lockPosition: true,
      resizable: true,
      hide: true,
    },
    {
      headerName: 'Produto',
      field: 'Produto',
      cellClass: 'cell-string',
      lockPosition: true,
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Quantidade',
      field: 'Quantidade',
      cellClass: 'cell-number',
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      lockPosition: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter2,
    },
    {
      headerName: 'Preço',
      field: 'Preco',
      cellClass: 'cell-number',
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      lockPosition: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter6,
    },
    {
      headerName: 'Posição',
      field: 'Posicao',
      cellClass: 'cell-number',
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      lockPosition: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter2,
    },
    {
      headerName: 'Resultado',
      field: 'Resultado',
      cellClass: 'cell-number',
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      lockPosition: true,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter2,
    },
    {
      headerName: 'Alavancado',
      field: 'PrecoAlavancado',
      cellClass: 'cell-number',
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      lockPosition: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: 'right' },
      valueFormatter: this.formatter2,
    },
  ];

  rowData: any[] = [];
  fundoAtual: number;

  constructor(
    private analiticoWebsocket: AnaliticoWebsocketService,
    private userWebsocket: UserWebsocketService,
    private http: HttpService,
    private cookies: CookieService,
    private internalData: InternalDataService,
  ) {
    this.getRowNodeId = function (data) {
      return data.id;
    };
  }

  /**
   * Escuta a mudança de tamanho de tela
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.gridApi.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.setGridData();
  }

  setGridData() {
    this.internalData.getFundoSelecionado().subscribe((fundo) => {
      this.fundoAtual = fundo.cadastro_xml_id;
      this.http.getAnalitico(fundo.cadastro_xml_id).subscribe((res) => {
        this.gridApi.setRowData([]);
        res.forEach((item) => {
          const novoItem = {};
          novoItem['id'] = item['id'];
          novoItem['Produto'] = item['produto'];
          novoItem['Quantidade'] = item['quantidade'];
          novoItem['Resultado'] = item['resultado'];
          novoItem['Posicao'] = item['posicao'];
          novoItem['Preco'] = item['preco'];
          novoItem['PrecoAlavancado'] = item['alavancado'];
          this.gridApi.updateRowData({ add: [novoItem] });
        });
        window.dispatchEvent(new Event('resize'));
      });
    });
  }

  formatter2(params) {
    return Number(params.value).toFixed(2).replace('.', ',');
  }

  formatter6(params) {
    return Number(params.value).toFixed(6).replace('.', ',');
  }

  ngOnInit() {
    this.analiticoWebsocket.getAnaliticoWebsocket().subscribe((msg) => {
      try {
        this.addOrUpdate(msg['id'], msg['campo'], msg['valor']);
      } catch {}
    });
    this.userWebsocket.getUserWebsocket().subscribe((msg) => {
      if (msg.hasOwnProperty('reload_analitico')) {
        if (this.fundoAtual == (msg['reload_analitico'] as number)) {
          this.setGridData();
        }
      }
    });
  }

  public addOrUpdate(id: string, field: string, value: number) {
    // Update
    const rowNode = this.gridApi.getRowNode(id);
    if (rowNode != undefined) {
      // if(field == 'Fundo' || field == 'Produto' || field == 'Contraparte'){
      //   rowNode.setDataValue(field, value);
      // }
      // else
      // {
      //   rowNode.setDataValue(field, Number(value));
      // }
    } else {
      if (field == 'cod_fundo') {
        if (this.fundoAtual == Number(value)) {
          // Cria um row novo
          const novoItem = {};
          novoItem['id'] = id;
          this.gridApi.updateRowData({ add: [novoItem] });
        }
      }
    }
    window.dispatchEvent(new Event('resize'));
  }
}
