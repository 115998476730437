import { registerLocaleData } from '@angular/common';
import localept from '@angular/common/locales/pt';
import { Component, LOCALE_ID, NgZone, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http/http.service';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';
import { ValidatorsService } from 'src/app/services/validators/validators.service';
import { UserWebsocketService } from 'src/app/services/websocket/user-websocket/user-websocket.service';
import { SidebarContentComponent } from '../sidebar/sidebar-content/sidebar-content.component';
import { iRespostaVerificaXml } from './../../models/resposta-verifica-xml.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { TIPOS_PERFIL } from './../../utils';

registerLocaleData(localept, 'ptBr');
interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

interface IPlano {
  'nome': string,
  'id': string
}
@Component({
  selector: 'app-upload-xml',
  templateUrl: './upload-xml.component.html',
  styleUrls: ['./upload-xml.component.less'],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
})
export class UploadXmlComponent implements OnInit {
  private formData: FormData;
  public fileSelectText = 'Nenhum arquivo selecionado';
  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  importacaoControl: FormControl;
  checkPosicaoControl: FormControl;
  checkExplosaoControl: FormControl;
  checkXMLControl: FormControl;
  ausentesControl: FormControl;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  formBenchMark: FormArray;
  rentDadosForm: any[] = [];
  rentDados: any[] = [];
  carteiras: any[];
  planos: IPlano[];
  cnpjs: string[];
  uuid: string;
  benchmarks: string[];
  relatorioFuncao: any = {};
  diferencasCompleto: boolean;
  spinnerAusentes: boolean;
  showSpinner2 = false;
  temXML = false;
  public passagemXml: any;
  public RespostaJson: any;
  public diferencasParametros: any = { comando: 'esperar', parametro_id: [] };
  public tipoInstituicao;
  public jobRedis;
  public msgRetorno: string;
  public fundosCalculados: string[];
  isOverlap = false;
  statusValidacao: 'validando' | 'invalido' | 'valido' = 'validando';
  TIPOS_PERFIL = TIPOS_PERFIL;
  importou: boolean;
  calculoJaPostado: boolean;
  filteredOptions: Observable<string[]>[] = [];
  stepLabels: Record<string, string>;
  xmlAusentes = [];
  listaFundos: string[];
  mensagemPosicao: string;
  mensagemLog: string;
  logValidacaoXML: string;
  arquivosValidacaoDownload = [];
  fileUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder,
    private http: HttpService,
    private router: Router,
    private interData: InternalDataService,
    private sideBar: SidebarContentComponent,
    private ngZone: NgZone,
    private validator: ValidatorsService,
    private userWebsocket: UserWebsocketService,
    private modalRef: MatDialogRef<UploadXmlComponent>,
  ) {
    this.interData.getTipoInstituicao.subscribe((res) => {
      this.tipoInstituicao = res;
    });
    this.carteiras = [];
    this.calculoJaPostado = false;

    this.userWebsocket.getUserWebsocket().subscribe((msg) => {
      try {
        if (msg['diferencas_reload'] == true) {
          this.diferencas();
        }
      } catch (e) { }
    });
    this.stepLabels = {
      importacao: 'Importação XML',
      checkArquivo: 'Validação XML',
      checkPosicao: 'Alerta de sobreposição',
      checkExplosao: 'Visualizar ativos da composição',
      ausentesPrev: 'Planos e XMLs Ausentes',
      ausentesAsset: 'XMLs Ausentes',
      rentabilidade: 'Importar rentabilidade',
      benchmark: 'Benchmark',
      diferencas: 'Diferenças',
      calculo: 'Calculo',
    };
    this.xmlAusentes = [];
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  inputFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      const fileList = Object.keys(event.target.files);
      this.formData = new FormData();
      for (const key in fileList) {
        this.temXML = true;
        this.formData.append(event.target.files[key]['name'], event.target.files[key]);
      }
      if (fileList.length == 1) {
        this.fileSelectText = event.target.files[0]['name'];
      } else {
        this.fileSelectText = fileList.length + ' arquivos selecionados';
      }
    }
  }

  ngOnInit() {
    this.formData = new FormData();
    this.importacaoControl = new FormControl();
    this.checkPosicaoControl = new FormControl();
    this.checkExplosaoControl = new FormControl();
    this.ausentesControl = new FormControl();
    this.checkXMLControl = new FormControl();

    this.diferencasParametros['comando'] = 'esperar';

    this.thirdFormGroup = new FormGroup({
      thirdCtrl: new FormControl(),
    });
    this.formBenchMark = new FormArray([]);
    this.fourthFormGroup = new FormGroup({
      benchmark: this.formBenchMark,
    });
    this.diferencasCompleto = false;
    this.spinnerAusentes = true;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLocaleLowerCase();
    return this.benchmarks.filter((option) => option.toLocaleLowerCase().includes(filterValue));
  }

  public manageBenchControl(index: number) {
    this.filteredOptions[index] = this.formBenchMark.at(index).valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value)),
    );
  }

  onSubmit() {
    if (this.temXML) {
      this.arquivosValidacaoDownload = []
      this.nextStep();
      this.msgRetorno = '';
      this.validadorXml();
    } else {
      this.msgRetorno = 'Por favor adicione um arquivo .xml';
    }
  }

  terminarValidacaoXML() {
    this.nextStep();
    this.verificaSobreposicao();
  }

  nextStep() {
    let stepOk = false;
    this.ngZone.run(() => {
      if (this.stepper.selectedIndex == 0) {
        stepOk = true;
      } else if (
        [this.stepLabels['ausentesPrev'], this.stepLabels['ausentesAsset']].indexOf(this.stepper.selected.label) > -1
      ) {
        this.stepper.linear = false;
        this.blockStepBefore(this.stepper.selectedIndex + 1);
        stepOk = true;
      } else {
        if (this.stepper.selected.stepControl) {
          stepOk = this.stepper.selected.stepControl.valid;
        }
      }
      if (stepOk) {
        this.stepper.selected.completed = true;
        this.stepper.next();
      }
    });
  }

  trataStep() {
    // this.nextStep();
    if (this.tipoInstituicao == 'Previdencia') {
      this.postXml(true);
    } else {
      this.nextStep();
    }
  }

  geraArquivoCsv(json: Array<any>) {
    const fields = Object.keys(json[0]);
    const replacer = (_, value) => {
      const wordMap = {
        error: 'Erro',
        warning: 'Informativo',
        gravidade: 'Gravidade',
        linha: 'Linha',
        tag: 'Tag',
        mensagem: 'Mensagem',
      };

      return wordMap[value] ?? value ?? '';
    };
    const csvArray = json.map((row) => fields.map((field) => JSON.stringify(row[field], replacer)).join(';'));
    const header = fields.map((word) => replacer(null, word)).join(';');
    csvArray.unshift(header);
    const csvString = csvArray.join('\r\n');
    return csvString;
  }

  validadorXml() {
    this.statusValidacao = 'validando';
    type respostaValidacao = {
      [nomeArquivo: string]: [{gravidade: string, linha: number, tag: string, mensagem: string}]
    }

    this.http.validaXml(this.formData).subscribe((res: respostaValidacao) => {
      const valoresResposta = Object.values(res);
      if (valoresResposta.every((logs) => logs.length < 1)) {
        this.statusValidacao = 'valido';
        this.mensagemLog = 'Não foram encontrados erros na validação do XML';
        this.terminarValidacaoXML();
        return;
      }

      this.statusValidacao = 'invalido';
      this.mensagemLog = valoresResposta.some((logs) => logs.some((log) => log.gravidade === 'error')) ?
        'Foram encontrados erros críticos no seu XML, faça download do arquivo de logs para obter mais informações:' :
        'Foram encontrados possíveis erros no seu XML, faça download do arquivo de logs para obter mais informações:';

      for (const nomeArquivo in res) {
        if (res[nomeArquivo].length) {
          const csv = this.geraArquivoCsv(res[nomeArquivo]);
          const blob = new Blob(["\ufeff",csv], { type: 'text/csv' });
          const urlArquivo = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
          const nomeArquivoSeparado = nomeArquivo.split('.');
          nomeArquivoSeparado[nomeArquivoSeparado.length - 1] = 'csv';
          const nomeArquivoCsv = nomeArquivoSeparado.join('.');
          this.arquivosValidacaoDownload.push({
            nome: `Erros - ${nomeArquivoCsv}`,
            url: urlArquivo,
          });
        }
      }

      return;
    });
  }


  postXml(explode: boolean): void {
    this.nextStep();
    this.spinnerAusentes = true;
    this.formData.append('explode', explode ? 'true' : 'false');

    this.http.verificaXml(this.formData).subscribe((msg: iRespostaVerificaXml) => {
      this.interData.setNovoXmlImportado(true);
      this.uuid = msg.calculo_id;
      this.cnpjs = msg.cnpjs_fundos;

      if (msg.carteiras) {
        this.carteiras = msg.carteiras;
      }
      if (msg.planos) {
        this.planos = msg.planos;
      }
      if (explode) {
        this.xmlAusentes = msg.ausentes;
      } else {
        this.xmlAusentes = [];
      }
      this.passagemXml = msg.lista_calculos_id;
      this.jobRedis = msg.job_redis;
      this.benchmarks = msg.benchmarks;
      this.msgRetorno = '';
      this.formBenchMark.clear();
      if (this.tipoInstituicao == TIPOS_PERFIL.previdencia && this.carteiras) {
        this.thirdFormGroup = this._formBuilder.group({
          thirdCtrl: new FormArray(this.carteiras.map((data) => new FormControl(data.key, [Validators.required]))),
        });
      }
      this.planos.map(() => {
        this.formBenchMark.push(
          new FormControl(
            null,
            Validators.compose([Validators.required, this.validator.requireMatch(this.benchmarks)]),
          ),
        );
      });

      if (explode) {
        this.listaFundos = msg.lista_fundos_pais;
      } else {
        this.listaFundos = msg.lista_fundos;
      }

      for (let index = 0; index < this.formBenchMark.length; index++) {
        this.manageBenchControl(index);
      }
      if (this.xmlAusentes.length < 1) {
        this.nextStep();
      } else {
        this.spinnerAusentes = false;
      }
    });
    this.formData.delete('explode');
  }

  verificaSobreposicao() {
    this.http.verificaCalculoDataXML(this.formData).subscribe((res: any) => {
      if (res.existe && res.existe.length) {
        this.fundosCalculados = res.existe;
        if (this.fundosCalculados.length > 1) {
          this.mensagemPosicao = `Existe uma posição calculada para esses fundos.<br/>Tem certeza de que deseja sobrepor essas posições?`;
        } else {
          this.mensagemPosicao = `Existe uma posição calculada para esse fundo.<br/>Tem certeza de que deseja sobrepor essa posição?`;
        }
        this.isOverlap = true;
      } else {
        this.trataStep();
      }
    });
  }

  postRentabilidade(form) {
    this.rentDadosForm = form.value.thirdCtrl;

    for (const i in this.carteiras) {
      this.rentDados.push({
        tipo: 'plano',
        id: this.carteiras[i].id,
        rentabilidade: this.rentDadosForm[i],
      });
    }
  }

  postBenchMark(benchmark) {
    if (benchmark.valid) {
      this.stepper.linear = true;
      this.stepper.next();
      this.blockStepBefore(this.stepper.selectedIndex);

      if (this.tipoInstituicao == 'Previdencia') {
        let i = 0;
        for (const dados of this.rentDados) {
          dados['bench'] = benchmark.value.benchmark[i++];
        }
        this.http.postRentabilidade({ dados: this.rentDados }).subscribe();
        this.importa();
      } else {
        this.rentDados = this.rentDados.concat(
          this.cnpjs.map(cnpj => ({
              tipo: 'fundo',
              id: cnpj,
              rentabilidade: null,
              bench: benchmark.value.benchmark[0]
          }))
        );
        this.http.postRentabilidade({ dados: this.rentDados }).subscribe((msg) => {
          if (msg['status'] == 200) {
            if (this.uuid !== undefined) {
              this.http.getEstruturaByUuid(this.uuid).subscribe((res) => {
                this.sideBar.selecionaFundo(res['retorno']);
                this.modalRef.close();
              });
            }
          }
        });
        this.importa();
      }
    }
  }

  private blockStepBefore(intToBlock: number) {
    this.stepper.steps.forEach((step, index) => {
      if (index < intToBlock) {
        step.editable = false;
      }
    });
  }

  importa() {
    this.http.importaXml(this.jobRedis).subscribe((msg) => {
      this.importou = msg['redis'];
      if (this.importou && this.tipoInstituicao == TIPOS_PERFIL.previdencia) {
        this.diferencas();
      } else {
        this.showSpinner2 = true;
      }
    });
  }

  diferencas() {
    this.diferencasParametros['parametro_id'] = this.passagemXml;

    this.http.postDiferencas(this.diferencasParametros).subscribe((msg) => {
      this.RespostaJson = msg['resposta'];
      if (this.RespostaJson.length !== 0) {
        this.showSpinner2 = true;
      } else if (this.RespostaJson.length > 2) {
        (document.getElementById('scroolbar') as HTMLElement).style.overflow = 'auto';
      }
      return 'ok';
    });
  }

  comunicarAPI() {
    this.diferencasParametros['comando'] = 'cancelar';
  }

  relatorio(tipo) {
    this.relatorioFuncao = {
      parametro_id: this.passagemXml,
      caminho: tipo,
    };

    this.http.postRecalculo(this.relatorioFuncao).subscribe((msg) => {
      this.RespostaJson = msg['calculo_id'];
      this.calculoJaPostado = false;
      (document.getElementById('btn_fechar') as HTMLElement).click();
      this.http.getEstruturaByUuid(this.RespostaJson).subscribe((res) => {
        this.sideBar.selecionaFundo(res['retorno']);
      });
    });
    this.calculoJaPostado = true;
  }

  recalculo(tipo) {
    this.relatorioFuncao = {
      parametro_id: this.passagemXml,
      caminho: tipo,
    };
    this.http.postRecalculo(this.relatorioFuncao).subscribe((msg) => {
      this.RespostaJson = msg['calculo_id'];
      this.diferencas2(this.RespostaJson);
    });
  }

  diferencas2(RespostaJson2) {
    this.http.postDiferencas(RespostaJson2).subscribe(
      (msg) => {
        if (this.RespostaJson.length !== 0) {
          this.showSpinner2 = true;
        } else if (this.RespostaJson.length > 2) {
          (document.getElementById('scroolbar') as HTMLElement).style.overflow = 'auto';
        }
        return 'ok';
      },
      () => {
        setTimeout(() => {
          return this.diferencas2(RespostaJson2);
        }, 9000);
      },
    );
  }
  corrigeStatusStepper() {
    if (this.stepper.selected.stepControl.invalid) {
      this.stepper.selected.interacted = false;
    }
  }
}
