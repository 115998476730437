import { Component, OnInit } from '@angular/core';
import { InternalDataService } from 'src/app/services/internal-data/internal-data.service';

@Component({
  selector: 'app-boletas',
  templateUrl: './boletas.component.html',
  styleUrls: ['./boletas.component.less'],
})
export class BoletasComponent implements OnInit {
  public toggle: boolean;
  public isGestaoAberto: boolean;
  public isConsolidadoAberto: boolean;

  constructor(private internalData: InternalDataService) {
    this.internalData.getSidebarToggle().subscribe((msg) => {
      this.toggle = msg;
    });

    this.internalData.getGestaoAberto.subscribe((res) => {
      this.isGestaoAberto = res;
    });

    this.internalData.getRelatorioAberto.subscribe((res) => {
      this.isConsolidadoAberto = res;
    });
  }

  ngOnInit() {}
}
