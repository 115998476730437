import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
  @ViewChild('variavelSenha', { static: true }) senhas: ElementRef;

  public form: Form;
  panelOpenState = false;
  flag: number;

  constructor(private auth: AuthService, public dialog: MatDialog) {
    this.form = new Form('', '');
  }

  ngOnInit() {}

  alertaSenha() {
    (document.getElementsByClassName('senhas')['0'] as HTMLElement).style.display = 'block';
    setTimeout(function () {
      (document.getElementsByClassName('senhas')['0'] as HTMLElement).style.display = 'none';
    }, 2000);
  }

  ngAfterViewInit() {
    this.senhas.nativeElement.style.display = 'none';
  }

  onSubmit() {
    this.auth.login(this.form.username, this.form.password);
  }
  openDialog(): void {}
}

@Component({
  selector: 'forgot-password',
  templateUrl: 'forgot-password.html',
})
export class ForgotPasswordComponent {
  msgFormSubmit: string = '';

  constructor(public dialogRef: MatDialogRef<ForgotPasswordComponent>, private httpService: HttpService) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  redefinirSenha(email: string, usuario: string) {
    this.httpService.postEsqueciSenha(usuario, email).subscribe(() => (this.msgFormSubmit = 'Email enviado!'));
  }
}

export class Form {
  constructor(public username: string, public password: string) {}
}
